<template>
    <div v-if="isModalVisible" class="modal-overlay" @click.self="closeModal">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h2>Choisir vos Tickets</h2>
          <button class="close-btn" @click="closeModal">×</button>
        </div>

        <div class="loading-container" v-if="loading">
            <i class="fas fa-spinner fa-spin"></i>
        </div>
        <div v-else>

            <div v-if="tickets  && tickets.length > 0" >
                <!-- Ticket List -->
                <div v-for="ticket in tickets" :key="ticket.id" class="ticket-item">
                    <div class="ticket-name-price">
                        {{ ticket.name }} ({{ ticket.price }} {{ ticket.currency }})
                    </div>
                    <div class="ticket-info">
                        <!-- Description Toggle -->
                        <button class="info-btn" @click="toggleDescription(ticket.id)">?</button>
                        <div v-if="ticket.showDescription" class="ticket-description">
                        {{ ticket.description }}
                        </div>
                        
                        <!-- Ticket Quantity Controls -->
                        <button 
                            class="quantity-btn" 
                            :disabled="ticket.quantity <= 0" 
                            @click="updateQuantity(ticket.id, -1)"
                        >
                            -
                        </button>
                        <span class="ticket-quantity">{{ ticket.quantity }}</span>
                        <button 
                            class="quantity-btn" 
                            :disabled="ticket.quantity >= ticket.maxQuantity" 
                            @click="updateQuantity(ticket.id, 1)"
                        >
                            +
                        </button>
                    </div>
                </div>
        
                <!-- Total Price -->
                <div class="total-price">
                  Total:
                  <span v-for="(total, currency) in totalAmountsByCurrency" :key="currency">
                    {{ total }} {{ currency }}<span v-if="!isLastCurrency(currency)"> | </span>
                  </span>
                </div>

        
                <!-- Confirm Purchase Button -->
                <button v-if="!showViewPIN"
                class="confirm-btn" 
                @click="confirmPurchase" 
                :disabled="!canConfirm"
                >
                <i class="fas fa-money-check-alt"></i> Procéder au paiement
                </button>

                <div v-if="showViewPIN">
                    <h4 class="title-pin">Insérer votre pin</h4>
                    <div class="pin-container">
                        <div class="pin-inputs">
                            <!-- Les inputs avec ref dynamique -->
                            <input v-for="(digit, index) in pin" 
                                :key="index" 
                                v-model="pin[index]" 
                                maxlength="1"
                                :ref="'pin' + index" 
                                @input="onInput(index)" 
                                @keydown.backspace="onBackspace(index)" 
                                :type="isRevealed ? 'text' : 'password'" 
                                class="pin-box" 
                                inputmode="numeric" 
                                pattern="[0-9]*"
                                @keypress="isNumber($event)" />
                            
                            <!-- Icône pour révéler le code PIN -->
                            <i class="fas fa-eye eye-icon" @mousedown="revealPin" @mouseup="hidePin" @mouseleave="hidePin"></i>
                        </div>
                    </div>


                    <!-- Bouton de validation avec une icône FontAwesome -->
                    <button type="submit" class="btn-submit-pin" :disabled="loadingPin" @click="concluate">
                    <span v-if="loadingPin">
                        Transfert en cours <i class="fas fa-spinner fa-spin"></i>
                    </span>
                    <span v-else>
                        Valider <i class="fas fa-check-circle"></i>
                    </span>
                    </button>


                    <div>
                    <span class="error-message validate-error" v-if="pinError">{{ pinError }}</span>
                    </div>

                </div>

            </div>

            <div v-else class="no-results-message">
                Aucun Ticket n'est actuellement disponible pour cet événement.
            </div>

        </div>
  
      </div>
    </div>
</template>
  
<script>
  import axios from 'axios';
  import { SERVER_IP } from '../../config';
  import { checkAuth } from '@/utils/validationUtils';

  export default {
    name: 'ModalTicketing',
    props: {
        isModalVisible: {
            type: Boolean,
            required: true
        },
        eventID: {           // Prop pour le `memberID`
            type: Number,
            required: true
        },
    },
    watch: {
        isModalVisible(newVal) {
            if (newVal) {
                this.showViewPIN = false;
                this.pin = ["", "", "", ""];
                this.pinError = '';
                this.getTickets();
            }
        }
    },

    data() {
      return {
        tickets: [],
        loading: false,
        showViewPIN: false,
        loadingPin: false,
        pin: ["", "", "", ""],
        pinError: '',
        isRevealed: false,
        bought: false, // Initialisation à false
      };
    },

    computed: {
      // Regroupe les montants par devise
      totalAmountsByCurrency() {
        if (!this.tickets) return {};

        // Agrégation des montants par devise
        return this.tickets.reduce((acc, ticket) => {
          if (!ticket.currency || !ticket.price || !ticket.quantity) return acc;

          acc[ticket.currency] = (acc[ticket.currency] || 0) + ticket.price * ticket.quantity;
          return acc;
        }, {});
      },

      // Vérifie si la devise actuelle est la dernière dans la liste
      isLastCurrency() {
        const currencies = Object.keys(this.totalAmountsByCurrency);
        return (currency) => currencies[currencies.length - 1] === currency;
      },

      // Confirme si au moins un ticket est sélectionné
      canConfirm() {
        return this.tickets?.some(ticket => ticket.quantity > 0) || false;
      }
    },


    methods: {
      closeModal() {
        if (!this.loadingPin) {
          this.$emit("close", this.bought);
        }
      },
      toggleDescription(index) {
        this.tickets[index].showDescription = !this.tickets[index].showDescription;
      },
      updateQuantity(ticketId, delta) {
        // Trouver le ticket correspondant par son id
        const ticket = this.tickets.find(t => t.id === ticketId);
        if (ticket) {
            // Mettre à jour la quantité du ticket
            ticket.quantity = Math.max(0, Math.min(ticket.quantity + delta, ticket.maxQuantity));
            this.showViewPIN = false;
        } else {
            console.error(`Ticket avec ID ${ticketId} introuvable.`);
        }
    },

      confirmPurchase() {
        // Logique pour confirmer l'achat
        this.showViewPIN = true;
      },

      // Empêche l'utilisateur d'entrer des lettres
      isNumber(event) {
            const charCode = event.keyCode ? event.keyCode : event.which;
            if (charCode < 48 || charCode > 57) { // 48-57 correspond aux chiffres 0-9
                event.preventDefault();
            }
        },

        // Lorsque l'utilisateur entre un chiffre dans un input
        onInput(index) {
            if (this.pin[index] && index < this.pin.length - 1) {
                // Si le champ est rempli, passer automatiquement au champ suivant
                this.$nextTick(() => {
                    const nextInput = this.$refs['pin' + (index + 1)][0];
                    if (nextInput) nextInput.focus();
                });
            }
            this.pinError = '';
        },

        // Gestion de la suppression d'un chiffre
        onBackspace(index) {
            if (!this.pin[index] && index > 0) {
                // Si le champ est vide après suppression, revenir au champ précédent
                this.$nextTick(() => {
                    const prevInput = this.$refs['pin' + (index - 1)][0];
                    if (prevInput) prevInput.focus();
                });
            } 
            this.pinError = '';
        },

        // Révéler le PIN pendant 1 seconde
        revealPin() {
            this.isRevealed = true;
            setTimeout(() => {
                this.isRevealed = false;
            }, 3000); // Masque le code après 3 secondes
        },

        hidePin() {
            this.isRevealed = false;
        },

        // Validation du PIN
        validatePin() {
            if (this.pin.join('').length === 4) {
                return true;
            } else {
                return false;
            }
        },

        getSelectedTickets() {
            return this.tickets
            .filter(ticket => ticket.quantity > 0)  // Filtrer les tickets où la quantité est > 0
            .map(ticket => ({
                id: ticket.id,  // Remplacez 'name' par l'ID réel du ticket si vous avez un champ 'id'
                quantity: ticket.quantity,
            }));
        },

        scrollToError(field) {
            const element = document.getElementById(field);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
            this.loading = false;
        },
  

        async concluate(){
            this.loadingPin = true;
            if(!this.validatePin()){
                this.pinError = "Veuillez remplir tous les champs avec des chiffres.";
                this.loadingPin = false;
                return;
            }

            const url = `${SERVER_IP}/eventsManagement/ticketing`;
            try {
                const userId = checkAuth();
                const response = await axios.post(url, { 
                    tickets: this.getSelectedTickets(),
                    pin: this.pin.join(''),
                    userId: userId
                });
                if (response.status === 200) {
                    this.bought = true; 
                    this.$emit("close", this.bought);
                }
                
            } catch (error) {
                if (error.response && error.response.data.error === 'invalid_pin'){
                    this.pinError = "Votre code PIN est incorrecte";
                    this.scrollToError('pinError');
                }
                else if ((error.response && error.response.data.error === 'transaction_impossible')) {
                    this.pinError = "Un de vos tickets sélétionnés n'est plus disponible, veuillez refaire une autre séléction.";
                    this.scrollToError('pinError');
                }
                else{
                    this.pinError = "Une erreur est survenue, veuillez recommencer ultérieurement";
                    this.loadingPin = false;
                }
                
            }
            finally {
                this.loadingPin = false; // Assurez-vous de désactiver le chargement dans tous les cas
            }
        },

        async getTickets() {
            this.loading = true;
            const url = `${SERVER_IP}/eventsManagement/loadTickets`;
            try {
                const response = await axios.post(url, { 
                    id: this.eventID
                });
                if (response.status === 200) {
                    this.tickets = response.data.tickets;
                }
                
            } catch (error) {
                console.log("Aucun Ticket");
            }
            finally {
                this.loading = false; // Assurez-vous de désactiver le chargement dans tous les cas
            }
        },

    }
  };
</script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 350px;
    max-width: 100%;
  }
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    color: #000;
  }
  .close-btn {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: #000;
    font-size: 1.5rem;
}


  .ticket-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    color: #000;
  }
  .ticket-name-price {
    font-weight: bold;
  }
  .ticket-info {
    display: flex;
    align-items: center;
  }
  .info-btn {
    background-color: #ddd;
    border: none;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
  }
  .quantity-btn {
    background-color: #eee;
    border: none;
    padding: 5px;
    margin: 0 5px;
    cursor: pointer;
  }
  .ticket-quantity {
    font-size: 16px;
  }
  .ticket-description {
    margin-top: 5px;
    font-size: 14px;
    color: #555;
  }
  .total-price {
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
    text-align: center;
    color: #000;
  }
  .confirm-btn {
    width: 100%;
    font-size: 16px;

    background-color: #016A40; /* Fond vert */
    color: white; /* Texte blanc */
    border: none;
    border-radius: 5px; /* Coins moins circulaires */
    padding: 10px 20px; /* Ajustement du rembourrage */
    cursor: pointer;
    transition: background-color 0.3s; /* Transition pour l'animation */
    width: auto;
    margin-top: 5%;
  }

  .confirm-btn:hover {
    background-color: #005934; /* Changement de couleur au survol */
  }

  .confirm-btn:active {
    background-color: #003822; /* Changement de couleur lorsqu'il est cliqué */
  }

  .confirm-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

.title-pin{
    font-weight: normal;
    font-size: 1.5rem;
    color: #000;
    margin-top: 5%;
}

.pin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -2%;
}

.pin-label {
  margin-bottom: 20px;
  font-size: 1.2rem;
  text-align: center;
}

.pin-inputs {
  display: flex;
  gap: 10px;
  position: relative;
}

.pin-box {
  width: 50px;
  height: 50px;
  border: 2px solid #000103;
  border-radius: 5px;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}

.eye-icon {
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #000;
}

.btn-submit-pin {
background-color: #016a40;
color: white;
border: none;
padding: 10px 20px;
font-size: 1em;
cursor: pointer;
border-radius: 5px;
transition: background-color 0.3s;
margin-top: 15px;
}

.btn-submit-pin:hover {
background-color: #005934;
}

.error-message {
font-size: 0.8em; /* Ajustez la taille de la police selon vos besoins */
font-weight: normal; /* Ajustez le poids de la police si nécessaire */
transition: font-size 0.3s; /* Transition pour une animation douce */
color: #9c0707; /* Couleur du texte rouge */
font-style: italic; /* Texte en italique */
margin-top: 5px;
}

/****************** NO RESULT FETCH ************************/
.no-results-message {
    color: #000; 
}
</style>
  