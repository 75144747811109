<template>
    <div class="modal-overlay" v-if="isOpen" @click.self="closeModal">
      <div class="modal-content">
        <h3>Filtres de SOL</h3>

        <!-- Section Statut du Groupe -->
        <div class="filter-section">
            <h4><i class="fas fa-users-cog"></i> Statut du Groupe</h4>
            <div class="checkbox-group">
                <label class="select-all">
                    <input type="checkbox" v-model="filters.allStatusGroup" @change="selectAllStatusGroup"> Tous les status
                </label>
                <label class="sub-select-3" v-for="status in groupStatus" :key="status">
                    <input 
                        type="checkbox" 
                        v-model="filters.selectedStatusGroup" 
                        :value="status" 
                        @change="selectStatusGroup(status)"> {{ stringSatusGroup(status) }}
                </label>
            </div>
        </div>

        <!-- Section Statut du Participant -->
        <div class="filter-section">
            <h4><i class="fas fa-user-check"></i> Statut de Participation</h4>
            <div class="checkbox-group">
                <label class="select-all">
                    <input type="checkbox" v-model="filters.allStatusMember" @change="selectAllStatusMember"> Tous les status
                </label>
                <label class="sub-select-3" v-for="status in memberStatus" :key="status">
                    <input 
                        type="checkbox" 
                        v-model="filters.selectedStatusMember" 
                        :value="status" 
                        @change="selectStatusMember(status)"> {{ stringStatusMember(status) }}
                </label>
            </div>
        </div>

        <!-- Section Cycle du Groupe -->
        <div class="filter-section">
            <h4><i class="fas fa-redo"></i> Cycle</h4>
            <div class="checkbox-group">
                <label class="select-all">
                    <input type="checkbox" v-model="filters.allCycles" @change="selectAllCycles"> Tous les status
                </label>
                <label class="sub-select" v-for="cycle in cycles" :key="cycle">
                    <input 
                        type="checkbox" 
                        v-model="filters.selectedCycle" 
                        :value="cycle" 
                        @change="selectCycle(cycle)"> {{ stringFrequences(cycle) }}
                </label>
            </div>
        </div>

  
        <!-- Section Devise -->
        <div class="filter-section">
          <h4><i class="fas fa-coins"></i> Devise</h4>
          <div class="checkbox-group">
            <label class="select-all">
              <input type="checkbox" v-model="filters.allCurrencies" @change="selectAllCurrencies"> Toutes les devises
            </label>
            <label class="sub-select" 
                  v-for="currency in currencies" 
                  :key="currency" 
                  
            >
              <input 
                type="checkbox" 
                v-model="filters.selectedCurrencies" 
                :value="currency" 
                @change="selectCurrency(currency)"> {{ currency }}
            </label>
          </div>
        </div>

        
        <!-- Section Role -->
        <div class="filter-section">
          <h4><i class="fas fa-user-tag"></i> Rôle dans le Groupe</h4>
          <div class="checkbox-group">
            <label class="select-all">
              <input type="checkbox" v-model="filters.allRoles" @change="selectAllRoles"> Tous les rôles
            </label>
            <label class="sub-select" v-for="role in roles" :key="role">
              <input 
                type="checkbox" 
                v-model="filters.selectedRoles" 
                :value="role" 
                @change="selectRole(role)"> {{ role }}
            </label>
          </div>
        </div>

        <!-- Section Order -->
        <div class="filter-section">
          <h4><i class="fas fa-sort"></i> Trier par</h4>
          <div class="checkbox-group">
            <label class="sub-select" v-for="(order, index) in orders" :key="index">
              <input 
                type="radio" 
                v-model="filters.selectedOrder" 
                :value="order.value" 
                @change="selectOrder(order)"
              > 
              <i :class="order.icon"></i> {{ order.text }}
            </label>
          </div>
        </div>

  
        <!-- Boutons de validation -->
        <div class="modal-buttons">
          <button @click="applyFilters">Appliquer</button>
          <button @click="closeModal">Annuler</button>
        </div>
      </div>
    </div>
</template>
  
<script>
  import { stringSatusGroup, stringStatusMember, stringFrequences } from '@/utils/groupsavings';

  export default {
    name: 'ModalFiltersPools',
    props: {
      isOpen: {
        type: Boolean,
        required: true
      }
    },

    mounted() {
      // Vérifier si des filtres sont sauvegardés dans localStorage
      const savedFilters = localStorage.getItem('savedFilters');
      if (savedFilters) {
        this.filters = JSON.parse(savedFilters);
      }
    },

    

    data() {
      return {
        groupStatus: ['pending', 'in-progress', 'completed'],
        memberStatus: ['active','inactive','confirmed','waiting','cancel','removed'],
        cycles: ['daily', 'weekly', 'bi-weekly', 'monthly'],
        currencies: ['HTG', 'USD'],
        roles: ['Admin', 'Membre'],
        orders: [
            { value: 1, text: 'Nom', icon: 'fas fa-sort-amount-down-alt' },
            { value: 2, text: 'Nom', icon: 'fas fa-sort-amount-up-alt' },
            { value: 3, text: 'Montant', icon: 'fas fa-sort-amount-down-alt' },
            { value: 4, text: 'Montant', icon: 'fas fa-sort-amount-up-alt' }
        ],

        filters: {
          allStatusGroup: true,
          selectedStatusGroup: [],
          allStatusMember: true,
          selectedStatusMember: [],
          allCycles: true,
          selectedCycle: [],
          allCurrencies: true,
          selectedCurrencies: [],
          allRoles: true,
          selectedRoles: [],
          selectedOrder: 1, 
        }
      };
    },

    

    methods: {

      stringSatusGroup(status) {
          return stringSatusGroup(status);
      },
      selectAllStatusGroup() {
          if (this.filters.allStatusGroup) {
              // Si "Toutes les transactions" est sélectionné, vide selectedTypes
              this.filters.selectedStatusGroup = [];
          }
      },
      selectStatusGroup(status) {

          // Si un type spécifique est sélectionné, désélectionner "Toutes les transactions"
          if (this.filters.selectedStatusGroup.includes(status)) {
              this.filters.allStatusGroup = false;
          } else {
              // Si "allTypes" est déjà sélectionné, le désélectionner quand on choisit un autre type
              this.filters.allStatusGroup = false;
          }

          if (this.filters.selectedStatusGroup.length === 0) {
            this.filters.allStatusGroup = true;
          }
      },

      stringStatusMember(status) {
          return stringStatusMember(status);
      },
      selectAllStatusMember() {
          if (this.filters.allStatusMember) {
              // Si "Toutes les transactions" est sélectionné, vide selectedTypes
              this.filters.selectedStatusMember = [];
          }
      },
      selectStatusMember(status) {

          // Si un type spécifique est sélectionné, désélectionner "Toutes les transactions"
          if (this.filters.selectedStatusMember.includes(status)) {
              this.filters.allStatusMember = false;
          } else {
              // Si "allTypes" est déjà sélectionné, le désélectionner quand on choisit un autre type
              this.filters.allStatusMember = false;
          }

          if (this.filters.selectedStatusMember.length === 0) {
            this.filters.allStatusMember = true;
          }
      },

      stringFrequences(status) {
          return stringFrequences(status);
      },
      selectAllCycles() {
          if (this.filters.allCycles) {
              // Si "Toutes les transactions" est sélectionné, vide selectedTypes
              this.filters.selectedCycle = [];
          }
      },
      selectCycle(cycle) {

          // Si un type spécifique est sélectionné, désélectionner "Toutes les transactions"
          if (this.filters.selectedCycle.includes(cycle)) {
              this.filters.allCycles = false;
          } else {
              // Si "allTypes" est déjà sélectionné, le désélectionner quand on choisit un autre type
              this.filters.allCycles = false;
          }

          if (this.filters.selectedCycle.length === 0) {
            this.filters.allCycles = true;
          }
      },

      selectAllCurrencies() {
          if (this.filters.allCurrencies) {
          this.filters.selectedCurrencies = [];
          }
      },
      selectCurrency(currency) {
          if (this.filters.selectedCurrencies.includes(currency)) {
              this.filters.allCurrencies = false;
          }else {
              // Si "allTypes" est déjà sélectionné, le désélectionner quand on choisit un autre type
              this.filters.allCurrencies = false;
          }

          if (this.filters.selectedCurrencies.length === 0) {
            this.filters.allCurrencies = true;
          }
      },

      
      selectAllRoles() {
          if (this.filters.allRoles) {
          this.filters.selectedRoles = [];
          }
      },
      selectRole(role) {
          if (this.filters.selectedRoles.includes(role)) {
              this.filters.allRoles = false;
          }else {
              // Si "allTypes" est déjà sélectionné, le désélectionner quand on choisit un autre type
              this.filters.allRoles = false;
          }

          if (this.filters.selectedRoles.length === 0) {
            this.filters.allRoles = true;
          }
      },

      selectOrder(order) {
        this.filters.selectedOrder = order.value;
      },


        applyFilters() {
            this.$emit('apply-filters', this.filters);
            localStorage.setItem('savedFilters', JSON.stringify(this.filters));
            this.closeModal();
        },
        closeModal() {
            this.$emit('close');
        },


    }
  };
</script>
  
<style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 350px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    color: #000;
    max-height: 70vh; /* Limite la hauteur de la modale pour éviter qu'elle dépasse l'écran */
    overflow-y: auto; /* Ajoute le défilement si le contenu dépasse */
    scrollbar-width: none; /* Cache la barre de défilement dans Firefox */
  }

  .modal-content::-webkit-scrollbar {
    width: 0px; /* Cache la barre de défilement dans Chrome, Safari */
    background: transparent; /* Optionnel : pour enlever un arrière-plan */
  }

  
  .filter-section {
    margin-bottom: 20px;
  }
  .filter-section h4{
    color: #FFF;
    background-color: #000;
    border-radius: 15px;
    padding: 10px;
    text-align: left;
  }
  .filter-section h4 i{
    margin-right: 10px;
  }
  
  .checkbox-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }
  .checkbox-group .select-all {
    display: block;
    text-align: left;
    width: 100%;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .checkbox-group .sub-select {
    flex: 1 1 calc(33.33% - 10px);
    /* display: inline-block; */
    margin-right: 5%;
    margin-bottom: 10px;
    text-align: left;
  }


  .sub-select-3 {
    flex: 1 1 calc(33.33% - 10px); /* 3 éléments par ligne (ajuster selon besoin) */
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .checkbox-group > .sub-select-3:last-child {
    text-align: right;
  }
  
  
  .modal-buttons {
    display: flex;
    justify-content: flex-end;
  }
  
  .modal-buttons button {
    margin-left: 10px;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }
  
  .modal-buttons button:last-child {
    background-color: #dc3545;
  }

  .input-date input {
    padding: 0.5em 0;
    font-size: 1em;
    /* width: 100%; */
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #000;
    color: #000;
  }

  .input-date input:focus {
    outline: none;
    border-bottom: 2px solid #016A40; /* Couleur de focus verte */
  }

  .single-date {
    display: block;
    text-align: left;
    width: 100%;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-left: 5px;
  }
  .single-date label{
    margin-right: 10px;
  }

  .plage-date {
    display: flex; /* Utiliser Flexbox pour répartir les éléments */
    justify-content: space-between; /* Répartit les éléments uniformément */
    align-items: center; /* Aligne verticalement les éléments au centre */
    width: 100%; /* S'assure que le conteneur occupe 100% de la largeur disponible */
    gap: 10px; /* Ajoute un petit espacement entre les éléments */
    }

    .plage-date label {
    flex: 1; /* Les étiquettes prennent chacune une part égale de l'espace */
    text-align: right; /* Aligne le texte des étiquettes à droite */
    margin-right: 10px; /* Ajoute un espace à droite des étiquettes */
    }

    .plage-date input[type="date"] {
    flex: 2; /* Les champs de date prennent plus d'espace que les étiquettes */
    padding: 5px; /* Ajoute un peu de padding pour les entrées */
    width: 100%; /* S'assure que les champs d'entrée s'étendent sur toute leur zone */
    box-sizing: border-box; /* S'assure que le padding est inclus dans la largeur totale */
    }


</style>
  