
export function stringFrequences(frequence) {
    const frequenceMap = {
        'daily': 'Chaque jour',
        'weekly': 'Chaque semaine',
        'bi-weekly': 'Chaque quinzaine',
        'monthly': 'Chaque mois'
    };
    
    return frequenceMap[frequence] || 'Fréquence inconnue'; // Gestion d'un cas par défaut
}


export function intFrequences(frequence) {
    const frequenceMap = {
        'daily': 1,
        'weekly': 2,
        'bi-weekly': 3,
        'monthly': 4
    };
    
    return frequenceMap[frequence] || 'Fréquence inconnue'; // Gestion d'un cas par défaut
}

export function stringSatusGroup(status) {
    const statusMap = {
        'pending': 'En attente',
        'in-progress': 'En cours',
        'completed': 'Achevé'
    };
    
    return statusMap[status] || 'Status inconnue'; // Gestion d'un cas par défaut
}

export function getStatusGroup() {
    const status = ['pending', 'in-progress', 'completed'];
    return status;
}

export function stringStatusMember(status) {
    const statusMap = {
        'active': 'Actif',
        'inactive': 'Inactif',
        'confirmed': 'Confirmé',
        'waiting': 'En attente',
        'cancel': 'Annulé',
        'removed': 'Retiré'
    };
    
    return statusMap[status] || 'Statut inconnu'; // Gestion d'un cas par défaut
}


