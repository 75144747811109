<template>
    <div v-if="visible" class="modal-overlay"  @click.self="closeModal">
      <div class="modal-container">
        <h2>Achat Réussi <i class="fa-regular fa-circle-check"></i></h2>
        <p>Votre achat de tickets a été confirmé avec succès. Merci pour votre confiance !</p>
        <button @click="closeModal" class="close-btn"><i class="fas fa-close"></i> Fermer</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ModalTickettingSuccess",
    props: {
      visible: {
        type: Boolean,
        required: true,
      },
    },
    methods: {
      closeModal() {
        this.$emit("close");
      },
    },
  };
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4); /* Overlay semi-transparent */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-container {
    background: #ffffff; /* Fond blanc */
    color: #000000; /* Texte noir */
    padding: 20px 30px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 350px;
    width: 90%;
  }
  
  h2 {
    margin-bottom: 10px;
    font-size: 1.5em;
  }
  
  p {
    margin-bottom: 20px;
    font-size: 1em;
  }
  
  .close-btn {
    background: #007bff; /* Bouton bleu */
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
  }
  
  .close-btn:hover {
    background: #0056b3;
  }
  </style>
  