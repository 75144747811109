<template>
    <h1>
      <i class="fas fa-arrow-left icon-back" @click="handleGoBack"></i>
      <span :style="{ fontSize: fontSize }">{{ title }}</span>
    </h1>
</template>
  
<script>
  export default {
    props: {
      title: {
        type: String,
        required: true
      },

      goBack: {
        type: Function, // Permet de passer une fonction personnalisée
        default: null // Laisser null si non défini par le parent
      },

      fontSize: {
        type: String, // Accepte des tailles de police comme '1rem', '24px', 'larger', etc.
        default: '2rem' // Valeur par défaut si non spécifiée
      },
    },
    methods: {
      handleGoBack() {
        if (this.goBack) {
          // Si une méthode personnalisée est définie, on l'appelle
          this.goBack();
        } else {
          // Sinon, on utilise la méthode par défaut
          this.$router.go(-1); // Retourne à la page précédente
        }
      },
    }
  };
</script>
  
  <style scoped>
    h1 {
        color: #FFFFFF;
        max-width: 400px;
        margin: auto;
        padding: 0px;
        text-align: center;
        color: #ffffff;
        padding-left: 5px;
        padding-right: 5px;
        margin-top: 10px;
    }
    .icon-back {
        background-color: white;
        border-radius: 50%;
        padding: 8px;
        margin-right: 50px;
        color: black;
        font-size: 0.5em;
        cursor: pointer;
    }
  </style>
  