<template>
    <Header />
    <PageHeader title="Créer un Groupe SOL" :goBack="customGoBack" />

    <div class="create-group-savings-page">
        <!-- Mockup image -->
        <img src="@/assets/images/ewallet_35.png" alt="long-term-savings" class="mockup-image">

        <div class="zone-formulaire" v-if="activeIndex === 0">

            <form @submit.prevent="handleSubmit" novalidate>
                <div class="form-group">
                    <label for="times-select"><i class="fas fa-cube  icon-bg"></i> Nom du groupe</label>
                    <input 
                      type="text" 
                      id="name" 
                      v-model="form.name" 
                      @input="handleNameInput" 
                      @blur="checkName()"
                      placeholder="Nom du groupe" 
                      required 
                    />
                    <span class="error-message" v-if="nameError">{{ nameError }}</span>
                </div>

                <div class="form-row">
                  <div class="form-group">
                    <label for="cycle">
                      <i class="fas fas fa-calendar-alt icon-bg"></i> Cycle
                    </label>
                    <select id="cycle" v-model="form.cycle" @change="handleCycleChange" required>
                      <option value="1">Chaque Jour</option>
                      <option value="2">Chaque Semaine</option>
                      <option value="3">Chaque Quinzaine</option>
                      <option value="4">Chaque Mois</option>
                    </select>
                  </div>

                  <div class="form-group">
                    <label for="qty">
                      <i class="fas fa-user-friends icon-bg"></i> Nbre de participants
                    </label>
                    <input 
                      type="number" 
                      id="qty" 
                      v-model="form.qty" 
                      @input="handleQtyInput"   
                      @blur="checkQty"
                      placeholder="Nombre de participants" 
                      min="2" 
                      max="maxQty"
                      required 
                    />
                    <span class="error-message" v-if="qtyError">{{ qtyError }}</span>
                  </div>

                </div>

                <div class="form-row">
                  <div class="form-group form-currency">
                    <label for="currency">
                      <i class="fas fa-coins icon-bg"></i> Devise
                    </label>
                    <select id="currency" v-model="form.currency" @change="handleCurrencyChange" required>
                      <option value="HTG">HTG</option>
                      <option value="USD">USD</option>
                    </select>
                  </div>

                  <div class="form-group form-amount">
                    <label for="amount">
                      <i class="fas fa-money-bill-wave icon-bg"></i> Montant
                    </label>
                    <input 
                      type="number" 
                      id="amount" 
                      v-model="form.amount" 
                      min="minAmount" 
                      @input="handleAmountInput"   
                      @blur="checkAmount"
                      placeholder="0.00" 
                      required 
                    />
                    <span class="error-message" v-if="amountError">{{ amountError }}</span>
                  </div>

                  <div class="form-group form-rate">
                    <label for="startDate">
                      <i class="fas fa-calendar-day icon-bg"></i> Démarrage
                    </label>
                    <input 
                      type="date" 
                      id="startDate" 
                      v-model="form.startDate" 
                      :min="minStartDate" 
                      @input="handleStartDateInput" 
                      placeholder="Date de Démarrage" 
                      required 
                      ref="dateInput"
                    />   
                    <span class="error-message" v-if="dateError">{{ dateError }}</span>                 
                  </div>

                </div>

          
                <div class="submit-container">
                  <button type="submit" class="btn-submit" :disabled="loading">
                    <span v-if="loading">
                      Traitement en cours... <i class="fas fa-spinner fa-spin"></i>
                    </span>
                    <span v-else>
                        Confirmer la création <i class="fas fa-check-circle"></i>
                    </span>
                  </button>
                </div>

                <div>
                  <span class="error-message" v-if="resultError">{{ resultError }}</span>
              </div>

            </form>

        </div>

        <div class="block-a2f" v-if="activeIndex === 1">
            <p v-html="$t('pageBasicDeposit.noRecordPin')"></p>
            <button @click="goTo2AF" class="btn-submit">
            {{ $t('pageBasicDeposit.configure') }} <i class="fas fa-user-lock"></i>
            </button>
        </div>

        <div class="block-a2f" v-if="activeIndex === 2">
            <p>Votre groupe {{ form.name }} a été créé avec succès et commencera le {{ formatStartDate }}.<br/><br/>Cliquez sur le bouton ci-dessous pour inviter des participants et démarrer le cycle.<br/><br/></p>
            <router-link :to="{ name: 'AddGroupSavingsMembers', params: { groupId: new_group_id } }">
              <button @click="goToHome" class="btn-submit">
                  Ajouter des membres <i class="fas fa-user-plus"></i>
              </button>
            </router-link>
        </div>

    </div>

    <Footer :key="footerKey" />
    
</template>


<script>
import Header from '../shared/HeaderPage.vue';
import Footer from '../shared/FooterPage.vue';
import PageHeader from '../shared/PageHeader.vue';
import axios from 'axios';
import { SERVER_IP } from '../../config';

import { formatDate, checkAuth } from '@/utils/validationUtils';

import { refreshFooterMixin } from '@/mixins/refreshFooterMixin';

export default {
  name: 'CreateGroupSavings',
  mixins: [refreshFooterMixin],

  components: {
      Header,
      PageHeader,
      Footer,
  },
  created(){
    checkAuth();
  },
  mounted() {
    // Créer un nouvel objet Date
    const today = new Date();
    
    // Ajouter 1 jour pour obtenir la date de demain
    // const tomorrow = new Date(today);
    // tomorrow.setDate(today.getDate());

    // Formater la date au format YYYY-MM-DD pour l'input de type "date"
    const formattedDate = today.toISOString().split('T')[0];

    // Définir la valeur initiale et minimale de la date
    this.form.startDate = formattedDate;
    this.minStartDate = formattedDate;
  },

  computed: {
    formatStartDate() {
        return formatDate(this.minStartDate);
    },
  },


  data() {
    return {
        form: {
            name: '',
            cycle: 2,
            qty: null,
            currency: 'HTG',
            amount: null,
            startDate: null,
            startDateDemo: null,
        },
        // Les préférences de groupe pour chaque cycle
        group_pref: [
          { cycle: 1, max_qty: 30, minHTG: 5, minUSD: 1 },
          { cycle: 2, max_qty: 16, minHTG: 50, minUSD: 5 },
          { cycle: 3, max_qty: 20, minHTG: 200, minUSD: 20 },
          { cycle: 4, max_qty: 12, minHTG: 500, minUSD: 50 }
        ],

        loading: false,
        maxQty: 30,
        minAmount: 50,
        minStartDate: null, // Pour définir la date minimale
        activeIndex: 0,

        qtyError: '',
        amountError: '',
        nameError: '',
        dateError: '',
        resultError: '',
        new_group_id: null,
    };
  },

  methods : {

    customGoBack() {
        (this.activeIndex === 0 || this.activeIndex === 2)  ? this.$router.go(-1) : this.activeIndex = 0;
    },

    handleCurrencyChange() {
      this.handleCycleChange();

      if(this.form.qty)
        this.checkQty();
      
      if(this.form.amount)
        this.checkAmount();
    },

    handleCycleChange() {

      const currentPref = this.group_pref.find(pref => pref.cycle === parseInt(this.form.cycle));
      if (currentPref) {
        // Ajuster la quantité maximale et autres valeurs en fonction du cycle
        this.maxQty = currentPref.max_qty;
        // Ajuster le nombre de participants si la valeur actuelle dépasse la nouvelle limite maximale
        if (this.form.qty > this.maxQty) {
          this.form.qty = this.maxQty;
        }
        this.minAmount = this.form.currency === 'HTG' ? currentPref.minHTG : currentPref.minUSD;
      } else {
        // Si aucune préférence n'est trouvée, définir des valeurs par défaut
        this.maxQty = 52;
      }

      this.clearError();

      if(this.form.qty)
        this.checkQty();
      
      if(this.form.amount)
        this.checkAmount();
      
    },

    handleNameInput() {
      this.clearError();
    },

    checkName() {
      const regex = /^[A-Za-zÀ-ÿ0-9\s-]{3,50}$/;
      if (!regex.test(this.form.name)) {
        this.nameError ='Le nom doit contenir entre 3 et 50 caractères.';
      }
    },

    checkQty() {
      if (this.form.qty > this.maxQty)
        this.qtyError = `La quantité maximale est de ${this.maxQty}`;
      else if (this.form.qty < 2)
        this.qtyError = `La quantité minimale est de 2`;
    },

    handleQtyInput() {
      this.clearError();
    },

    checkAmount() {
      if (this.form.amount < this.minAmount)
        this.amountError = `La quantité miniale est de ${this.minAmount} ${this.form.currency}`;
    },
    handleAmountInput(){
      this.clearError();
    },

    clearError(){
      this.qtyError = "";
      this.amountError = "";
      this.nameError = "";
    },

    validateForm() {

      const regex = /^[A-Za-zÀ-ÿ0-9\s-]{3,50}$/;
      if (!regex.test(this.form.name)) {
        this.nameError = 'Le nom doit contenir entre 3 et 50 caractères.';
        return false;
      }

      if(this.form.qty > this.maxQty) {
        this.qtyError = `La quantité maximale est de ${this.maxQty}`;
        return false;
      }
      if(this.form.qty < 2) {
        this.qtyError = `La quantité minimale est de 2`;
        return false;
      }

      if(this.form.amount < this.minAmount) {
        this.amountError = `Le montant minimal est de ${this.minAmount}  ${this.form.currency}`;
        return false;
      }
        
      if(this.form.startDate > new Date()) {
        this.dateError = `Il faut choisir une date superieure à aujourd'hui`;
        return false;
      }

        return true;
    },

    async handleSubmit() {
      this.loading = true;

      if (!this.validateForm()) {
        this.loading = false;
        return;
      }
      const url = `${SERVER_IP}/poolsavings/create-group`;
      try {
        const userId = checkAuth();
        const response = await axios.post(url, {
          name: this.form.name,
          cycle: this.form.cycle,
          qtyUser: this.form.qty,
          currency: this.form.currency,
          amount: this.form.amount,
          startDate: this.form.startDate,
          userId: userId
        });

        if (response.status === 200 ) {
          this.new_group_id = response.data.group_id;
          this.activeIndex = 2;
        }
        
      } catch (error) {
        if (error.response && error.response.status === 400) {
          const { message } = error.response.data;
          if (message === 'no_pin') {
            this.activeIndex = 1;
          }
          if (message === 'name_exist') {
            this.nameError = 'Le nom que vous avez entré est déjà utilisé. Pensez à quelque chose d\'unique !';
          }
          else {
            this.resultError = "Une erreur inconnue est survenue, veuillez réessayer !";
          }

        }
      } finally {
        this.loading = false;
      }


    }

  }



};

</script>


<style scoped>
.block-a2f{
max-width: 400px;
margin: auto;
padding: 0px;
text-align: center;
color: #ffffff;
padding-left: 10px;
padding-right: 10px;
font-size: 0.8rem;
}
.block-a2f p{
  font-size: 1rem;
}
.block-a2f p a{
    color: #03a5fc;
    text-decoration: none;
}
.block-a2f p a:hover{
    color: #08489c;
}

.create-group-savings-page {
max-width: 400px;
margin: auto;
padding: 0px;
text-align: center;
color: #ffffff;
padding-left: 10px;
padding-right: 10px;
margin-bottom: 5.5%;
font-size: 0.8rem;
}

.mockup-image {
max-width: 120px;
margin: 20px auto;
display: block;
}


form {
  display: flex;
  flex-direction: column;
  max-width: auto;
  margin: auto;
  background-color: #000103;
  color: white;
  /* padding: 5px; */
  border-radius: 8px;
  margin-top:-10px;
}

.form-group {
  margin-bottom: 1em;
  display: auto;
  flex-direction: column;
  width: calc(100% - 0px);
  text-align: left;
}
.form-row {
  display: flex;
  justify-content: space-between; /* Répartition égale de l'espace entre les enfants */
  gap: 20px;
  width: auto;
  margin-top: 20px;
}

.form-group label {
  margin-bottom: 0.5em;
  color: white;
  text-align: left;
  display: flex;
  align-items: center;
}

.icon-bg {
  background-color: #016A40;
  color: white;
  padding: 5px; /* Taille des icônes réduite */
  border-radius: 8px; /* Coins arrondis */
  margin-right: 10px;
  font-size: 0.6em; /* Réduire la taille des icônes */
  width: 15px;
  height: 15px;
  text-align: center;
  font-size: 0.9em;
}

.form-group input,
.form-group select{
  padding: 0.5em 0;
  font-size: 1em;
  width: calc(100% - 0px);
  background-color: transparent;
  border: none;
  border-bottom: 1px solid white;
  color: white;
}

.form-group input:focus,
.form-group select:focus {
  outline: none;
  border-bottom: 2px solid #016A40; /* Couleur de focus verte */
}
option{
    color: #000;
}

.error-message {
  font-size: 0.8em; /* Ajustez la taille de la police selon vos besoins */
  font-weight: normal; /* Ajustez le poids de la police si nécessaire */
  transition: font-size 0.3s; /* Transition pour une animation douce */
  color: #FFFC00; /* Couleur du texte rouge */
  font-style: italic; /* Texte en italique */
  text-align: left;
}

.submit-container {
  text-align: center;
  margin-top: 20px;
}

.btn-submit {
  background-color: #016A40; /* Fond vert */
  color: white; /* Texte blanc */
  border: none;
  border-radius: 5px; /* Coins moins circulaires */
  padding: 10px 20px; /* Ajustement du rembourrage */
  cursor: pointer;
  transition: background-color 0.3s; /* Transition pour l'animation */
  width: 50%;
}

.btn-submit:hover {
  background-color: #005934; /* Changement de couleur au survol */
}

.btn-submit:active {
  background-color: #003822; /* Changement de couleur lorsqu'il est cliqué */
}

</style>