<template>
    <Header />
    <PageHeader title="Ajouter des membres à votre groupe SOL" :goBack="customGoBack" />

    <div class="add-members-group-savings-page">

        <div class="sector-list-members" v-if="activeIndex === 0">
            <div class="combo-box box-search-user">

                <div class="label-search">
                  Rechercher les membres à ajouter
                </div>
                
                <div class="search-input">
                    
                    <input 
                        type="text" 
                        v-model="searchQuery" 
                        placeholder="Entrez un numéro de téléphone ou un email" 
                        @keyup.enter="fetchResults"
                        @input="filterResults"
                    />
                    <!-- Icône de recherche à l'intérieur de l'input -->
                    <button @click="fetchResults" class="search-icon" :disabled="downloadingSearch">
                        <span v-if="downloadingSearch">
                          <i class="fas fa-spinner fa-spin"></i>
                        </span>
                        <span v-else>
                          <i class="fas fa-search"></i>
                        </span>
                    </button>

                </div>
                
                <ul v-if="showDropdown && filteredResults.length > 0" class="dropdown">
                <li
                    v-for="(result, index) in filteredResults"
                    :key="index"
                    @click="selectResult(result)"
                    class="dropdown-item"
                >
                    {{ result.fullName }}
                </li>
                </ul>
            </div>

            <div class="button-reorder">
              <button @click="orderMembers" class="btn-order" :disabled="loadingOrder">
                  <span v-if="loadingOrder">
                    <i class="fas fa-spinner fa-spin"></i>
                  </span>
                  <span v-else>
                    <i class="fas fa-random"></i>
                  </span>
              </button>
            </div>

            <div class="box-list-user"> 
                <div v-for="(user, index) in selectedUser"  
                  :key="index" 
                  class="row-user" 
                >
                  <div class="child-div div-info">
                    <div class="icon-ascension">
                        <i class="fas fa-caret-up"
                        :class="{
                          'white': index===0,
                        }"
                        @click="moveItemUp(index)"
                        ></i>
                        <i class="fas fa-caret-down"
                        :class="{
                          'white': index+1===selectedUser.length,
                        }"
                        @click="moveItemDown(index)"
                        ></i>
                    </div>
                    <div class="contact-pos">
                        {{index+1}}
                    </div>
                    <div class="contact-info">
                        <span class="contact-name">{{ user.fullName }}</span>
                        <span class="contact-phone">{{ user.phone }} • {{ user.email }}</span>
                    </div> 
                  </div>
                  <div class="child-div div-status">
                    {{ user.status }}
                  </div>
                  <div class="child-div delete"
                    :class="{
                          'disabled': !user.isDeletable
                    }"
                    @click="deleteUser(user, index)"
                    :disabled="user.isDeleting"
                  >
                    <span v-if="user.isDeleting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </span>
                    <span v-else>
                      <i class="fas fa-trash"></i>
                    </span>
                  </div>

                </div>

            </div>
            <br/>
            <span class="error-message" v-if="qtyError">{{ qtyError }}</span>
            <br/>

            <div class="submit-container">
              <button type="submit" class="btn-submit" :disabled="loading" @click="invite">
                <span v-if="loading">
                  Traitement en cours... <i class="fas fa-spinner fa-spin"></i>
                </span>
                <span v-else>
                    Envoyer les invitations <i class="fas fa-check-circle"></i>
                </span>
              </button>
            </div>


        </div>

        <div class="block-a2f" v-if="activeIndex === 1">
            <p>
              <br/><br/>Vous avez ajouté {{selectedUser.length}} membres à votre groupe SOL: "{{nameGroup}}". <br/><br/>
              Les invitations sont en cours d'envoi. Assurez-vous que chaque membre confirme sa participation avant le {{ formatStartDate }} pour garantir le lancement du cycle à temps. 
              <br/><br/>N'hésitez pas à les contacter si nécessaire.
            </p>
            <button @click="goToDashboard" class="btn-submit">
              Tableau des SOLs <i class="fas fa-th-list"></i>
            </button>
        </div>
        
    </div>

    <Footer :key="footerKey" />
    
</template>


<script>
import Header from '../shared/HeaderPage.vue';
import Footer from '../shared/FooterPage.vue';
import PageHeader from '../shared/PageHeader.vue';
import axios from 'axios';
import { SERVER_IP } from '../../config';

import { formatDate, checkAuth } from '@/utils/validationUtils';

import { refreshFooterMixin } from '@/mixins/refreshFooterMixin';

export default {
  name: 'AddGroupSavingsMembers',
  mixins: [refreshFooterMixin],

  components: {
      Header,
      PageHeader,
      Footer,
  },

  created() {
    const groupId = this.$route.params.groupId;
    checkAuth();
    
    if (!groupId) {
      this.goBack();  // Si groupId n'est pas défini, retourner à la page précédente
    }
    
  },

  mounted() {
    this.groupID = this.$route.params.groupId;
    this.getMembers();
  },

  computed: {
    formatStartDate() {
        return formatDate(this.startDate);
    },
  },

  data() {
    return {
      searchQuery: "",            // Texte tapé dans l'input
        results: [],          // Tous les résultats possibles
        filteredResults: [],  // Résultats filtrés en fonction de l'input
        selectedUser: [],
        groupID: null,
        maxUsers: null,
        nameGroup: null,
        startDate: new Date(),
        showDropdown: false,  // Contrôle de l'affichage du menu déroulant
        activeIndex: 0,
        downloadingSearch: false,
        loadingOrder: false,
        loading: false,
        qtyError: "",
    };
  },

  methods: {
    goBack() {
      this.$router.go(-1);  // Retourne à la page précédente
    },
    goToDashboard() {
      this.$router.push('/dashboard-group-sol');
    },

    filterResults() {
      // Filtrer les résultats en fonction de la recherche de l'utilisateur
      if (this.searchQuery) {
        this.filteredResults = this.results.filter(result =>{
          // result.fullName.toLowerCase().includes(this.searchQuery.toLowerCase())
          const search = this.searchQuery.toLowerCase();
          return (
            result.fullName?.toLowerCase().includes(search) || 
            result.phone?.toLowerCase().includes(search) || 
            result.email?.toLowerCase().includes(search)
          );
        });
      } else {
        this.filteredResults = [];
      }
      
      this.showDropdown = true;
    },

    selectResult(result) {
      if (this.selectedUser.length >= this.maxUsers) {
        this.qtyError = `Vous avez atteint la quantité maximale de ${this.selectedUser.length} membres pour votre groupe`;
      } else {
        result.isDeletable = true;
        this.selectedUser.push(result);
        this.qtyError = this.selectedUser.length < this.maxUsers ? `Il vous reste ${ this.maxUsers - this.selectedUser.length } membre(s) à choisir.` : '';
      }
      
      this.filteredResults = [];
      this.results = [];
      this.searchQuery = '';
      this.showDropdown = false;
      
    },

    async fetchResults() {
        this.downloadingSearch = true;
        this.results = [];
        const url = `${SERVER_IP}/poolsavings/search-user`;
        try {

          const userId = checkAuth();
          const response = await axios.post(url, {
            name: this.searchQuery,
            userId: userId
          });
          if (response.status === 200 ) {
            this.results = response.data.data;
          }
          
        } catch (error) {
          if (error.response && error.response.status === 400) {
            const { message } = error.response.data;
            if (message === 'no_pin') {
              console.log(message);
            }
        }
      } finally {
        this.downloadingSearch = false;
      }

      this.filterResults();
      
    },

    async getMembers() {

        const url = `${SERVER_IP}/poolsavings/select-member`;
        try {
          const response = await axios.post(url, {
            groupId: this.groupID
          });
          if (response.status === 200 ) {
            this.selectedUser = response.data.members;
            this.maxUsers = response.data.maxUsers;
            this.nameGroup = response.data.group_name;
            this.startDate = response.data.start_date;
            this.qtyError = this.selectedUser.length < this.maxUsers ? `Il vous reste ${ this.maxUsers - this.selectedUser.length } membre(s) à choisir.` : '';
          }
          
          
        } catch (error) {
          console.log(error);
        }
    },

    async deleteUser(user, index){
      if (!user.isDeletable || user.isDeleting) return;

      // Créez une référence locale de l'utilisateur
      this.selectedUser[index].isDeleting = true; // Marquer comme en cours de suppression


      try {

        // URL de l'API
        const url = `${SERVER_IP}/poolsavings/delete-member`;

        // Supprimer l'utilisateur via l'API s'il a un id
        if (user.id) {
            await axios.post(url, { rowId: user.id});
        }
        
        // Supprimer l'utilisateur de la liste
        this.selectedUser.splice(index, 1);
        
      } catch (error) {
          console.error("Erreur lors de la suppression de l'utilisateur :", error);
          // Ajouter ici la gestion des erreurs (par exemple, afficher un message d'erreur)
      } finally {
          // Réinitialiser l'état de suppression seulement si l'utilisateur est encore présent
          this.selectedUser.splice(index, 1);
          const remainingUser = this.selectedUser.find(u => u === user);
          if (remainingUser) remainingUser.isDeleting = false;
      }
    },

    async invite(){
      if (this.loading) {
        return;
      }

      this.loading = true;
      const url = `${SERVER_IP}/poolsavings/invitation`;
      try {
        const userId = checkAuth();
        const response = await axios.post(url, {
          members: this.selectedUser,
          groupId: this.groupID,
          userId: userId
        });
        if (response.status === 200 ) {
          this.activeIndex = 1
        }
        
      } catch (error) {
            if (error.response && error.response.status === 400) {
              this.qtyError = "Une erreur inconnue est survenue, veuillez réessayer"
          }
        } finally {
          this.loading = false;
        }
        
    },


    orderMembers() {
        this.loadingOrder = true;

        // Attendre 5 secondes avant d'exécuter le réordonnancement
        setTimeout(() => {
            // Mélanger la liste selectedUser de manière aléatoire
            for (let i = this.selectedUser.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                // Échange des éléments aux indices i et j
                [this.selectedUser[i], this.selectedUser[j]] = [this.selectedUser[j], this.selectedUser[i]];
            }

            // Désactiver l'indicateur de chargement
            this.loadingOrder = false;
        }, 2000); // Délai de 5 secondes (5000 ms)
    },

    moveItemUp(index) {
      // Vérifier que l'élément n'est pas le premier
      if (index > 0) {
        // Échanger les éléments aux indices index et index - 1
        [this.selectedUser[index], this.selectedUser[index - 1]] = [this.selectedUser[index - 1], this.selectedUser[index]];
      }
    },

    // Déplacer un élément vers le bas
    moveItemDown(index) {
      // Vérifier que l'élément n'est pas le dernier
      if (index < this.selectedUser.length - 1) {
        // Échanger les éléments aux indices index et index + 1
        [this.selectedUser[index], this.selectedUser[index + 1]] = [this.selectedUser[index + 1], this.selectedUser[index]];
      }
    },

  },

};

</script>


<style scoped>

.block-a2f{
max-width: 400px;
margin: auto;
padding: 0px;
text-align: center;
color: #ffffff;
padding-left: 10px;
padding-right: 10px;
font-size: 0.8rem;
}
.block-a2f p{
  font-size: 1rem;
}
.block-a2f p a{
    color: #03a5fc;
    text-decoration: none;
}
.block-a2f p a:hover{
    color: #08489c;
}

.add-members-group-savings-page {
max-width: 400px;
margin: auto;
padding: 0px;
text-align: center;
color: #ffffff;
padding-left: 10px;
padding-right: 10px;
margin-bottom: 5.5%;
font-size: 0.8em;
}

.mockup-image {
max-width: 70px;
margin: 20px auto;
display: block;
}

.label-search {
  text-align: left;
  margin-bottom: 5px;
}

.box-search-user {
  margin-top: 30px;
}

/* Style de l'input de recherche */
.search-input {
  position: relative;
  flex-grow: 1;
  margin-right: 15px; /* Espace entre l'input et le bouton filters */
  max-width: 88%;
}

.search-input input {
  width: 100%;
  padding: 10px 40px 10px 10px;
  background-color: white; /* Arrière-plan blanc */
  color: black; /* Texte noir */
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 12px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
}

.search-input input::placeholder {
  color: lightgray; /* Placeholder gris clair */
}

/* Icône de recherche à l'intérieur de l'input */
.search-input .search-icon {
  position: absolute;
  right: -13%;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: black; /* Couleur de l'icône de recherche */
  cursor: pointer;
  font-size: 18px;
  padding-right: 5px;
}

.combo-box {
  position: relative;
  width: 100%;
}

.dropdown {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  width: 90%;
  max-height: 150px;
  overflow-y: auto;
  z-index: 10;
  text-align: left;
  margin-top: 1px;
  border-radius: 5px;
}

.dropdown-item {
  padding: 8px;
  cursor: pointer;
  color: #000;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}


.button-reorder {
  width: 100%;
  text-align: right;
  color: #FFF;
  margin-top: 15px;
}

.btn-order {
  font-size: 18px;
  background: none;
  border: none;
  color: #FFF;
  cursor: pointer;
}


/****************** Zone liste user choisis *************************/

.box-list-user {
  background-color: #FFF;
  color: #FFF;
  border-radius: 15px;
  min-height: 30%;
  padding: 10px 0px;
  height: 30vh; /* 30% de la hauteur de la fenêtre */
  overflow-y: scroll;
  scrollbar-width: none; /* Masquer la barre de défilement sur Firefox */
}
.box-list-user::-webkit-scrollbar {
    display: none; /* Masquer la barre de défilement sur Chrome, Safari et Edge */
}

.row-user {
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: center;
}

.row-user .div-info {
  flex: 7;
  text-align: left;
  color: black;
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  align-items: center;
}
.row-user .div-status {
  flex: 2;
  font-style: italic;
  color: green;
  text-align: center;
  font-size: 0.8em;
}
.row-user .delete {
  flex: 1;
  color:#016A40;
  font-size: 18px;
  text-align: right;
}
.row-user .delete i {
  cursor: pointer;
}
.row-user .delete i:hover {
color: #005934;
}
.row-user .delete i:active {
color: #003822;
}
.row-user .delete.disabled { 
 color: #999;
}
.row-user .delete.disabled i:hover {
color: #999;
}
.row-user .child-div{
  padding: 10px;
}

.row-user .div-info .contact-info{
  display: flex;
  flex-direction: column;
}
.row-user .div-info .contact-name {
  font-weight: bold;
  font-size: 0.8rem;
  color: #000;
}
.row-user .div-info .contact-phone {
  font-style: italic;
  font-size: 0.7rem;
  color: #555;
} 

.row-user .div-info .contact-pos {
  background-color: #FFF;
  color: #000;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: bold;
  border: 1px solid #ccc;
}

.row-user .div-info .icon-ascension {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000;
  font-size: 1rem;
}
.row-user .div-info .icon-ascension i {
  cursor: pointer;
  margin: 4px 0;
}

.error-message {
  font-size: 0.8rem; /* Ajustez la taille de la police selon vos besoins */
  font-weight: normal; /* Ajustez le poids de la police si nécessaire */
  transition: font-size 0.3s; /* Transition pour une animation douce */
  color: #FFFC00; /* Couleur du texte rouge */
  font-style: italic; /* Texte en italique */
  text-align: left;
}

.submit-container {
  text-align: center;
  margin-top: 20px;
}

.btn-submit {
  background-color: #016A40; /* Fond vert */
  color: white; /* Texte blanc */
  border: none;
  border-radius: 5px; /* Coins moins circulaires */
  padding: 10px 20px; /* Ajustement du rembourrage */
  cursor: pointer;
  transition: background-color 0.3s; /* Transition pour l'animation */
  width: auto;
}

.btn-submit:hover {
  background-color: #005934; /* Changement de couleur au survol */
}

.btn-submit:active {
  background-color: #003822; /* Changement de couleur lorsqu'il est cliqué */
}

.white {
  color: #FFF;
}

</style>