// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';

// Importer les composants pour les routes
import RegisterForm from '@/components/auth/RegisterForm.vue';
import SuccessRegister from '@/components/auth/SuccessRegister.vue';
import ForgotPassword from '@/components/auth/ForgotPassword.vue';
import RecoveryPassword from '@/components/auth/RecoveryPassword.vue';
import AccountActivation from '@/components/auth/AccountActivation.vue';
import LoginPage from '@/components/auth/LoginPage.vue';

import TermsConditions from '@/components/legal/TermsConditions.vue';
import PrivacyPolicy from '@/components/legal/PrivacyPolicy.vue';

import HomePage from '@/components/home/HomePage.vue';

import ContactPage from '@/components/ContactPage.vue';
import AboutPage from '@/components/AboutPage.vue';
import NewsLetter from '@/components/NewsLetter.vue';
import UserProfile from '@/components/UserProfile.vue';
import ChangePassword from '@/components/ChangePassword.vue';
import UserPreferences from '@/components/UserPreferences.vue';

import BasicTransfer from '@/components/transaction/BasicTransfer.vue';
import BasicWithdraw from '@/components/transaction/BasicWithdraw.vue';
import BasicDeposit from '@/components/transaction/BasicDeposit.vue';

import ConfirmWithdraw from '@/components/transaction/ConfirmWithdraw.vue';
import ConfirmDeposit from '@/components/transaction/ConfirmDeposit.vue';
import TrackTransaction from '@/components/transaction/TrackTransaction.vue';

import HistoricTransactions from '@/components/transaction/HistoricTransactions.vue';

import NotificationsPage from '@/components/NotificationsPage.vue';
import CreatePin from '@/components/auth/CreatePin.vue';

import DailyRates from '@/components/exchanges/DailyRates.vue';

import ListGames from '@/components/games/ListGames.vue';


import AddLongTerm from '@/components/savings/AddLongTerm.vue';
import ManageSavings from '@/components/savings/ManageSavings.vue';

import CreateGroupSavings from '@/components/savingspool/CreateGroupSavings.vue';
import AddGroupSavingsMembers from '@/components/savingspool/AddGroupSavingsMembers.vue';
import InvitationSavingsGroup from '@/components/savingspool/InvitationSavingsGroup.vue';
import DashboardSavingsGroups from '@/components/savingspool/DashboardSavingsGroups.vue';
import SavingGroupConfiguration from '@/components/savingspool/SavingGroupConfiguration.vue';


import DashboardEvents from '@/components/events_tickets/DashboardEvents.vue';
import CreateEvents from '@/components/events_tickets/CreateEvents.vue';




const routes = [
  {
    path: '/home',
    name: 'HomePage',
    component: HomePage,
    meta: { title: 'Kob Direk - Accueil' },
  },
  {
    path: '/',
    name: 'LoginPage',
    component: LoginPage,
    meta: { title: 'Kob Direk - Connexion' },
  },
  {
    path: '/register-form',
    name: 'RegisterForm',
    component: RegisterForm,
    meta: { title: 'Kob Direk - Inscription' },
  },
  {
    path: '/success-register',
    name: 'SuccessRegister',
    component: SuccessRegister,
    meta: { title: 'Kob Direk - Inscription' },
  },
  {
    path: '/activation',
    name: 'AccountActivation',
    component: AccountActivation,
    meta: { title: 'Kob Direk - Activation' },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: { title: 'Kob Direk - Demande de nouveau mot de passe' },
  },
  {
    path: '/recovery-password',
    name: 'RecoveryPassword',
    component: RecoveryPassword,
    meta: { title: 'Kob Direk - Changement de mot de passe' },
  },
  {
    path: '/terms-conditions',
    name: 'TermsConditions',
    component: TermsConditions,
    meta: { title: 'Kob Direk - Conditions' },
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta: { title: 'Kob Direk - Confidentialité' },
  },


  {
    path: '/contact-page',
    name: 'ContactPage',
    component: ContactPage,
    meta: { title: 'Kob Direk - Contact' },
  },
  {
    path: '/about',
    name: 'AboutPage',
    component: AboutPage,
    meta: { title: 'Kob Direk - A Propos' },
  },
  {
    path: '/newsletter',
    name: 'NewsLetter',
    component: NewsLetter,
    meta: { title: 'Kob Direk - NewsLetter' },
  },
  {
    path: '/profile',
    name: 'UserProfile',
    component: UserProfile,
    meta: { title: 'Kob Direk - Profile' },
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: { title: 'Kob Direk - Modifier Mot de passe' },
  },
  {
    path: '/settings',
    name: 'UserPreferences',
    component: UserPreferences,
    meta: { title: 'Kob Direk - Modifier ses préférences' },
  },

  {
    path: '/transfer',
    name: 'BasicTransfer',
    component: BasicTransfer,
    meta: { title: 'Kob Direk - Transfert' },
  },
  {
    path: '/retrait',
    name: 'BasicWithdraw',
    component: BasicWithdraw,
    meta: { title: 'Kob Direk - Retrait' },
  },
  {
    path: '/depot',
    name: 'BasicDeposit',
    component: BasicDeposit,
    meta: { title: 'Kob Direk - Dépôt' },
  },
  
  {
    path: '/confirm-retrait',
    name: 'ConfirmWithdraw',
    component: ConfirmWithdraw,
    meta: { title: 'Kob Direk - Confirmation Retrait' },
  },
  {
    path: '/confirm-depot',
    name: 'ConfirmDeposit',
    component: ConfirmDeposit,
    meta: { title: 'Kob Direk - Confirmation Dépôt' },
  },
  {
    path: '/tracking',
    name: 'TrackTransaction',
    component: TrackTransaction,
    meta: { title: 'Kob Direk - Suivi Transaction' },
  },
  {
    path: '/historic-transations',
    name: 'HistoricTransactions',
    component: HistoricTransactions,
    meta: { title: 'Kob Direk - Historique de Transactions' },
  },
  
  
  {
    path: '/notifications',
    name: 'NotificationsPage',
    component: NotificationsPage,
    meta: { title: 'Kob Direk - Notifications' },
  },

  {
    path: '/create-pin',
    name: 'CreatePin',
    component: CreatePin,
    meta: { title: 'Kob Direk - PIN' },
  },


  
  {
    path: '/daily-rates',
    name: 'DailyRates',
    component: DailyRates,
    meta: { title: 'Kob Direk - Taux du jour' },
  },


  {
    path: '/games',
    name: 'ListGames',
    component: ListGames,
    meta: { title: 'Kob Direk - Choix Jeu' },
  },


  
  {
    path: '/place-savings',
    name: 'AddLongTerm',
    component: AddLongTerm,
    meta: { title: 'Kob Direk - Placer Epargne' },
  },
  {
    path: '/my-savings',
    name: 'ManageSavings',
    component: ManageSavings,
    meta: { title: 'Kob Direk - Mes Epargnes' },
  },

  
  {
    path: '/create-pool-savings',
    name: 'CreateGroupSavings',
    component: CreateGroupSavings,
    meta: { title: 'Kob Direk - Création Group de Sol' },
  },
  {
    path: '/group/:groupId/add-members-saving',
    name: 'AddGroupSavingsMembers',
    component: AddGroupSavingsMembers,
    meta: { title: 'Kob Direk - Gestion Membres Sol' },
    props: true
  },
  {
    path: '/group/:ref/group-invit',
    name: 'InvitationSavingsGroup',
    component: InvitationSavingsGroup,
    meta: { title: 'Kob Direk - Invitation Sol' },
    props: true
  },
  {
    path: '/dashboard-group-sol',
    name: 'DashboardSavingsGroups',
    component: DashboardSavingsGroups,
    meta: { title: 'Kob Direk - Dashboard Sol' },
  },
  {
    path: '/group-sol/:ref/configuration',
    name: 'SavingGroupConfiguration',
    component: SavingGroupConfiguration,
    meta: { title: 'Kob Direk - Configuration Groupe Sol' },
    props: true
  },
  
  {
    path: '/dashboard-events',
    name: 'DashboardEvents',
    component: DashboardEvents,
    meta: { title: `Kob Direk - Centre des Événements` },
    props: true
  },
  {
    path: '/create-events',
    name: 'CreateEvents',
    component: CreateEvents,
    meta: { title: `Kob Direk - Créer un événement` },
    props: true
  },
  
  
  
  
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Kob Direk';
  next();
});

export default router;
