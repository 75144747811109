<template>
    <Header />
    <PageHeader title="Créer un événement" :fontSize="'1.75rem'" :goBack="customGoBack" />
  
    <div class="creating-events">

        <div class="container-menu" v-if="menuItemsIndex < 6">
            <div
            v-for="(item, index) in menuItems"
            :key="index"
            class="box"
            :class="{ active: menuItemsIndex === index }"
            >
            <p class="name">{{ item.name }}</p>
            <div class="underline"></div>
            </div>
        </div>

        <div class="event-info-container" v-if="menuItemsIndex === 0">
          <h2 class="section-title">Informations Générales</h2>
          
          <form class="event-form">
            <!-- Nom de l'événement -->
            <div class="form-group">
              <label for="event-name">Nom de l'événement</label>
              <input 
                type="text" 
                id="event-name" 
                v-model="form.name" 
                placeholder="Entrez le nom de l'événement" 
                @input="handleNameInput" 
              />
              <span v-if="nameError" class="error-message">{{ nameError }}</span>
            </div>
            
            <!-- Catégorie -->
            <div class="form-group">
              <label for="event-category">Catégorie</label>
              <select id="event-category" v-model="form.category" @change="handleCategoryInput" required>
                <option value="" disabled selected>Choisissez une catégorie</option>
                <option 
                  v-for="(category, index) in categoryOptions" 
                  :key="index" 
                  :value="category"
                >
                  {{ category }}
                </option>
              </select>
              <span v-if="categoryError" class="error-message">{{ categoryError }}</span>
            </div>
            
            <!-- Description -->
            <div class="form-group">
              <label for="event-description">Description</label>
              <textarea id="event-description" v-model="form.description" rows="4" placeholder="Décrivez votre événement..."></textarea>
            </div>
            
            <!-- Image Upload -->
            <div class="form-group file-upload">
              <label for="event-image">Image de l'événement</label>
              <input type="file" id="event-image" accept="image/*" @change="previewImage" />
              <div class="file-preview" @click="loadImage">
                <template v-if="form.imagePreviewUrl">
                  <img :src="form.imagePreviewUrl" alt="Prévisualisation de l'image" class="preview-image" />
                </template>
                <template v-else >
                  <i class="fas fa-upload"></i>
                  <span >Aucune image sélectionnée</span>
                </template>
              </div>
            </div>
            
          </form>
        </div>

        <div class="event-coordinates" v-if="menuItemsIndex === 1">
          <h2 class="section-title">Coordonnées de l'événement</h2>
          <form class="event-form">
              <!-- Date et Heure -->
              <div class="form-group">
                <label for="event-date">Date et Heure :</label>
                <input 
                  type="datetime-local" 
                  id="event-date" 
                  v-model="form.dateTime"
                  @input="handleDateInput" 
                  required 
                />
                <span v-if="dateTimeError" class="error-message">{{ dateTimeError }}</span>
              </div>

              <!-- Type d'événement -->
              <div class="form-group">
                <label>Type d'événement :</label>
                <div class="radio-group">
                  <label>
                    <input 
                      type="radio" 
                      value="physique" 
                      v-model="form.eventType"
                      @change="handleTypeInput" 
                      required 
                    />
                    <i class="fas fa-map-marker-alt"></i> Physique
                  </label>
                  <label>
                    <input 
                      type="radio" 
                      value="online" 
                      v-model="form.eventType" 
                      @change="handleTypeInput" 
                      required 
                    />
                    <i class="fas fa-globe"></i> Online
                  </label>
                </div>
              </div>

              <!-- Adresse ou URL -->
              <div class="form-group">
                <label v-if="form.eventType === 'physique'" for="event-address">Adresse :</label>
                <label v-else for="event-url">URL :</label>
                <input 
                  v-if="form.eventType === 'physique'" 
                  type="text" 
                  id="event-address" 
                  v-model="form.address" 
                  placeholder="Entrez l'adresse de l'événement" 
                  @input="handleAdressInput"
                  required 
                />
                <input 
                  v-else 
                  type="url" 
                  id="event-url" 
                  v-model="form.url" 
                  placeholder="Entrez le lien de l'événement" 
                  @input="handleAdressInput"
                  required 
                />
                <span v-if="adressError" class="error-message">{{ adressError }}</span>
              </div>
          </form>
          

        </div>

        <div class="ticket-management" v-if="menuItemsIndex === 2">
          <h2 class="section-title">Gestion des Billetteries</h2>
          
          <form class="event-form"  @submit.prevent="handleSubmit">
            <!-- Nombre de places disponibles et Date limite de vente -->
            <div class="inputs">
              <div class="input-group">
                <label for="availableSeats">Nombre de places</label>
                <input 
                  type="number" 
                  id="availableSeats" 
                  placeholder="500" 
                  v-model="availableSeats" 
                  @input="handleTicketPlaceInput"
                  required 
                />
                <span v-if="availableSeatsError " class="error-message">{{ availableSeatsError  }}</span>
              </div>
              
              <div class="input-group">
                <label for="saleDeadline">Date limite de vente</label>
                <input 
                  type="datetime-local" 
                  id="saleDeadline"
                  placeholder="Date limite de vente des tickets" 
                  v-model="saleDeadline"
                  @input="handleTicketDateInput" 
                />
                <span v-if="saleDeadlineError " class="error-message">{{ saleDeadlineError  }}</span>
              </div>
            
                <div class="inputs-row">
                  <!-- Nom du Ticket -->
                  <div class="input-group name">
                    <label for="ticketName">Nom</label>
                    <input type="text" id="ticketName" @input="handleTicketNameInput"  placeholder="Nom du ticket" v-model="ticketName" />
                    <span v-if="ticketNameError " class="error-message">{{ ticketNameError  }}</span>
                  </div>

                  <!-- Prix du Ticket -->
                  <div class="input-group price">
                    <label for="ticketPrice">Prix </label>
                    <input type="number" id="ticketPrice" @input="handleTicketPriceInput"  placeholder="Montant" v-model="ticketPrice" />
                    <span v-if="ticketPriceError " class="error-message">{{ ticketPriceError  }}</span>
                  </div>

                  <!-- Devise -->
                  <div class="input-group currency">
                    <label for="currency">Devise</label>
                    <select id="currency" v-model="currency" required>
                      <option value="USD">USD</option>
                      <option value="HTG">HTG</option>
                    </select>
                  </div>
                  
                </div>              
                
                <button class="btn-create-ticket" @click="createTicket">
                  Créer le Ticket
                </button>
                <div>
                  <span v-if="ticketError " class="error-message">{{ ticketError  }}</span>
                </div>
                
            </div>

            <!-- Liste des tickets créés -->
            <div class="ticket-list" v-if="tickets.length > 0">
              <h3>Tickets Créés</h3>
              <ul>
                <li v-for="(ticket, index) in tickets" :key="index">
                  <span>{{ ticket.name }} - {{ ticket.price }} {{ ticket.currency }} [{{ticket.availableSeats}}]</span>
                  <button @click="deleteTicket(index)" class="delete-btn"><i class="fas fa-trash"></i> Supprimer</button>
                </li>
              </ul>
            </div>

          </form>
          
        </div>

        <div class="confidentiality-settings" v-if="menuItemsIndex === 3">
          <h2 class="section-title">Confidentialité de l'Événement</h2>
          <div class="visibility-options">
            <label class="option">
              <input 
                type="radio" 
                name="visibility" 
                value="public" 
                v-model="form.visibility"
              />
              <span class="custom-radio"></span>
              Public
            </label>

            <label class="option">
              <input 
                type="radio" 
                name="visibility" 
                value="private" 
                v-model="form.visibility"
              />
              <span class="custom-radio"></span>
              Privé
            </label>
          </div>

          <p class="description">
            <span v-if="form.visibility === 'public'">
              ⚠️ Un événement ** Public ** sera visible par tout le monde.
            </span>
            <span v-else>
              🔒 Un événement ** Privé ** sera uniquement visible par les personnes invitées.
            </span>
          </p>
        </div>

        <div class="terms-container" v-if="menuItemsIndex === 4">
          <h2 class="section-title">Termes et Conditions</h2>
          <div class="terms-content">
            
            <p>
              En créant un événement sur notre plateforme, vous acceptez de respecter les termes et conditions suivants :
            </p>

            <hr />

            <h4>1. Authenticité des événements</h4>
            <p>
              • Vous vous engagez à ne pas créer d’événements frauduleux ou mensongers. Chaque événement publié doit être réel, correspondre à la description fournie et respecter la législation en vigueur.
            </p>
            <p>
              • Toute tentative de manipulation ou de tromperie entraînera des sanctions, notamment la suspension de votre compte et des poursuites légales si nécessaire.
            </p>

            <hr />

            <h4>2. Paiement des fonds</h4>
            <p>
              • La somme totale des billets vendus sera transférée sur votre compte <strong>24 heures après le déroulement prévu de l’événement</strong>.
            </p>
            <p>
              • Les fonds ne seront pas versés si des enquêtes sont en cours ou si l’événement est signalé comme n’ayant pas eu lieu.
            </p>

            <hr />

            <h4>3. Gestion des réclamations</h4>
            <p>
              • Si des participants signalent que l’événement n’a pas eu lieu ou qu’il ne correspond pas à ce qui a été annoncé, une enquête sera immédiatement lancée.
            </p>
            <p>
              • Durant les <strong>24 heures suivant l’événement</strong>, les fonds seront retenus pour permettre une vérification approfondie.
            </p>
            <p>
              • Si des preuves d’abus ou de non-respect des conditions sont établies, la somme des billets pourra être remboursée aux acheteurs, et des mesures disciplinaires seront prises contre l’organisateur.
            </p>

            <hr />

            <h4>4. Responsabilité de l’organisateur</h4>
            <p>
              • Vous êtes entièrement responsable de la gestion, de l’organisation et de la réussite de votre événement.
            </p>
            <p>
              • En cas d’annulation ou de modification majeure de l’événement, vous êtes tenu d’informer immédiatement les participants et de leur proposer une solution (report, remboursement, etc.).
            </p>
            <p>
              • Toute négligence dans la communication ou la gestion peut entraîner la suspension de votre compte.
            </p>

            <hr />

            <h4>5. Utilisation de la plateforme</h4>
            <p>
              • Vous devez fournir des informations exactes, complètes et honnêtes lors de la création de l’événement.
            </p>
            <p>
              • Vous vous engagez à ne pas utiliser la plateforme pour des activités illégales, immorales ou non conformes à nos politiques.
            </p>
            <p>
              • Les événements à caractère discriminatoire, haineux, violent ou illicite sont strictement interdits.
            </p>

            <hr />

            <h4>6. Commissions et frais</h4>
            <p>
              • Une commission (précisée au moment de la création) sera prélevée sur chaque billet vendu pour couvrir les frais de gestion et de service de la plateforme.
            </p>
            <p>
              • Les montants finaux transférés sur votre compte tiendront compte de cette commission.
            </p>

            <hr />

            <h4>7. Protection des participants</h4>
            <p>
              • Vous devez garantir la sécurité des participants pendant l’événement.
            </p>
            <p>
              • La plateforme décline toute responsabilité en cas de dommage, d’accident ou de problème survenant lors de l’événement.
            </p>

            <hr />

            <h4>8. Litiges</h4>
            <p>
              • En cas de litige avec un participant, vous acceptez de collaborer avec la plateforme pour résoudre le problème dans les meilleurs délais.
            </p>
            <p>
              • En cas de non-coopération, des mesures restrictives pourront être appliquées, y compris la suspension des paiements ou du compte.
            </p>

            <hr />

            <h4>9. Annulation ou suspension de l’événement</h4>
            <p>
              • La plateforme se réserve le droit de suspendre ou d’annuler un événement si des preuves de fraude ou d’abus sont détectées.
            </p>
            <p>
              • Vous serez informé(e) en cas de problème et aurez la possibilité de fournir des explications ou des preuves.
            </p>

            <hr />

            <h4>10. Acceptation des conditions</h4>
            <p>
              • En créant un événement, vous reconnaissez avoir lu, compris et accepté ces termes et conditions.
            </p>
            <p>
              • Ces termes peuvent être modifiés à tout moment. Les mises à jour seront communiquées via la plateforme.
            </p>

            <hr />

            <p><strong>Votre engagement est essentiel pour maintenir une plateforme fiable et sûre pour tous.</strong></p>


          </div>

          <div class="accept-terms">
              <input 
                type="checkbox" 
                name="terms" 
                v-model="termsAccepted"
                class="custom-checkbox"
              />
              <span @click="clickTerms">J'accepte les termes et conditions</span>
          </div>


        </div>

        <div class="event-preview" v-if="menuItemsIndex === 5">
          <!-- Catégorie -->
          <div class="event-category">{{ form.category }}</div>

          <!-- Image -->
          <div class="event-image-container">
            <img :src="form.imagePreviewUrl || 'placeholder.jpg'" alt="Image de l'événement" class="preview-image" />
          </div>

          <!-- Informations principales -->
          <div class="event-details">
            <h2 class="event-title">{{ form.name }}</h2>
            <p class="event-organizer">par {{ organizerName }}</p>
          </div>

          <!-- Sous-div des informations secondaires -->
          <div class="event-secondary-details">
            <!-- Sous-div gauche -->
            <div class="event-location-time">
              <p v-if="form.eventType === 'physique'">
                <i class="fas fa-map-marker-alt"></i> {{ form.address }}
              </p>
              <p v-else>
                <i class="fas fa-globe"></i> {{ form.url }}
              </p>
              <p><i class="fas fa-calendar-alt"></i> {{ formatShortDateText(form.dateTime) }}</p>
              <p><i class="fas fa-clock"></i> {{ formatTimeText(form.dateTime) }}</p>
            </div>

            <!-- Sous-div droite -->
            <div class="event-stats">
              <p><i class="fas fa-star"></i> 0/5</p>
              <p><i class="fas fa-users"></i> X participants</p>
            </div>
          </div>
        </div>

        <div class="block-a2f" v-if="menuItemsIndex === 6">
            <h3>Événement créé avec succès !</h3>
            <p>Votre événement a été créé et est désormais disponible dans votre tableau de bord.</p>
            
            <p>Merci d'utiliser notre plateforme pour organiser vos événements ! <i class="fas fa-smile"></i></p>

            <button @click="gotoEvents" class="btn-submit">
                    <i class="fas fa-hand-point-right"></i> Centre des événements 
            </button>
            
        </div>


        <div class="button-group" v-if="menuItemsIndex < 6" >
          <button class="btn cancel" @click="cancel">
            <i class="fas fa-times-circle"></i> Annuler
          </button>

          <div class="action-buttons">
            <button class="btn previous" @click="previousMenu">
              <i class="fas fa-arrow-left"></i> Précédent
            </button>

            <button class="btn next" @click="nextMenu" :disabled="loading">
              <template v-if="loading">
                <i class="fas fa-spinner fa-spin"></i> Chargement...
              </template>
              <template v-else-if="menuItemsIndex === 5">
                Créer <i class="fas fa-plus-circle"></i>
              </template>
              <template v-else>
                Suivant <i class="fas fa-arrow-right"></i>
              </template>
            </button>
          </div>
        </div>
      
    </div>
  
    <Footer :key="footerKey" />
  
</template>
    
<script>
      import Header from '../shared/HeaderPage.vue';
      import Footer from '../shared/FooterPage.vue';
      import PageHeader from '../shared/PageHeader.vue';
      import axios from 'axios';
      import { SERVER_IP } from '../../config';

      import * as eventUtilities from "../../utils/events.js";
      import { checkAuth, formatShortDateText, formatTimeText } from '@/utils/validationUtils';
  
      export default {
          name: "CreateEvents",
          components: {
              Header,
              PageHeader,
              Footer,
          },   
          
          created(){
            checkAuth();
          },
          
          data() {
            return {
              menuItemsIndex: 0, // La position active (par exemple : 2 = troisième div)
              loading: false,
              menuItems: [
                  { name: "Informations" },
                  { name: "Coordonnées" },
                  { name: "Billetterie" },
                  { name: "Confidentialités" },
                  { name: "Termes" },
              ],

              form: {
                name: "",
                category: "",
                description: "",
                imagePreviewUrl: null, // Stocke l'URL pour la prévisualisation de l'image: null,
                imageFile: null, // Contient le fichier sélectionné

                dateTime: "",
                eventType: "physique",
                address: "",
                url: "",

                visibility: 'public', // Valeur par défaut
              },

              nameError: "",
              categoryError: "",
              dateTimeError: "",
              adressError:"",

              availableSeats: 0,  // Nombre de places disponibles
              saleDeadline: "",   // Date limite de vente
              ticketName: "",     // Nom du ticket
              ticketPrice: 0,     // Prix du ticket
              currency: "HTG",    // Devise du ticket
              tickets: [],        // Liste des tickets créés
              
              saleDeadlineError: "",
              availableSeatsError: "",
              ticketPriceError: "",
              ticketNameError: "",
              ticketError: "",

              termsAccepted: false, // Gère l'état de la case à cocher
              organizerName: "Votre nom complet",

            };
        },

        mounted() {     
          this.getOrganizer(); // Appel à l'API pour récupérer les info de l'organisateur
        },

        computed: {
          categoryOptions() {
            return eventUtilities.categoriesList; // Retourne les catégories pour les options
          },
        },

        methods: {
          handleNameInput() {
            this.nameError = ""; // Masquez le message d'erreur
          },
          handleCategoryInput() {
            this.categoryError = "";
          },
          handleDateInput() {
            this.dateTimeError = "";
          },
          handleAdressInput() {
            this.adressError = "";
          },
          handleTypeInput() {
            this.adressError = "";
          },

          handleTicketPlaceInput() {
            this.availableSeatsError = "";
            this.ticketError = "";
          },
          handleTicketDateInput() {
            this.saleDeadlineError = "";
            this.ticketError = "";
          },
          handleTicketPriceInput() {
            this.ticketPriceError = "";
            this.ticketError = "";
          },
          handleTicketNameInput() {
            this.ticketNameError = "";
            this.ticketError = "";
          },

          formatShortDateText(date) {
              return formatShortDateText(date);
          },
          formatTimeText(date) {
            return formatTimeText(date);
          },
          gotoEvents() {
            this.$router.push('/dashboard-events');
          },

          clickTerms() {
            const termsCheckbox = document.querySelector('.custom-checkbox');
            if (termsCheckbox) {
              termsCheckbox.click();
            }
          },

          previewImage(event) {
            const file = event.target.files[0]; // Récupère le fichier sélectionné
            if (file) {
              this.form.imagePreviewUrl = URL.createObjectURL(file); // Crée une URL pour la prévisualisation
              this.form.imageFile = file;
            } else {
              this.form.imagePreviewUrl = null; // Réinitialise si aucun fichier n'est sélectionné
            }
          },
          loadImage() {
            const fileInput = document.getElementById('event-image'); // Récupère l'élément via l'ID
            if (fileInput) {
              fileInput.click(); // Déclenche le clic
            }
          },


          scrollToError(field) {
            const element = document.getElementById(field);
            if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
            }
            // this.loading = false;
          },


          previousMenu() {
            if(this.menuItemsIndex > 0){
              this.menuItemsIndex -= 1;
            }
          },

          async nextMenu() {
            if(this.menuItemsIndex === 0) {

              let validation = eventUtilities.validateNameEvent(this.form.name);
              if(!validation.isValid){
                this.nameError = validation.errorMessage;
                this.scrollToError("event-name");
                return validation.isValid;
              }

              validation = eventUtilities.validateCategory(this.form.category);
              if(!validation.isValid){
                this.categoryError = validation.errorMessage;
                this.scrollToError("event-category");
                return validation.isValid;
              }

              this.menuItemsIndex = 1;
              
            }

            else if(this.menuItemsIndex === 1) {

              let validation = eventUtilities.validateDateEvent(this.form.dateTime);
              if(!validation.isValid) {
                this.dateTimeError = validation.errorMessage;
                this.scrollToError("event-date");
                return validation.isValid;
              }

              validation = eventUtilities.validateAddressEvent(this.form.eventType, this.form.address, this.form.url);
              if(!validation.isValid) {
                this.adressError = validation.errorMessage;
                this.scrollToError("event-address");
                return validation.isValid;
              }

              this.saleDeadline = this.form.dateTime;
              this.menuItemsIndex = 2;          
            }

            else if(this.menuItemsIndex === 2) {
              if(this.tickets.length < 1) {
                this.ticketError = "Il faut créer au moins un Ticket";
                return false;
              }

              this.menuItemsIndex = 3;
            }

            else if(this.menuItemsIndex === 3) {
              this.menuItemsIndex = 4;
            }

            else if(this.menuItemsIndex === 4 && this.termsAccepted) {
              this.menuItemsIndex = 5;
            }

            else if(this.menuItemsIndex === 5) {
              this.loading = true;
              const url = `${SERVER_IP}/eventsManagement/create`;
              try {
                const userId = checkAuth();
                const formData = new FormData();
                formData.append('organizer_id', userId);
                formData.append('title', this.form.name);
                formData.append('description', this.form.description);
                formData.append('start_date', this.form.dateTime);
                formData.append('image', this.form.imageFile);
                formData.append('event_type', this.form.eventType);
                formData.append('category', this.form.category);
                formData.append('address', this.form.address);
                formData.append('url', this.form.url);
                formData.append('visibility', this.form.visibility);
                formData.append('tickets', JSON.stringify(this.tickets));

                const response = await axios.post(url, formData);
                if (response.status === 200 ) {
                  this.menuItemsIndex = 6;
                }
                
              } catch (error) {
                console.log("Une erreur inconnue est survenue, veuillez réessayer !");
              } finally {
                this.loading = false;
              }

            }

            
          },

          createTicket() {
            if(this.availableSeats <= 0) {
              this.availableSeatsError = "Il faut au moins une place de disponible";
              return false;
            }

            const today = new Date(); // Obtenir la date du jour
            today.setHours(0, 0, 0, 0); // Réinitialiser les heures, minutes, secondes et millisecondes à 0 pour comparer uniquement la date

            const saleDeadlineDate = new Date(this.saleDeadline); // Convertir la date limite en objet Date

            if (!saleDeadlineDate || saleDeadlineDate < today) {
              this.saleDeadlineError = "La date limite ne peut être inférieure à aujourd'hui.";
              return false;
            }

            if(this.ticketName === '') {
              this.ticketNameError = "Il faut entrer un nom pour le ticket";
              return false;
            }

            if(this.ticketPrice < 0) {
              this.ticketPriceError = "Le prix ne peut-etre plus petit que zéro";
              return false;
            }
            
            // Crée un ticket et l'ajoute à la liste
            
              this.tickets.push({
                name: this.ticketName,
                price: this.ticketPrice,
                currency: this.currency,
                availableSeats: this.availableSeats,
                saleDeadline: this.saleDeadline
              });

              // Réinitialiser les champs après création
              this.ticketName = "";
              this.ticketPrice = 0;
              this.currency = "HTG";
              this.availableSeats = 0;
              this.saleDeadline = this.form.dateTime;
              this.ticketError = "";
            
          },

          async getOrganizer() {
            try {
              const userId = checkAuth();
              const url = `${SERVER_IP}/eventsManagement/organizer-name`;
              const response = await axios.post(url, {
                  userId: userId
              });

            if (response.status === 200 ) {
                // On met à jour les données locales après réception de l'API
                this.organizerName = response.data.name;
            }
              
            } catch (error) {
              console.log(error.message);
              this.$router.go(-1);
            }
          },

          deleteTicket(index) {
            // Supprime un ticket de la liste
            this.tickets.splice(index, 1);
          },


        },
          
      };
  
</script>
    
<style scoped>
    .creating-events {
      max-width: 400px;
      margin: auto;
      padding: 0px;
      text-align: center;
      color: #ffffff;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 5.5%;
      font-size: 0.8rem;
    }

  
    .block-a2f{
    max-width: 400px;
    margin: auto;
    padding: 0px;
    text-align: center;
    color: #ffffff;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 0.8rem;
    margin-top: 5%;
    padding-top: 5%;
    }
    .block-a2f p{
      font-size: 1rem;
    }
    .block-a2f p a{
        color: #03a5fc;
        text-decoration: none;
    }

    .container-menu {
      display: flex; /* Permet de placer les enfants en ligne */
      justify-content: space-between; /* Répartition équitable */
      align-items: center; /* Aligner verticalement les enfants */
      /*width: 100%; /* Le conteneur prend toute la largeur */
      margin-top: 25px;
      max-width: 300px;
    }

    .container-menu .box {
      flex: 1; /* Les enfants prennent la même largeur */
      text-align: center; /* Centrer le contenu */
      margin:0 5px;
    }

    .container-menu .name {
      color: #a0a0a0; /* Gris un peu nuancé */
      margin: 0;
      padding: 0;
    }

    .container-menu .underline {
      height: 2px; /* Hauteur du trait */
      background-color: #a0a0a0; /* Couleur grise */
      margin: 0 auto;
      width: 100%; /* Largeur du trait */
      margin-top: 2%;
    }

    /* Style pour l'élément actif */
    .container-menu .box.active .name {
      color: #016A40; /* Vert pour le texte actif */
    }

    .container-menu .box.active .underline {
      background-color: #016A40; /* Vert pour le trait actif */
    }

    .button-group {
      display: flex; /* Aligner horizontalement */
      justify-content: space-between; /* Boutons répartis entre les extrémités */
      align-items: center; /* Alignement vertical */
      padding: 20px 0px;
      border-radius: 10px; /* Coins arrondis pour un look moderne */
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Ombre légère */
      margin-bottom: 15px;
    }

    .button-group .btn {
      display: flex; /* Pour aligner l'icône et le texte */
      align-items: center;
      gap: 8px; /* Espacement entre l'icône et le texte */
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }

    .button-group .btn i {
      font-size: 1.2rem; /* Taille des icônes */
    }

    /* Cancel Button */
    .button-group .cancel {
      background-color: #721c24; /* Rouge clair */
      color: #fff;
    }

    .button-group .cancel:hover {
      background-color: #a71e2b;
    }

    /* Previous Button */
    .button-group .previous {
      color: #0c5460;
    }

    .button-group .previous:hover {
      background-color: #d1ecf1; /* Bleu clair */
      color: #0c5460;
    }

    /* Next Button */
    .button-group .next {
      background-color: #016A40; /* Vert */
      color: #fff;
    }

    .button-group .next:hover {
      background-color: #005934;
    }

    /* Sous-conteneur pour Previous et Next */
    .button-group .action-buttons {
      display: flex;
      gap: 10px; /* Espacement entre Previous et Next */
    }

</style>

<style scoped>
  .event-info-container {
  max-width: 400px;
  margin: auto;
  padding-right: 0px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  color: #FFF;
  margin-bottom: 40px;
}

.section-title {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 40px;
}

.event-form .form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: normal;
  text-align: left;
}

.input-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: normal;
  text-align: left;
}

input,
select,
textarea {
  padding: 0.5em 0;
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid white;
  color: white;
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
  border-bottom: 2px solid #016A40; /* Couleur de focus verte */
}

select option {
  color: #000;
}

.file-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-upload #event-image {
  display: none;
}

.file-upload label {
  text-align: left;
}

.file-upload .file-preview {
  margin-top: 10px;
  padding: 15px;
  border: 1px dashed #ccc;
  border-radius: 5px;
  text-align: center;
  color: #777;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-upload .file-preview i {
  font-size: 2rem;
  margin-bottom: 8px;
}

.form-group{
  text-align: left;
} 

.error-message {
  font-size: 0.8em; /* Ajustez la taille de la police selon vos besoins */
  font-weight: normal; /* Ajustez le poids de la police si nécessaire */
  transition: font-size 0.3s; /* Transition pour une animation douce */
  color: #FFFC00; /* Couleur du texte rouge */
  font-style: italic; /* Texte en italique */
  text-align: left;
}

 /* COORDONEES VIEW */
 .event-coordinates {
  max-width: 400px;
  margin: auto;
  padding-right: 0px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  color: #FFF;
  margin-bottom: 10px;
}

.radio-group {
  display: flex;
  gap: 20px;
}

.radio-group label {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.radio-group input[type="radio"] {
  margin: 0;
}

.radio-group i {
  color: #555;
}

</style>
    
<style scoped>
  .ticket-management {
    max-width: 400px;
    margin: auto;
    padding-right: 0px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    color: #FFF;
    margin-bottom: 10px;
  }

  .ticket-creation {
    margin-bottom: 20px;
  }

  .input-group {
    display: flex;
    flex-direction: column;
    width: 48%;
    margin: 10px 1%;
  }

  .inputs .input-group {
    display: inline-flex;
    flex-direction: column;
    width: 48%; /* Chaque input occupe 48% de la largeur pour être aligné en ligne */
  }

  .btn-create-ticket {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 20px;
  }

  .btn-create-ticket:hover {
    background-color: #45a049;
  }

  .btn-create-ticket:disabled {
    background-color: #ccc;
  }

  .ticket-list {
    margin-top: 20px;
  }

  .ticket-list ul {
    list-style-type: none;
    padding: 0;
  }

  .ticket-list li {
    padding: 10px;
    background-color: #e0f7fa;
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
  }

  .delete-btn {
    padding: 5px 10px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }

  .delete-btn:hover {
    background-color: #e53935;
  }


  .inputs-row {
    display: flex; /* Aligner les éléments sur une ligne */
    gap: 15px; /* Espacement entre les colonnes */
    align-items: flex-start; /* Aligner les inputs en haut */
  }

  .input-group {
    display: flex;
    flex-direction: column; /* Label au-dessus de l'input */
  }

  .input-group.name {
    flex: 3; /* 60% */
  }

  .input-group.price,
  .input-group.currency {
    flex: 1; /* 20% chacun */
  }
  .input-group-span {
    margin:0px;
    text-align: left;
    margin-top: -10px;
  }

</style>

<style scoped>
  .confidentiality-settings {
    max-width: 400px;
    margin: auto;
    padding-right: 0px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    color: #FFF;
    margin-bottom: 10px;
}


.visibility-options {
  display: flex;
  justify-content: space-between;
  margin: 15px 20px;
}

.option {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-size: 1rem;
}

.option input {
  display: none; /* Cacher les boutons radios par défaut */
}

.option .custom-radio {
  width: 16px;
  height: 16px;
  border: 2px solid #ccc;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  background: #fff;
  transition: border-color 0.3s ease;
}

.option input:checked + .custom-radio {
  border-color: #016a40;
  background: #016a40;
}

.option input:checked + .custom-radio::after {
  content: "";
  width: 8px;
  height: 8px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.description {
  font-size: 0.9rem;
  margin: 30px 0px;
}


/* Terms & Conditions */
.terms-container {
  max-width: 400px;
    margin: auto;
    padding-right: 0px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    color: #FFF;
    margin-bottom: 5px;
}

.terms-content {
  height: 45vh; /* Limite la hauteur du texte */
  overflow-y: auto; /* Active le défilement vertical */
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  color: #333;
  line-height: 1.6;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: justify;
  margin-top: -5%;
}

.terms-content::-webkit-scrollbar {
  width: 0; /* Cache la barre de défilement pour un look minimaliste */
}

.accept-terms {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Aligne à gauche */
  margin-top: 10px;
}
.accept-terms span {
  cursor: pointer;
}

.custom-checkbox {
  width: 18px; /* Ajuste la taille de la case à cocher */
  height: 18px;
  appearance: none; /* Supprime le style par défaut du navigateur */
  border: 2px solid #ccc; /* Bordure personnalisée */
  border-radius: 4px; /* Coins légèrement arrondis */
  background-color: white; /* Fond blanc par défaut */
  cursor: pointer; /* Curseur pointer */
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-checkbox:checked {
  background-color: #28a745; /* Fond vert lorsque coché */
  border-color: #28a745; /* Bordure verte */
}

.custom-checkbox:checked::after {
  content: '✔'; /* Ajouter une coche */
  color: white; /* Coche blanche */
}


</style>

<style scoped>
  .event-preview {
  background-color: #101010;
  color: white;
  border-radius: 15px;
  padding: 20px;
  max-width: 600px;
  margin: 20px auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.event-category {
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 15px;
  background-color: #016A40;
  padding: 10px;
  border-radius: 10px;
}

.event-image-container {
  display: flex;
  justify-content: center;
  margin: 15px 0;
}

.preview-image {
  max-width: 350; /* Limite maximale de largeur */
  max-height: 200px; /* Limite maximale de hauteur */
  width: auto; /* Ajuste automatiquement la largeur pour conserver le ratio */
  height: auto; /* Ajuste automatiquement la hauteur pour conserver le ratio */
  border-radius: 15px; /* Coins arrondis */
  object-fit: contain; /* Maintient tout l'image visible dans les limites */
  display: block; /* Aligne correctement l'image */
  margin: 0 auto; /* Centre l'image horizontalement */
}


.event-details {
  margin-top: 15px;
  text-align: left;
}

.event-title {
  font-size: 1.5rem;
  font-weight: bold;
  /* margin: 5px 0; */
  margin-bottom: 0;
}

.event-organizer {
  font-style: italic;
  color: #aaa;
}

.event-secondary-details {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 15px;
  background-color: #1a1a1a;
  border-radius: 10px;
}

.event-location-time,
.event-stats {
  flex: 1;
  margin: 0 10px;
  text-align: left;
}



.event-location-time p,
.event-stats p {
  margin: 5px 0;
}

.event-location-time p i,
.event-stats p i {
  margin-right: 5px;
  color: #00b894;
}


.btn-submit {
  background-color: #016A40; /* Fond vert */
  color: white; /* Texte blanc */
  border: none;
  border-radius: 5px; /* Coins moins circulaires */
  padding: 10px 20px; /* Ajustement du rembourrage */
  cursor: pointer;
  transition: background-color 0.3s; /* Transition pour l'animation */
  width: auto;
  margin-top: 5%;
}

.btn-submit:hover {
  background-color: #005934; /* Changement de couleur au survol */
}

.btn-submit:active {
  background-color: #003822; /* Changement de couleur lorsqu'il est cliqué */
}

</style>