<template>
    <div v-if="isVisible" class="modal-overlay" @click.self="closeModal">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Notifications des SOL</h3>
          <button class="close-btn" @click="closeModal">&times;</button>
        </div>
        <div class="modal-content">
          <div v-if="isLoading" class="loading-container">
            <i class="fas fa-spinner fa-spin"></i>
          </div>
          <div v-else>
            <ul v-if="notifications.length > 0" class="notification-list">
                
                <li v-for="(notification, index) in visibleNotifications" :key="index" class="notification-item">
                    <!-- Header -->
                    <div class="notification-header" @click="toggleContent(index)">
                        <h4>
                            {{ notification.title }}
                            <i :class="notification.icon"></i>
                        </h4>
                        <p class="notification-date">{{ formatShortDateText(notification.created_at) }}</p>

                        <!-- Contenu caché -->
                        <div v-if="activeNotification === index" class="notification-content">
                            <p>{{ notification.content }}</p>
                            <!-- Bloquer la propagation du clic ici -->
                            <i v-if="!notification.isDeleting" class="fas fa-trash delete-icon" @click.stop="deleteNotification(notification)"></i>
                            <i v-else class="fas fa-spinner fa-spin delete-icon"></i> <!-- Spinner ici -->
                        </div>
                    </div>
                    <!-- Barre horizontale -->
                    <hr />
                </li>
                <button
                    v-if="hasMoreNotifications"
                    @click="showMoreNotifications"
                    class="show-more-btn"
                >
                    <i class="fas fa-circle-plus"></i> Afficher plus
                </button>
            </ul>
            <p v-else>Aucune notification disponible pour le moment.</p>
          </div>
        </div>
      </div>
    </div>
</template>
  
<script>
    import axios from 'axios';
    import { SERVER_IP } from '../../config';

    import { checkAuth, formatShortDateText} from '@/utils/validationUtils';

  export default {
    name: "ModalNotifGroupsSaving",
    props: {
      isVisible: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        isLoading: false,
        activeNotification: null, // Index de la notification actuellement ouverte
        maxItem: 3, // Nombre maximum d'éléments à afficher initialement ou par chargement
        visibleNotificationsCount: 3, // Initialement égal à maxItem
        refreshComputed: false,
        notifications: [], // Liste des notifications
      };
    },

    watch: {
      isVisible(newValue) {
        if (newValue) {
          this.getNotifications(); // Recharger les notifications lorsque le modal devient visible
          this.refreshComputed = !this.refreshComputed; // Force le recalcul en changeant une dépendance utilisée dans computed
        }
      },
    },

    computed: {
        visibleNotifications() {
            this.refreshComputed; // Accès à refreshComputed pour déclencher un recalcul
            // Retourne seulement les notifications visibles
            return this.notifications.slice(0, this.visibleNotificationsCount);
        },
        hasMoreNotifications() {
            this.refreshComputed; // Accès à refreshComputed pour déclencher un recalcul
            // Vérifie s'il reste encore des notifications non visibles
            return this.visibleNotificationsCount < this.notifications.length;
        }
    },


    methods: {
      closeModal() {
        this.$emit("close");
      },

      formatShortDateText(date){
        return formatShortDateText(date, true);
      },

      showMoreNotifications() {
        // Augmente le nombre de notifications visibles
        this.visibleNotificationsCount += this.maxItem;
      },

      toggleContent(index) {
        this.activeNotification = this.activeNotification === index ? null : index; // Basculer entre afficher/cacher
      },

      async getNotifications() {
        this.isLoading = true; // Activer l'indicateur de chargement
        const url = `${SERVER_IP}/poolsavings/get-notifications`;
        try {
            const userId = checkAuth();
            const response = await axios.post(url, {
                userId: userId
            });

            if (response.status === 200 ) {
                this.notifications = response.data.notifications.map(notification => ({
                    ...notification, // Copie de toutes les propriétés existantes
                    isDeleting: false // Ajouter la nouvelle propriété 'isDeleting' avec la valeur initiale false
                }));
            }
  
          
        } catch (error) {
          console.error("Erreur lors du chargement des notifications:", error);
          this.notifications = [];
        } finally {
          this.isLoading = false; // Désactiver l'indicateur de chargement
        }
      },

      
      async deleteNotification(notification) {
        // Marquer la notification comme étant en train de se supprimer
        notification.isDeleting = true;
        const token = localStorage.getItem('token'); 

        try {
            // Envoi de la requête pour supprimer la notification
            const response = await axios.post(`${SERVER_IP}/notifications/delete`, { 
                notificationId: notification.id, 
                token 
            });

            // Vérification de la réponse
            if (response.status === 200) {
                // Retirer la notification de la liste des notifications
                const notificationIndex = this.notifications.findIndex(n => n.id === notification.id);
                if (notificationIndex !== -1) {
                    this.notifications.splice(notificationIndex, 1);
                }

                // Retirer la notification de la liste visible (si elle existe)
                const notificationIndexVisible = this.visibleNotifications.findIndex(n => n.id === notification.id);
                if (notificationIndexVisible !== -1) {
                    this.visibleNotifications.splice(notificationIndexVisible, 1);
                }

                // Mettre à jour le token si le serveur le renvoie
                const newToken = response.data.token;
                localStorage.setItem('token', newToken);
            }
            
        } catch (error) {
            // Gestion des erreurs
            console.error('Erreur lors de la mise à jour de la notification', error);
        } finally {
            // Réinitialiser l'état de la notification après la suppression ou l'échec de la suppression
            notification.isDeleting = false;
        }
    },


    },
  };
</script>
  
<style scoped>
  /* Overlay pour le modal */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Conteneur du modal */
  .modal-container {
    background-color: white;
    border-radius: 8px;
    width: 400px;
    max-height: 80%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  
  /* En-tête du modal */
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #ddd;
  }
  
  .modal-header h3 {
    margin: 0;
    color: #000;
  }
  
  .close-btn {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  /* Contenu du modal */
  .modal-content {
    padding: 20px;
    overflow-y: auto;
    flex-grow: 1;
    scrollbar-width: none; /* Masquer la barre de défilement sur Firefox */
    -ms-overflow-style: none; /* Masquer la barre de défilement sur IE/Edge */
    font-size: 0.8rem;
    color: #000;
  }
  
  .modal-content::-webkit-scrollbar {
    display: none; /* Masquer la barre de défilement sur Chrome, Edge, et Safari */
  }
  
  /* Conteneur de chargement */
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  /* Liste des notifications */
  /* Liste des notifications */
.notification-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.notification-item {
  margin-bottom: 10px;
}

/* Header */
.notification-header {
  background-color: #000;
  color: white;
  padding: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.notification-header h4 {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: normal;
}

.notification-header .notification-date {
  font-size: 0.7rem;
  color: #a9a9a9; /* Gris nuancé */
  font-style: italic;
  margin: 5px 0 0;
  text-align: left;
}

/* Contenu caché */
.notification-content {
  background-color: #fff;
  color: #000;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 5px 0;
  position: relative;
}

.notification-content .delete-icon {
  color: #dc3545; /* Rouge pour suppression */
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

/* Barre de séparation */
hr {
  border: 0;
  height: 1px;
  background-color: #ccc;
}

.show-more-btn {
  background-color: #016A40;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin-top: 1rem;
}

.show-more-btn:hover {
  background-color: #005934;
}

</style>
  