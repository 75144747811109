<template>
  <Header />
  <PageHeader title="Centre des Événements" :fontSize="'1.75rem'" :goBack="customGoBack" />

  <div class="dashboard-events">
    <div class="btn-large-container">
        <button class="btn-add-saving" @click="createEvents">
            Créer un événement <i class="fas fa-plus-circle"></i>
        </button>
    </div>

    <div class="search-container">
      <!-- Input de recherche -->
      <div class="search-input">
          <input 
              type="text" 
              v-model="searchQuery" 
              placeholder="Titre de l'événement" 
              @keyup.enter="searchOffice"
          />
          <!-- Icône de recherche à l'intérieur de l'input -->
          <button @click="fetchExchangeOffices" class="search-icon">
              <i class="fas fa-search"></i>
          </button>
      </div>

      <!-- Bouton pour les filtres avec icône uniquement -->
      <button class="filter-btn"  @click="openModalFilters">
          <i class="fas fa-sliders-h"></i>
      </button>
    </div>

    <div class="loading-fetch" v-if="loadingFetch">
        <i class="fas fa-spinner fa-spin"></i>
    </div>

    <div v-else>
      <div v-if="events.length">
        <div v-for="(event, index) in events" 
          :key="index" 
          class="event-preview"
        >
            <!-- Catégorie -->
            <div class="event-category">
              <i class="fas fa-cog icon-left"></i>
              <span class="category-text">{{ event.category }}</span>
              <i class="far fa-heart icon-right"></i>
            </div>


            <!-- Image -->
            <div class="event-image-container">
              <img 
                :src="mediaUrls[event.id] || 'placeholder.jpg'"  
                alt="Image de l'événement" 
                class="preview-image" 
              />
            </div>


            <!-- Informations principales -->
            <div class="event-details">
              <h2 class="event-title">{{ event.title }}</h2>
              <p class="event-organizer">par {{ event.organizer_id }}</p>
            </div>

            <!-- Sous-div des informations secondaires -->
            <div class="event-secondary-details">
              <!-- Sous-div gauche -->
              <div class="event-location-time">
                <p v-if="event.event_type === 'physical'">
                  <i class="fas fa-map-marker-alt"></i> {{ event.address }}
                </p>
                <p v-else>
                  <i class="fas fa-globe"></i> {{ event.url }}
                </p>
                <p><i class="fas fa-calendar-alt"></i> {{ formatShortDateText(event.start_date) }}</p>
                <p><i class="fas fa-clock"></i> {{ formatTimeText(event.start_date) }}</p>
              </div>

              <!-- Sous-div droite -->
              <div class="event-stats">
                <p><i class="fas fa-star"></i> 0/5</p>
                <p><i class="fas fa-users"></i> {{event.purchases}} participants</p>
              </div>
            </div>

            <button @click="eventSelectedID = event.id; showModalTicketing = true" class="btn-submit">
              <i class="fas fa-money-check-alt"></i> Acheter des tickets
            </button>
        </div>

        <!-- Bloc de pagination -->
        <div class="pagination-container">
            <div class="pagination" v-if="totalPages > 1">
                <button @click="prevPage" :disabled="currentPage === 1">
                    <i class="fa-solid fa-angles-left"></i>
                </button>
                <span> {{ currentPage }} / {{ totalPages }} </span>
                <button @click="nextPage" :disabled="currentPage === totalPages">
                    <i class="fa-solid fa-angles-right"></i>
                </button>
            </div>
        </div>


      </div>
      <div v-else class="no-results-message">
          Aucun événement ne correspond à votre recherche actuelle. <br><br>
          Veuillez vérifier vos filtres ou créer un nouvel événement.
      </div>
    </div>
    
    
    <ModalFiltersEvents 
      :isOpen="showModalFilters"
      @apply-filters="handleApplyFilters" 
      @close="closeModalFilters" 
    />

    <ModalTicketing 
      :isModalVisible="showModalTicketing"
      :eventID="eventSelectedID" 
      @close="closeModalTicketing" 
    />
    <ModalTickettingSuccess 
      :visible="isModalSuccessTicketsVisible" 
      @close="isModalSuccessTicketsVisible = false"
    />



    
  </div>

  <Footer :key="footerKey" />

</template>
  
<script>
    import Header from '../shared/HeaderPage.vue';
    import Footer from '../shared/FooterPage.vue';
    import PageHeader from '../shared/PageHeader.vue';
    import axios from 'axios';
    import { SERVER_IP } from '../../config';
    import { MEDIA_SERVER_URL } from '../../config';

    import { checkAuth, formatShortDateText, formatTimeText } from '@/utils/validationUtils';

    import ModalTicketing from './ModalTicketing.vue';
    import ModalTickettingSuccess from './ModalTickettingSuccess.vue';
    import ModalFiltersEvents from './ModalFiltersEvents.vue';



    export default {
        name: "DashboardEvents",
        components: {
            Header,
            PageHeader,
            Footer,

            ModalTicketing,
            ModalTickettingSuccess,
            ModalFiltersEvents,
        },

        data() {
          const today = new Date().toISOString().slice(0, 10); // Date du jour au format 'YYYY-MM-DD'
            return {
                searchQuery: '',
                loadingFetch: true,
                currentPage: 1,     // Page courante
                visibleEventsCount: 1,   // Nombre d'éléments par page
                totalPages: 1,
                events: [], // Liste complète des événements
                mediaUrls: {}, // Dictionnaire pour stocker les URLs des images
                isLoading: true, // Indicateur de chargement

                showModalFilters: false,
                selectedFilters: {
                  nextEvents: true,
                  myEvents: false,
                  category: '0',
                  tickets: false,
                  startDate: today, // Date de début initialisée à aujourd'hui
                  endDate: today,   // Date de fin initialisée à aujourd'hui
                  selectedOrder: 1, 
                },

                showModalTicketing: false,
                eventSelectedID: null,
                isModalSuccessTicketsVisible: false,
            };
        },

        mounted() {     
          this.initiateFilters();
          this.fetchEvents(); // Appel à l'API pour récupérer les économies une fois le composant monté
        },
        beforeUnmount() {
          if (localStorage.getItem('savedFilters')) {
              localStorage.removeItem('savedFilters');
          }
        },

        watch: {
          // Surveille les changements dans la liste des événements
          events: {
            handler() {
              this.loadImages(); // Recharge les URLs dès que les événements sont disponibles
            },
            deep: true, // Si `events` est un tableau complexe, ce flag est nécessaire
          },
        },

        methods: {
          formatShortDateText(date) {
              return formatShortDateText(date);
          },
          formatTimeText(date) {
            return formatTimeText(date);
          },

          createEvents() {
            if(!this.loadingFetch)
              this.$router.push('/create-events');
          },

          nextPage() {
              if (this.currentPage < this.totalPages) {
                  this.currentPage++;
                  this.fetchEvents();  // Charger les données de la nouvelle page
              }
          },
          prevPage() {
              if (this.currentPage > 1) {
                  this.currentPage--;
                  this.fetchEvents();  // Charger les données de la nouvelle page
              }
          },

          async getURLImage(url) {
            if (!url) {
              return 'placeholder.jpg'; // Si l'URL est vide
            }

            const fullUrl = `${MEDIA_SERVER_URL}events/${url}`;
            try {
              const response = await fetch(fullUrl, { method: 'HEAD' });
              if (response.ok) {
                return fullUrl; // Retourne le lien complet si le fichier existe
              }
              console.warn(`Fichier non trouvé: ${fullUrl}`);
            } catch (error) {
              console.error('Erreur lors de la vérification du fichier :', error);
            }

            // Retourne l'image par défaut si l'URL n'existe pas ou est inaccessible
            return 'placeholder.jpg';
          },

          async loadImages() {
            // Charge les URLs des images pour tous les événements
            const urls = {};
            for (const event of this.events) {
              urls[event.id] = await this.getURLImage(event.image_url);
            }
            this.mediaUrls = urls;
          },

          async fetchEvents() {
            this.loadingFetch = true;
            const url = `${SERVER_IP}/eventsManagement/getEvents`;
            this.events = [];

            try {
                const userId = checkAuth();
                const response = await axios.post(url, {
                    userId: userId,
                    searchQuery: this.searchQuery,
                    currentPage: this.currentPage, 
                    itemsPerPage: this.visibleEventsCount,

                  
                    nextEvents: this.selectedFilters.nextEvents,
                    myEvents: this.selectedFilters.myEvents,
                    category: this.selectedFilters.category,
                    tickets: this.selectedFilters.tickets,
                    startDate: this.selectedFilters.startDate, // Date de début initialisée à aujourd'hui
                    endDate: this.selectedFilters.endDate,   // Date de fin initialisée à aujourd'hui
                    selectedOrder: this.selectedFilters.selectedOrder,
                });

                if (response.status === 200 ) {
                    // On met à jour les données locales après réception de l'API
                    this.events = response.data.events;   // Tableau des économies
                    this.totalPages = response.data.totalPages;   // Total des pages
                }
                
            } catch (error) {
                console.log(error.response.data.message);
            } finally {
              this.loadingFetch = false;
            }
          },

          closeModalTicketing(boughtValue) {
            this.isModalSuccessTicketsVisible = false;
            let bought = boughtValue;
            this.showModalTicketing = false;
            if(bought) {
              this.isModalSuccessTicketsVisible = true;
            }

          },

          openModalFilters() {
            if(!this.loadingFetch)
              this.showModalFilters = true;
          },
          closeModalFilters() {
            this.showModalFilters = false;
          },
          initiateFilters() {
              localStorage.setItem('savedFilters', JSON.stringify(this.selectedFilters));
          },
          handleApplyFilters(filters) {
              this.selectedFilters = filters;
              this.currentPage = 1;
              this.fetchEvents();
          },

        }
       
        
    };

</script>
  
<style scoped>
  .dashboard-events {
    max-width: 400px;
    margin: auto;
    padding: 0px;
    text-align: center;
    color: #ffffff;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 5.5%;
    font-size: 0.8em;
  }

  .btn-large-container {
    text-align: center;
    margin-top: 20px;
  }
  .btn-add-saving {
    background-color: #016A40; /* Fond vert */
    color: white; /* Texte blanc */
    border: none;
    border-radius: 5px; /* Coins moins circulaires */
    padding: 10px 20px; /* Ajustement du rembourrage */
    cursor: pointer;
    transition: background-color 0.3s; /* Transition pour l'animation */
    width: 100%;
  }

  /* SEARCH CONTAINER */
  /* Style du conteneur */
  .search-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
  }

  /* Style de l'input de recherche */
  .search-input {
    position: relative;
    flex-grow: 1;
    margin-right: 15px; /* Espace entre l'input et le bouton filters */
    max-width: 75%;
  }

  .search-input input {
    width: 100%;
    padding: 10px 40px 10px 10px;
    background-color: white; /* Arrière-plan blanc */
    color: black; /* Texte noir */
    border: none;
    outline: none;
    border-radius: 5px;
    font-size: 12px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  }

  .search-input input::placeholder {
    color: lightgray; /* Placeholder gris clair */
  }

  /* Icône de recherche à l'intérieur de l'input */
  .search-input .search-icon {
    position: absolute;
    right: -15%;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: black; /* Couleur de l'icône de recherche */
    cursor: pointer;
    font-size: 18px;
  }

  /* Bouton de filtre */
  .filter-btn {
    padding: 10px;
    background-color: white; /* Arrière-plan blanc */
    color: black; /* Texte noir */
    border: none;
    border-radius: 5px;
    font-size: 16px; /* Taille de l'icône */
    cursor: pointer;

  }

  .filter-btn i {
    margin: 0;
  }

  .loading-fetch {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
      font-size: 2.5rem;
      margin-top: 40%;
  }
  
</style>

<style scoped>
  .event-preview {
  background-color: #101010;
  color: white;
  border-radius: 15px;
  padding: 20px;
  max-width: 600px;
  margin: 20px auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.event-category {
  display: flex;
  align-items: center; /* Aligner les éléments verticalement */
  justify-content: space-between; /* Séparer les éléments aux extrémités */
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 10px;
}

.event-category .icon-left,
.event-category .icon-right {
  font-size: 1.5rem; /* Taille des icônes */
  cursor: pointer; /* Ajout d'un curseur pour indiquer que l'icône est cliquable */
}

.event-category .icon-left {
  margin-right: auto; /* Aligner l'icône à gauche */
}

.event-category .icon-right {
  margin-left: auto; /* Aligner l'icône à droite */
}

.event-category .category-text {
  flex-grow: 1; /* Occupe l'espace restant entre les icônes */
  text-align: center; /* Centre le texte */
}


.event-image-container {
  display: flex;
  justify-content: center;
  margin: 15px 0;
}

.preview-image {
  max-width: 350; /* Limite maximale de largeur */
  max-height: 200px; /* Limite maximale de hauteur */
  width: auto; /* Ajuste automatiquement la largeur pour conserver le ratio */
  height: auto; /* Ajuste automatiquement la hauteur pour conserver le ratio */
  border-radius: 15px; /* Coins arrondis */
  object-fit: contain; /* Maintient tout l'image visible dans les limites */
  display: block; /* Aligne correctement l'image */
  margin: 0 auto; /* Centre l'image horizontalement */
}


.event-details {
  margin-top: 15px;
  text-align: left;
}

.event-title {
  font-size: 1.5rem;
  font-weight: bold;
  /* margin: 5px 0; */
  margin-bottom: 0;
}

.event-organizer {
  font-style: italic;
  color: #aaa;
}

.event-secondary-details {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 15px;
  background-color: #1a1a1a;
  border-radius: 10px;
}

.event-location-time,
.event-stats {
  flex: 1;
  margin: 0 10px;
  text-align: left;
}



.event-location-time p,
.event-stats p {
  margin: 5px 0;
}

.event-location-time p i,
.event-stats p i {
  margin-right: 5px;
  color: #00b894;
}


.btn-submit {
  background-color: #016A40; /* Fond vert */
  color: white; /* Texte blanc */
  border: none;
  border-radius: 5px; /* Coins moins circulaires */
  padding: 10px 20px; /* Ajustement du rembourrage */
  cursor: pointer;
  transition: background-color 0.3s; /* Transition pour l'animation */
  width: auto;
  margin-top: 5%;
}

.btn-submit:hover {
  background-color: #005934; /* Changement de couleur au survol */
}

.btn-submit:active {
  background-color: #003822; /* Changement de couleur lorsqu'il est cliqué */
}

/****************** NO RESULT FETCH ************************/
.no-results-message {
    display: flex;
    text-align: center;
    color: white; /* Texte en blanc */
    border-radius: 10px; /* Coins arrondis */
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5); /* Ombre portée */
    padding: 20px;
    transition: all 0.3s ease;
    margin-top: 25%;
}

/********************* PAGINATION **********************/
.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 5%;
}

.pagination {
    display: flex;
    align-items: center;
}

.pagination button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    margin: 0 5px;
    font-size: 12px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.pagination button:hover:not(:disabled) {
    background-color: #0056b3;
}

.pagination span {
    margin: 0 10px;
    font-size: 12px;
    font-weight: bold;
}
</style>
  