<template>
    <div v-if="isVisible" class="modal-overlay" @click.self="closeModal">
        <div class="modal-content">
        <h2 class="modal-title">Paiement de {{ position }}ᵉ main</h2>
        
        <div class="modal-body">
            <p><span>Effectué le :</span> {{ formatDate(datePayment) }}</p>
            <p><span>Montant :</span> {{ amount }}</p>
        </div>

        <button class="confirm-button" @click="closeModal">
            <i class="fas fa-close"></i> Fermer
        </button>
        </div>
    </div>
</template>
  
<script>
import { formatDate } from '@/utils/validationUtils';

  export default {
    name: 'ModalInfoPayment',
    props: {
      isVisible: {
        type: Boolean,
        required: true
      },
      datePayment: {
        type: String,
        required: true
      },
      amount: {
        type: Number,
        required: true
      },
      position: {
        type: Number,
        required: true
      }
    },
    methods: {
      closeModal() {
        this.$emit('close');
      },

      formatDate(date) {
        return formatDate(date);
      },
    }
  };
</script>
  
<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7); /* Assombrissement plus prononcé */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  transition: background 0.3s ease;
}

.modal-content {
  background-color: #fff;
  border-radius: 12px;
  width: 320px;
  max-width: 90%;
  padding: 25px;
  text-align: center;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  transform: translateY(-10px);
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 1;
}

.modal-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #000;
  margin-bottom: 15px;
}

.modal-body p {
  margin: 12px 0;
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
  color: #333;
  font-weight: 500;
}

.modal-body span {
  font-weight: 600;
  color: #016A40;
}

.confirm-button {
  background-color: #D9534F; /* Rouge doux */
  color: #fff;
  font-size: 0.8rem;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.confirm-button:hover {
  background-color: #C9302C; /* Rouge plus foncé pour l'effet de survol */
}


.confirm-button i {
  margin-right: 8px;
  font-size: 1.1rem;
}

</style>
  