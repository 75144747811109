<template>
    <Header />
    <PageHeader title="Placer une épargne à terme" :goBack="customGoBack" />

    <div class="add-longterm-savings-page">

        <!-- Mockup image -->
        <img src="@/assets/images/ewallet_33.png" alt="long-term-savings" class="mockup-image">

        <div class="zone-formulaire" v-if="activeIndex === 0">

            <form @submit.prevent="handleSubmit" novalidate>
                <!-- Duree de l'epargne -->
                <div class="form-group">
                    <label for="times-select"><i class="fas fa-calendar-alt  icon-bg"></i> Durée de l'épargne</label>
                    <select id="times-select" v-model="selectedTimes" @change="handleTimeInput">
                        <option v-for="saving in savings" :key="saving.id" :value="saving.id">
                            {{ saving.time }}
                        </option>
                    </select>
                </div>

                <div class="form-row">
                  <div class="form-group form-currency">
                    <label for="currency">
                      <i class="fas fa-coins icon-bg"></i> Devise
                    </label>
                    <select id="currency" v-model="currency" @change="handleTimeInput" required>
                      <option value="HTG">HTG</option>
                      <option value="USD">USD</option>
                    </select>
                  </div>

                  <div class="form-group form-amount">
                    <label for="amount">
                      <i class="fas fa-money-bill-wave icon-bg"></i> Montant
                    </label>
                    <input type="number" id="amount" v-model="amount" @input="handleAmountInput" @blur="checkRate" @keyup.enter="handleSubmit" placeholder="0.00" required />
                  </div>

                  <div class="form-group form-rate">
                    <label for="rate">
                      <i class="fas fa-percentage icon-bg"></i> Taux
                    </label>
                    <div class="input-like"> 
                      <span v-if="loadingRate"><i class="fas fa-spinner fa-spin"></i></span>
                      <span v-else>{{ rate }}</span>
                    </div>
                  </div>

                </div>
                <span v-if="amountError" class="error-message">{{ amountError }}</span>

                <div class="submit-container">
                  <button type="submit" class="btn-submit" :disabled="loading">
                    <span v-if="loading">
                      Traitement en cours... <i class="fas fa-spinner fa-spin"></i>
                    </span>
                    <span v-else>
                      Placer mon épargne <i class="fas fa-hand-holding-usd"></i>
                    </span>
                  </button>
                </div>

                
                
            </form>

        </div>

        <div class="block-a2f" v-if="activeIndex === 1">
            <p v-html="$t('pageBasicDeposit.noRecordPin')"></p>
            <button @click="goTo2AF" class="btn-submit">
            {{ $t('pageBasicDeposit.configure') }} <i class="fas fa-user-lock"></i>
            </button>
        </div>

        <div class="zone-invoice" v-if="activeIndex === 2">
          <div class="row-invoice">
            <span class="label">Somme placée: </span> <span class="libelle">{{ amount }} {{ currency }}</span>
          </div>
          <div class="row-invoice">
            <span class="label">Durée chosie: </span> <span class="libelle">{{ stringTimes() }}</span>
          </div>
          <div class="row-invoice">
            <span class="label">Taux d'intérêt applicable: </span> <span class="libelle">{{ rate }}</span>
          </div>
          <hr style="border: 1px solid #3b3b3b;">
          <div class="row-invoice">
            <span class="label">Montant à la fin de l'épargne: </span> <span class="libelle">{{ calculAmount() }} {{ currency }}</span>
          </div>

          <h4 class="title-pin">Entrer votre PIN</h4>

          <div class="pin-container">
              <div class="pin-inputs">
                  <!-- Les inputs avec ref dynamique -->
                  <input v-for="(digit, index) in pin" 
                      :key="index" 
                      v-model="pin[index]" 
                      maxlength="1"
                      :ref="'pin' + index" 
                      @input="onInput(index)" 
                      @keydown.backspace="onBackspace(index)" 
                      :type="isRevealed ? 'text' : 'password'" 
                      class="pin-box" 
                      inputmode="numeric" 
                      pattern="[0-9]*"
                      @keypress="isNumber($event)" />
                  
                  <!-- Icône pour révéler le code PIN -->
                  <i class="fas fa-eye eye-icon" @mousedown="revealPin" @mouseup="hidePin" @mouseleave="hidePin"></i>
              </div>
          </div>


          <!-- Bouton de validation avec une icône FontAwesome -->
          <button type="submit" class="btn-submit" :disabled="loadingPin" @click="concluate">
          <span v-if="loadingPin">
              Traitement en cours... <i class="fas fa-spinner fa-spin"></i>
          </span>
          <span v-else>
              Valider <i class="fas fa-check-circle"></i>
          </span>
          </button>


          <div>
              <span class="error-message validate-error" v-if="pinError">{{ pinError }}</span>
          </div>

        </div>

        <div class="block-a2f" v-if="activeIndex === 3">
            <p>Votre épargne à terme a été placée avec succès!<br/> Vous recevrez une notification à l'échéance pour récupérer votre montant et vos intérêts.</p>
            <button @click="goToHome" class="btn-submit">
                Accueil <i class="fas fa-home"></i>
            </button>
        </div>

        <ModalConditions
          :isOpen="isOpenModal"
          @close="closeModalConditions"
          @average-confirm="handleConfirmConditions"
        />

    </div>

    <Footer :key="footerKey" />
    
</template>


<script>
import Header from '../shared/HeaderPage.vue';
import Footer from '../shared/FooterPage.vue';
import PageHeader from '../shared/PageHeader.vue';
import axios from 'axios';
import { SERVER_IP } from '../../config';

import { checkAuth } from '@/utils/validationUtils';

import { refreshFooterMixin } from '@/mixins/refreshFooterMixin';
import ModalConditions from './ModalConditions.vue';

export default {
  name: 'AddLongTerm',
  mixins: [refreshFooterMixin],

  components: {
      Header,
      PageHeader,
      Footer,

      ModalConditions
  },
  created(){
    checkAuth();
  },

  data() {
    return {
        savings : [
            { id: 1, time: '1 mois' },
            { id: 2, time: '3 mois' },
            { id: 3, time: '6 mois' },
            { id: 4, time: '12 mois' }
        ],
        selectedTimes: 2,
        currency: 'HTG',
        activeIndex: 0,
        rate: '0%',
        amount: '',
        amountError: '',
        loading: false,
        loadingRate: false,

        pin: ["", "", "", ""],
        loadingPin: false,
        pinError: '',
        isRevealed: false,

        isOpenModal: false,
    };
  },

  methods: {

    handleAmountInput() {
      this.amountError = "";
    },
    handleTimeInput() {
      this.amountError = "";
      if(this.amount) {
        this.checkRate();
      }
    },
    goTo2AF(){
      this.$router.push('/create-pin');
    },
    goToHome() {
      this.$router.push('/home');
    },
    customGoBack() {
        (this.activeIndex === 0 || this.activeIndex === 3)  ? this.$router.go(-1) : this.activeIndex = 0;
    },

    closeModalConditions() {
      this.isOpenModal = false;
    },
    handleConfirmConditions() {
      this.isOpenModal = false;
      this.activeIndex = 2;
    },

    stringTimes() {
      return this.savings.find(saving => saving.id === this.selectedTimes).time;
    },
    calculAmount() {
        let somme = this.amount;
        let rate = parseFloat(this.rate.replace('%', ''));
        return somme*rate/100 +somme;
    },

    async checkRate() {
      this.loadingRate = true;
      this.rate = 0;

      try {
        const url = `${SERVER_IP}/savings/checkRates`;
        const userId = checkAuth();
        const response = await axios.post(url, {
          amount: this.amount,
          currency: this.currency,
          times: this.selectedTimes,
          userId: userId
        });

        if (response.status === 200 && response.data.rate) {
          this.rate = response.data.rate +' %';
          this.amountError = '';
        }
        
      } catch (error) {
        if (error.response && error.response.status === 400) {
          const { message } = error.response.data;
          if (message === 'no_minimum') {
            this.amountError = `Le montant minimal pour cette épargne est de ${error.response.data.amount} ${this.currency}`;
          }
          if (message === 'no_funds') {
            this.amountError = `Vous n'avez pas ce montant sur votre compte principal.`;
          }
          if (message === 'rate_not_found') {
            this.amountError = `Une erreur inconnue est survenue. Veuillez réesayer`;
          }
          if (message === 'no_pin') {
            this.activeIndex = 1;
          }
        }
        
      } finally {
        this.loadingRate = false;
      }

    },

    async handleSubmit() {          
      this.loading = true; // Début du chargement

      await this.checkRate();
      if (this.amountError){
        this.loading = false;
        return
      }
      this.loading = false;
      this.isOpenModal = true;
    },

    async concluate() {
      this.loadingPin = true;
      if(!this.validatePin()){
          this.pinError = 'Tanpri ranpli tout chan yo ak chif.';
          this.loadingPin = false;
          return;
      }
      const url = `${SERVER_IP}/savings/addSaving`;
      try {
        const userId = checkAuth();
        const response = await axios.post(url, {
          amount: this.amount,
          currency: this.currency,
          times: this.selectedTimes,
          userId: userId,
          pin: this.pin.join(''),
        });

        if (response.status === 200 ) {
          this.activeIndex = 3;
        }
        
      } catch (error) {
        if (error.response && error.response.status === 400) {
          const { message } = error.response.data;
          if (message === 'invalid_pin') {
            this.pinError = 'Votre PIN est incorrecte';
          }
          else{
            this.pinError = `Une erreur inconnue est survenue. Veuillez réesayer`;
          }

        }
      } finally {
        this.loadingPin = false;
      }
    },

    /**********GESTION PIN************/
    // Empêche l'utilisateur d'entrer des lettres
    isNumber(event) {
        const charCode = event.keyCode ? event.keyCode : event.which;
        if (charCode < 48 || charCode > 57) { // 48-57 correspond aux chiffres 0-9
            event.preventDefault();
        }
    },

    // Lorsque l'utilisateur entre un chiffre dans un input
    onInput(index) {
        if (this.pin[index] && index < this.pin.length - 1) {
            // Si le champ est rempli, passer automatiquement au champ suivant
            this.$nextTick(() => {
                const nextInput = this.$refs['pin' + (index + 1)][0];
                if (nextInput) nextInput.focus();
            });
        }
        this.pinError = '';
    },

    // Gestion de la suppression d'un chiffre
    onBackspace(index) {
        if (!this.pin[index] && index > 0) {
            // Si le champ est vide après suppression, revenir au champ précédent
            this.$nextTick(() => {
                const prevInput = this.$refs['pin' + (index - 1)][0];
                if (prevInput) prevInput.focus();
            });
        } 
        this.pinError = '';
    },

    // Révéler le PIN pendant 1 seconde
    revealPin() {
        this.isRevealed = true;
        setTimeout(() => {
            this.isRevealed = false;
        }, 3000); // Masque le code après 3 secondes
    },

    hidePin() {
        this.isRevealed = false;
    },

    // Validation du PIN
    validatePin() {
        if (this.pin.join('').length === 4) {
            return true;
        } else {
            return false;
        }
    },
  }

  


};

</script>


<style scoped>

.block-a2f{
max-width: 400px;
margin: auto;
padding: 0px;
text-align: center;
color: #ffffff;
padding-left: 10px;
padding-right: 10px;
}
.block-a2f p{
  font-size: 1rem;
}
.block-a2f p a{
    color: #03a5fc;
    text-decoration: none;
}
.block-a2f p a:hover{
    color: #08489c;
}

.add-longterm-savings-page {
max-width: 400px;
margin: auto;
padding: 0px;
text-align: center;
color: #ffffff;
padding-left: 10px;
padding-right: 10px;
margin-bottom: 5.5%;
}

.mockup-image {
max-width: 120px;
margin: 20px auto;
display: block;
}

form {
  display: flex;
  flex-direction: column;
  max-width: auto;
  margin: auto;
  background-color: #000103;
  color: white;
  /* padding: 5px; */
  border-radius: 8px;
  margin-top:-10px;
}

.form-group {
  margin-bottom: 1em;
  display: auto;
  flex-direction: column;
  width: calc(100% - 0px);
}
.form-row {
  display: flex;
  justify-content: space-between; /* Répartition égale de l'espace entre les enfants */
  gap: 20px;
  width: auto;
  margin-top: 20px;
}

.form-row .form-currency {
  width: 33%; /* Largeur égale pour chaque form-group */
}
.form-row .form-amount {
  width: 33%; /* Largeur égale pour chaque form-group */
}
.form-row .form-rate {
  width: 33%; /* Largeur égale pour chaque form-group */
}

.form-group label {
  display: block; /* Afficher les labels en bloc */
}

.form-group label {
  margin-bottom: 0.5em;
  color: white;
  text-align: left;
  display: flex;
  align-items: center;
}

.icon-bg {
  background-color: #016A40;
  color: white;
  padding: 5px; /* Taille des icônes réduite */
  border-radius: 8px; /* Coins arrondis */
  margin-right: 10px;
  font-size: 0.6em; /* Réduire la taille des icônes */
  width: 15px;
  height: 15px;
  text-align: center;
  font-size: 0.9em;
}

.form-group input,
.form-group select, .form-group .input-like {
  padding: 0.5em 0;
  font-size: 1em;
  width: calc(100% - 0px);
  background-color: transparent;
  border: none;
  border-bottom: 1px solid white;
  color: white;
}

.form-group input:focus,
.form-group select:focus {
  outline: none;
  border-bottom: 2px solid #016A40; /* Couleur de focus verte */
}
option{
    color: #000;
}

.form-group .input-like{
  text-align: left;
  color: #6a6969;
}

.error-message {
  font-size: 0.8em; /* Ajustez la taille de la police selon vos besoins */
  font-weight: normal; /* Ajustez le poids de la police si nécessaire */
  transition: font-size 0.3s; /* Transition pour une animation douce */
  color: #FFFC00; /* Couleur du texte rouge */
  font-style: italic; /* Texte en italique */
  text-align: left;
}

.submit-container {
  text-align: center;
  margin-top: 20px;
}

.btn-submit {
  background-color: #016A40; /* Fond vert */
  color: white; /* Texte blanc */
  border: none;
  border-radius: 5px; /* Coins moins circulaires */
  padding: 10px 20px; /* Ajustement du rembourrage */
  cursor: pointer;
  transition: background-color 0.3s; /* Transition pour l'animation */
  width: 50%;
}

.btn-submit:hover {
  background-color: #005934; /* Changement de couleur au survol */
}

.btn-submit:active {
  background-color: #003822; /* Changement de couleur lorsqu'il est cliqué */
}

/**************** INVOICE *****************/
.row-invoice {
  display: flex;
  justify-content: space-between;
  margin: 5% 0%;
}
.row-invoice .label {
  font-weight: bold;
}
.row-invoice .libelle {
  font-weight: italic;
}
.row-invoice hr {
  border: 1px solid #3b3b3b;
}


.title-pin{
font-weight: normal;
font-size: 1.5rem;
}

.pin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
}

.pin-label {
  margin-bottom: 20px;
  font-size: 1.2rem;
  text-align: center;
}

.pin-inputs {
  display: flex;
  gap: 10px;
  position: relative;
}

.pin-box {
  width: 50px;
  height: 50px;
  border: 2px solid #000103;
  border-radius: 5px;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}

.eye-icon {
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
</style>