<template>
    <div class="modal-overlay" v-if="isVisible">
      <div class="modal-content">
        <h4>Êtes-vous prêt à effectuer un paiement pour le {{ formatShortDate(date) }} ?</h4>
        
        <div class="modal-buttons">
          <button @click="confirmPayment" class="confirm-button">
            <i class="fas fa-check"></i> Confirmer
          </button>
          <button @click="cancelPayment" class="cancel-button">
            <i class="fas fa-times"></i> Annuler
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ConfirmPaymentModal',
    props: {
      date: {
        type: Date,
        required: true
      },
      isVisible: {
        type: Boolean,
        default: false
      }
    },


    methods: {
      formatShortDate(date) {
        return new Intl.DateTimeFormat('fr-FR', {
          day: '2-digit', month: 'short', year: 'numeric'
        }).format(new Date(date));
      },
      confirmPayment() {
        this.$emit('confirm', true);
        this.closeModal();
      },
      cancelPayment() {
        this.$emit('confirm', false);
        this.closeModal();
      },
      closeModal() {
        this.$emit('update:isVisible', false);
      }
    }
  };
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999; /* For stacking above other modals */
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 350px;
    text-align: center;
  }
  
  h4 {
    margin-bottom: 20px;
    color: #333;
    font-weight: normal;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .confirm-button, .cancel-button {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    color: #fff;
    font-weight: normal;
  }
  
  .confirm-button {
    background-color: #016A40; /* Confirmation button background */
  }
  
  .cancel-button {
    background-color: #d9534f;
  }
  
  .confirm-button i, .cancel-button i {
    margin-right: 8px;
  }
  </style>
  