<template>
    <Header />
    <PageHeader title="Invitation de Groupe SOL" :goBack="customGoBack" />

    <div class="invitation-savings-group-page">


        <div class="loading-info" v-if="activeIndex === 0">
            <i class="fas fa-spinner fa-spin"></i>
        </div>
        
        <div class="group-info" v-if="activeIndex === 1">
            <div class="info-row title">
                <span>Nom du Groupe:</span>
                <span>{{ info.nameGroup }}</span>
            </div>
            <div class="info-row">
                <span>Organisateur :</span>
                <span><strong>{{ info.nameLeader }}</strong><br/><em>({{ info.phoneLeader }} / {{ info.emailLeader }})</em></span>
            </div>
            <div class="info-row">
                <span>Date de Démarrage :</span>
                <span>{{ formatStartDate }}</span>
            </div>
            <div class="info-row sub-section">
                <div>
                    <span>Montant</span><br/>
                    <span>{{ info.amount }}</span>
                </div>
                <div>
                    <span>Fréquence</span><br/>
                    <span>{{ stringFrequency(info.frequence) }}</span>
                </div>
            </div>
            <div class="info-row sub-section">
                <div>
                    <span>Position : </span>
                    <span>{{ info.rowPos }}e main</span>
                </div>
                <div>
                    <span>Membres : </span>
                    <span>{{ info.countMember }}</span>
                </div>
            </div>

            <div class="button-container">
                <button class="btn cancel-btn" @click="answer(false)" :disabled="loadingCancel">
                    <span v-if="loadingCancel">
                      Traitement en cours... <i class="fas fa-spinner fa-spin"></i>
                    </span>
                    <span v-else>
                        <i class="fas fa-ban"></i> Annuler
                    </span>
                </button>
                
                <button class="btn confirm-btn" @click="this.isOpenModal = true">
                    <i class="fas fa-check"></i> Confirmer
                </button>
            </div>
        </div>

        <div class="block-a2f" v-if="activeIndex === 2">
            <p>
                <br/><br/>Merci !<br/><br/>
                Vous avez confirmé votre participation au groupe SOL {{ info?.nameGroup ?? 'Indéfini' }}.<br/><br/> 
                Vous recevrez une notification pour chaque étape importante du cycle.<br/><br/>
            </p>
            <button @click="goToDashboard" class="btn confirm-btn">
                Voir mon groupe SOL <i class="fas fa-users"></i>
            </button>
        </div>

        <div class="block-a2f" v-if="activeIndex === 3">
            <p>
                Votre participation au groupe SOL {{ info?.nameGroup ?? 'Indéfini'  }} a été annulée.<br/><br/> 
                Si vous changez d'avis, veuillez contacter l'organisateur pour recevoir une nouvelle invitation.<br/><br/>
            </p>
            <button @click="goToHome" class="btn confirm-btn">
                Accueil <i class="fas fa-home"></i>
            </button>
        </div>

        <div class="block-a2f" v-if="activeIndex === 4">
            <p>
                La page que vous tentez de consulter n’est pas accessible pour le moment.<br/><br/> 
                Vous pouvez essayer à nouveau plus tard ou revenir à la page d'accueil.<br/><br/>
                Si le problème persiste,<br/>
                veuillez contacter notre service d’assistance pour obtenir de l'aide.<br/><br/>
            </p>
            <button @click="goToHome" class="btn confirm-btn">
                Accueil <i class="fas fa-home"></i>
            </button>
        </div>

        <div>
            <span class="error-message" v-if="errorAnswer">{{ errorAnswer }}</span>
        </div>



        <ModalTerms
          :isOpen="isOpenModal"
          @close="closeModalTerms"
          @average-confirm="handleConfirmTerms"
        />


    </div>

    <Footer :key="footerKey" />
</template>

<script>
import Header from '../shared/HeaderPage.vue';
import Footer from '../shared/FooterPage.vue';
import PageHeader from '../shared/PageHeader.vue';
import axios from 'axios';
import { SERVER_IP } from '../../config';

import { formatDate, checkAuth } from '@/utils/validationUtils';

import { refreshFooterMixin } from '@/mixins/refreshFooterMixin';
import ModalTerms from './ModalTerms.vue';

export default {
  name: 'InvitationSavingsGroup',
  mixins: [refreshFooterMixin],

  components: {
      Header,
      PageHeader,
      Footer,
      ModalTerms,
  },

  created() {
    checkAuth();
  },
  mounted() {
    this.loadInfo();
  },

  computed: {
    formatStartDate() {
        return formatDate(this.info.startDate);
    },
  },

  data() {
    return {
        info: {},
        errorAnswer: '',
        loadingCancel: false,
        activeIndex: 0,

        isOpenModal: false,
    };
  },

  methods : {

    stringFrequency(frequence) {
        const frequencyMap = {
            'daily': 'Chaque jour',
            'weekly': 'Chaque semaine',
            'bi-weekly': 'Chaque 2 semaines',
            'monthly': 'Chaque 4 semaines'
        };

        return frequencyMap[frequence] || '';
    },

    closeModalTerms() {
      this.isOpenModal = false;
    },
    handleConfirmTerms() {
      this.errorAnswer = '';
      this.answer(true);
    },
    goToHome() {
      this.$router.push('/home');
    },
    goToDashboard() {
      this.$router.push('/dashboard-group-sol');
    },

    

    async loadInfo() {
        const url = `${SERVER_IP}/poolsavings/invitation-info`;
        try {
            const userId = checkAuth();
            const response = await axios.post(url, {
                rowID: this.$route.params.ref,
                userId: userId
            });

            if (response.status === 200 ) {
                this.info = response.data.info;
                this.activeIndex = 1;
            }
            
        } catch (error) {
            if (error.response && error.response.status === 400) {
                this.activeIndex = 4;
            }
        } 
    },

    async answer(status){
        this.errorAnswer = '';
        const url = `${SERVER_IP}/poolsavings/invitation-answer`;
        this.loadingConfirm = status ? true : false;
        this.loadingCancel = !status;

        try {
            const userId = checkAuth();
            const response = await axios.post(url, {
                rowID: this.$route.params.ref,
                userId: userId,
                answer: status
            });

            if (response.status === 200 ) {
                this.info = response.data.info;
                this.isOpenModal = false;

                this.activeIndex = status === true ? 2 : 3;
            }
            
        } catch (error) {
            this.errorAnswer = 'Une erreur inconnue est survenue, veuillez réessayez';
        } finally {
            this.loadingConfirm = false;
            this.loadingCancel = false;
            this.isOpenModal = false;
        }
    }

  },

}

</script>

<style scoped>
.block-a2f{
max-width: 400px;
margin: auto;
padding: 0px;
text-align: center;
color: #ffffff;
padding-left: 10px;
padding-right: 10px;
}
.block-a2f p{
  font-size: 1rem;
}
.block-a2f p a{
    color: #03a5fc;
    text-decoration: none;
}
.block-a2f p a:hover{
    color: #08489c;
}

.invitation-savings-group-page {
max-width: 400px;
margin: auto;
padding: 0px;
text-align: center;
color: #ffffff;
padding-left: 10px;
padding-right: 10px;
margin-bottom: 5.5%;
font-size: 0.8rem;
}

.loading-info {
    color: #FFF;
    font-size: 2rem;
    margin-top: 30%;
}

.group-info {
    background-color: #ffffff;
    border: 1px solid #ddd;
    padding: 20px;
    max-width: 400px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    color: #333;
    margin-top: 20px;
}

.info-row {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    font-size: 0.8rem;
    border-bottom: 1px solid #f0f0f0;
    margin-top: 5px;
    padding-bottom: 10px;
}

.info-row.title span:last-child {
    font-weight: bold;
    font-size: 0.9rem;
}

.info-row:last-child {
    border-bottom: none;
}

.sub-section {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    margin-top: 12px;
    background-color: #f8f8f8;
    padding: 8px;
    border-radius: 4px;
}

.sub-section div {
    width: 48%; /* Occupe environ la moitié de la largeur */
}

.sub-section div:first-child {
    margin-right: auto; /* Force le premier élément à se positionner à gauche */
}

.sub-section span:first-child {
    font-weight: bold;
}


.info-row span:first-child {
    color: #555;
    font-weight: bold;
    text-align: left;
}

.info-row span:last-child {
    text-align: right;
}

.info-row em {
    font-style: italic;
    font-size: 0.8rem;
    color: #666;
}

.button-container {
    display: flex;
    justify-content: space-between;
    width: 100%; /* Adapte la largeur du conteneur */
    gap: 12px;
    margin-top: 20px;
}

.btn {
    background-color: #016A40; /* Fond vert */
    color: white; /* Texte blanc */
    border: none;
    border-radius: 5px; /* Coins moins circulaires */
    padding: 10px 20px; /* Ajustement du rembourrage */
    cursor: pointer;
    transition: background-color 0.3s; /* Transition pour l'animation */
    width: auto;
    gap: 6px;
}

.confirm-btn {
    background-color: #016A40; /* Vert pour confirmer */
}

.confirm-btn:hover {
    background-color: #005934;
}
.confirm-btn:active {
    background-color: #003822;
}

.cancel-btn {
    background-color: #dc3545; /* Rouge pour annuler */
}

.cancel-btn:hover {
    background-color: #bb2d3b; /* Rouge plus foncé pour le survol */
}

.cancel-btn:active {
    background-color: #8a1e29; /* Rouge encore plus foncé pour l'état actif */
}


.error-message {
  font-size: 0.8rem; /* Ajustez la taille de la police selon vos besoins */
  font-weight: normal; /* Ajustez le poids de la police si nécessaire */
  transition: font-size 0.3s; /* Transition pour une animation douce */
  color: #FFFC00; /* Couleur du texte rouge */
  font-style: italic; /* Texte en italique */
  text-align: left;
}


</style>