<template>
    <div class="modal-overlay" @click.self="closeModal">
      <div class="modal-content">
        <h3>Termes et Conditions pour le Service SOL</h3>

        <div class="content-text">
            <p>Merci d’avoir choisi notre service de SOL. En utilisant ce service, vous acceptez les termes et conditions suivants :</p>

            <h4>1. Vérification de Confiance</h4>
            Avant de rejoindre un groupe SOL, assurez-vous que l'organisateur est une personne de confiance que vous connaissez personnellement. Vous êtes entièrement responsable de vérifier la fiabilité de l'organisateur et des participants.

            <h4>2. Engagement de Contribution</h4>
            En acceptant de rejoindre un groupe SOL, vous vous engagez à respecter le calendrier de contributions établi. Les contributions régulières de chaque membre sont essentielles pour assurer le bon fonctionnement du groupe.

            <h4>3. Absence de Responsabilité de la Plateforme</h4>
            La plateforme n’intervient pas dans la gestion ou l’administration des groupes SOL et décline toute responsabilité en cas de litiges, défauts de paiements, ou comportements inattendus des participants.

            <h4>4. Aucune Garantie de Remboursement</h4>
            La plateforme n’assure aucun remboursement des contributions versées dans le cadre d'un groupe SOL, quelle qu’en soit la raison. Les utilisateurs doivent donc être prudents et bien informés avant de s’engager.

            <h4>5. Conseils de Sécurité</h4>
            Si à tout moment vous avez des doutes sur l’intégrité d'un groupe ou d'un participant, il est préférable de ne pas rejoindre ou de quitter le groupe immédiatement, et de signaler toute activité suspecte aux autorités compétentes si nécessaire.
            <br/><br/>
        </div>
  
        <!-- Boutons de validation -->
        <div class="modal-buttons">
            <button @click="closeModal"><i class="fas fa-times"></i> Fermer</button>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name: 'ModalTerms',
    props: {
      isOpen: {
        type: Boolean,
        required: true
      }
    },

    data() {
        
    },

    methods: {
        closeModal() {
            this.$emit('close');
        },
        
    },

}

</script>


<style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 350px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    color: #000;
  }

  .modal-content::-webkit-scrollbar {
    width: 0px; /* Cache la barre de défilement dans Chrome, Safari */
    background: transparent; /* Optionnel : pour enlever un arrière-plan */
  }
  .content-text{
    text-align: left;
    text-align: justify;
    font-size: 0.8rem;
    max-height: 65vh; /* Limite la hauteur de la modale pour éviter qu'elle dépasse l'écran */
    overflow-y: auto; /* Ajoute le défilement si le contenu dépasse */
    scrollbar-width: none; /* Cache la barre de défilement dans Firefox */
  }
  
  
  .modal-buttons {
    display: flex;
    justify-content: flex-end;
  }
  
  .modal-buttons button {
    margin-left: 10px;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    background-color: #dc3545;
    color: white;
    cursor: pointer;
    margin-top: 10px;
  }
  
  

</style>