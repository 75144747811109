<template>
    <div class="modal-overlay" v-if="isOpen" @click.self="closeModal">
      <div class="modal-content">
        <h3>Filtrer les événements</h3>

        <!-- Section Date Événements -->
        <!-- Section Date Événements -->
        <div class="filter-section">
            <h4><i class="fas fa-calendar-alt"></i> Date</h4>

            <!-- Groupe de champs de date et checkbox -->
            <div class="date-filter-container">
                <!-- Champs de date -->
                  <div class="date-range-group">
                      <!-- Date de début -->
                      <div class="date-group">
                          <label for="start-date">Date de début :</label>
                          <input 
                              type="date" 
                              id="start-date" 
                              v-model="filters.startDate" 
                              :disabled="filters.nextEvents" 
                              @change="updateEndDateMin"
                          >
                      </div>

                      <!-- Date de fin -->
                      <div class="date-group">
                          <label for="end-date">Date de fin :</label>
                          <input 
                              type="date" 
                              id="end-date" 
                              v-model="filters.endDate" 
                              :disabled="filters.nextEvents" 
                              :min="filters.startDate"
                          >
                      </div>
                  </div>


                <!-- Checkbox -->
                <div class="checkbox-group">
                    <label class="select-all">
                        <input type="checkbox" v-model="filters.nextEvents"> Tous les événements à venir
                    </label>
                </div>
            </div>
        </div>



        <!-- Section Mes Evenements -->
        <div class="filter-section">
          <h4><i class="fas fa-user-tie"></i> Mes événements</h4>
          <div class="checkbox-group">
            <label class="select-all">
              <input type="checkbox" v-model="filters.myEvents"> Afficher uniquement mes événements
            </label>
          </div>
        </div>

        <!-- Section Catégorie Evénements -->
        <div class="filter-section">
            <h4><i class="fas fa-tags"></i> Catégorie</h4>
            <select id="event-category" v-model="filters.category" @change="handleCategoryInput" required>
              <option value="0" selected>Toutes les catégories</option>
              <option 
                v-for="(category, index) in categoryOptions" 
                :key="index" 
                :value="category"
              >
                {{ category }}
              </option>
            </select>
        </div>


        <!-- Section tickets Evénements -->
        <div class="filter-section">
            <h4><i class="fas fa-ticket-alt"></i> Disponibilité des tickets</h4>
            <div class="checkbox-group">
                <label class="select-all">
                    <input type="checkbox" v-model="filters.tickets"> Afficher uniquement les 
                    evenements avec des tickets disponibles
                </label>
            </div>
        </div>

        <!-- Section Order -->
        <div class="filter-section">
          <h4><i class="fas fa-sort"></i> Trier par</h4>
          <div class="checkbox-group">
            <label class="sub-select" v-for="(order, index) in orders" :key="index">
              <input 
                type="radio" 
                v-model="filters.selectedOrder" 
                :value="order.value" 
                @change="selectOrder(order)"
              > 
              <i :class="order.icon"></i> {{ order.text }}
            </label>
          </div>
        </div>

  
        <!-- Boutons de validation -->
        <div class="modal-buttons">
          <button @click="applyFilters"><i class="fas fa-check-circle"></i> Appliquer</button>
          <button @click="closeModal"><i class="fas fa-undo"></i> Annuler</button>
        </div>
      </div>
    </div>
</template>
  
<script>
  import * as eventUtilities from "../../utils/events.js";

  export default {
    name: 'ModalFiltersEvents',
    props: {
      isOpen: {
        type: Boolean,
        required: true
      }
    },

    mounted() {
      // Vérifier si des filtres sont sauvegardés dans localStorage
      const savedFilters = localStorage.getItem('savedFilters');
      if (savedFilters) {
        this.filters = JSON.parse(savedFilters);
      }
    },

    computed: {
      categoryOptions() {
        return eventUtilities.categoriesList; // Retourne les catégories pour les options
      },
    },

    

    data() {
      const today = new Date().toISOString().slice(0, 10); // Date du jour au format 'YYYY-MM-DD'
    
      return {
        orders: [
            { value: 1, text: 'Titre', icon: 'fas fa-sort-amount-down-alt' },
            { value: 2, text: 'Titre', icon: 'fas fa-sort-amount-up-alt' },
            { value: 3, text: 'Date', icon: 'fas fa-sort-amount-down-alt' },
            { value: 4, text: 'Date', icon: 'fas fa-sort-amount-up-alt' }
        ],

        filters: {
          nextEvents: false,
          myEvents: false,
          category: '0',
          tickets: false,
          startDate: today, // Date de début initialisée à aujourd'hui
          endDate: today,   // Date de fin initialisée à aujourd'hui
          selectedOrder: 1, 
        }
      };
    },

    

    methods: {

      toggleDateInputs() {
          console.log("");
      },
      
      updateEndDateMin() {
          // Vérifie si la date de début est supérieure à la date de fin
          if (this.filters.startDate > this.filters.endDate) {
              this.filters.endDate = this.filters.startDate;
          }
      },


      selectOrder(order) {
        this.filters.selectedOrder = order.value;
      },


        applyFilters() {
            this.$emit('apply-filters', this.filters);
            localStorage.setItem('savedFilters', JSON.stringify(this.filters));
            this.closeModal();
        },
        closeModal() {
            this.$emit('close');
        },


    }
  };
</script>
  
<style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 350px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    color: #000;
    max-height: 70vh; /* Limite la hauteur de la modale pour éviter qu'elle dépasse l'écran */
    overflow-y: auto; /* Ajoute le défilement si le contenu dépasse */
    scrollbar-width: none; /* Cache la barre de défilement dans Firefox */
  }

  .modal-content::-webkit-scrollbar {
    width: 0px; /* Cache la barre de défilement dans Chrome, Safari */
    background: transparent; /* Optionnel : pour enlever un arrière-plan */
  }

  
  .filter-section {
    margin-bottom: 20px;
  }
  .filter-section h4{
    color: #FFF;
    background-color: #000;
    border-radius: 15px;
    padding: 10px;
    text-align: left;
  }
  .filter-section h4 i{
    margin-right: 10px;
  }
  
  .checkbox-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }
  .checkbox-group .select-all {
    display: block;
    text-align: left;
    width: 100%;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .checkbox-group .sub-select {
    flex: 1 1 calc(33.33% - 10px);
    /* display: inline-block; */
    margin-right: 5%;
    margin-bottom: 10px;
    text-align: left;
  }


  .sub-select-3 {
    flex: 1 1 calc(33.33% - 10px); /* 3 éléments par ligne (ajuster selon besoin) */
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .checkbox-group > .sub-select-3:last-child {
    text-align: right;
  }
  
  
  .modal-buttons {
    display: flex;
    justify-content: flex-end;
  }
  
  .modal-buttons button {
    margin-left: 10px;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }
  
  .modal-buttons button:last-child {
    background-color: #dc3545;
  }

  .input-date input {
    padding: 0.5em 0;
    font-size: 1em;
    /* width: 100%; */
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #000;
    color: #000;
  }

  .input-date input:focus {
    outline: none;
    border-bottom: 2px solid #016A40; /* Couleur de focus verte */
  }

  .single-date {
    display: block;
    text-align: left;
    width: 100%;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-left: 5px;
  }
  .single-date label{
    margin-right: 10px;
  }

  .plage-date {
    display: flex; /* Utiliser Flexbox pour répartir les éléments */
    justify-content: space-between; /* Répartit les éléments uniformément */
    align-items: center; /* Aligne verticalement les éléments au centre */
    width: 100%; /* S'assure que le conteneur occupe 100% de la largeur disponible */
    gap: 10px; /* Ajoute un petit espacement entre les éléments */
    }

    .plage-date label {
    flex: 1; /* Les étiquettes prennent chacune une part égale de l'espace */
    text-align: right; /* Aligne le texte des étiquettes à droite */
    margin-right: 10px; /* Ajoute un espace à droite des étiquettes */
    }

    .plage-date input[type="date"] {
    flex: 2; /* Les champs de date prennent plus d'espace que les étiquettes */
    padding: 5px; /* Ajoute un peu de padding pour les entrées */
    width: 100%; /* S'assure que les champs d'entrée s'étendent sur toute leur zone */
    box-sizing: border-box; /* S'assure que le padding est inclus dans la largeur totale */
    }

    .date-range-group {
    display: flex;
    justify-content: space-between; /* Répartition équitable entre les groupes */
    gap: 20px; /* Espace entre les deux groupes */
    width: 100%; /* Prend toute la largeur disponible */
}

.date-group {
    display: inline-block;
    width: 48%; /* Chaque groupe prend environ la moitié de la largeur du parent */
    text-align: left; /* Aligne le contenu à gauche */
}

.date-group label {
    display: block; /* Le label occupe une ligne au-dessus de l'input */
    margin-bottom: 5px; /* Espacement entre le label et l'input */
    font-weight: bold; /* Ajoute un peu d'accent sur les labels */
}

.date-group input {
    width: 100%; /* Les champs remplissent toute la largeur du groupe */
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc; /* Bordure légère pour un style moderne */
    border-radius: 4px; /* Coins arrondis */
    box-sizing: border-box; /* Inclut padding et bordures dans la largeur */
}

/* Style de base pour le select */
select#event-category {
    width: 100%; /* Occupe toute la largeur de son conteneur parent */
    padding: 8px 12px; /* Ajoute de l'espace intérieur pour le confort */
    font-size: 14px; /* Taille de police confortable */
    color: #333; /* Couleur du texte */
    background-color: #fff; /* Fond blanc */
    border: 1px solid #ccc; /* Bordure légère */
    border-radius: 4px; /* Coins arrondis */
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1); /* Léger effet d'ombre interne */
    appearance: none; /* Supprime les styles natifs du navigateur */
    -moz-appearance: none; /* Supprime les styles natifs dans Firefox */
    -webkit-appearance: none; /* Supprime les styles natifs dans Safari/Chrome */
    cursor: pointer; /* Change le curseur pour indiquer que c'est cliquable */
    outline: none; /* Supprime les contours natifs */
}

/* Ajout d'une icône de flèche vers le bas personnalisée */
select#event-category {
    background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16"%3E%3Cpath stroke="%23333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4.5 6l3.5 3.5 3.5-3.5"/%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: right 12px center; /* Positionne l'icône dans le coin droit */
    background-size: 12px 12px; /* Ajuste la taille de l'icône */
}

/* Hover et focus pour interactivité */
select#event-category:hover {
    border-color: #888; /* Change la couleur de la bordure au survol */
}

select#event-category:focus {
    border-color: #555; /* Change la couleur de la bordure au focus */
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25); /* Ajoute un effet de halo */
}

/* Désactiver le select */
select#event-category:disabled {
    background-color: #e9ecef; /* Fond gris clair */
    color: #6c757d; /* Texte grisé */
    cursor: not-allowed; /* Change le curseur */
}

</style>
  