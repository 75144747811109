// src/config.js
//http://localhost:3000
//http://192.168.0.186:3000
//https://www.api.kobdirek.com
//cd "Documents\TECH PAM\FinX\kobdirek"
export const SERVER_IP = 'https://www.api.kobdirek.com';

//
export const MEDIA_SERVER_URL  = `${SERVER_IP}/uploads/`;


