<template>
    <Header />
    <PageHeader title="Tableau des SOLs" :goBack="customGoBack" />

    <div class="dashboard-group-savings-page">

        <div class="btn-large-container">
            <button class="btn-add-pool" @click="createPool">
                Créer un nouveau groupe <i class="fas fa-plus-circle"></i>
            </button>
            <button class="btn-notification" @click="showModalNotifications = true">
                <i class="fas fa-bell"></i>
            </button>
        </div>

        <div class="search-container">
            <!-- Input de recherche -->
            <div class="search-input">
                <input 
                    type="text" 
                    v-model="searchQuery" 
                    placeholder="Nom de Groupe" 
                    @keyup.enter="searchPools"
                />
                <!-- Icône de recherche à l'intérieur de l'input -->
                <button @click="searchPools" class="search-icon">
                    <i class="fas fa-search"></i>
                </button>
            </div>

            <!-- Bouton pour les filtres avec icône uniquement -->
            <button class="filter-btn"  @click="openFiltersModal">
                <i class="fas fa-sliders-h"></i>
            </button>

        </div>

        <div class="loading-fetch" v-if="loadingFetch">
            <i class="fas fa-spinner fa-spin"></i>
        </div>
  
        <div v-else-if="!showDetails && !showViewPayment">
            <div v-if="pools.length" class="info-card-container">

                <div v-for="(pool, index) in pools" 
                    :key="index" 
                    class="sol-card"
                >
                    <div class="sol-info">
                        <div class="info-item">
                            <i class="fas fa-tags"></i>
                            <span><b>{{ pool.group_name }}</b></span>
                        </div>
                        <div class="info-item">
                            <i class="fas fa-check-circle"></i>
                            <span>{{ stringSatusGroup(pool.cycle_status) }}</span>
                        </div>
                        <div class="info-item">
                            <i class="fas fa-sync"></i>
                            <span>{{ stringFrequences(pool.contribution_frequency) }}</span>
                        </div>
                        <div class="info-item">
                            <i class="fas fa-money-bill-wave"></i>
                            <span>{{ pool.contribution_amount }} {{ pool.currency }}</span>
                        </div>
                        <div class="info-item">
                            <i class="fas fa-users"></i>
                            <span>{{ pool.participants }}</span>
                        </div>
                    </div>
                    <div class="sol-buttons">
                        <button class="btn-details" @click="loadDetails(pool)">Détails</button>
                        <button class="btn-config" v-if="pool.cycle_status !== 'in-progress'" @click="gotoSettings(pool.id)">
                            <i class="fas fa-cog"></i>
                        </button>
                    </div>
                </div>

            </div>

            <div v-else class="no-results-message">
                Aucun groupe SOL ne correspond à votre recherche actuelle. <br><br>
                Veuillez vérifier vos filtres ou créer un nouveau groupe SOL.
            </div>



        </div>

        <!-- Bloc de pagination -->
        <div class="pagination-container">
            <div class="pagination" v-if="totalPages > 1 && !showDetails && !showViewPayment">
                <button @click="prevPage" :disabled="currentPage === 1">
                    <i class="fa-solid fa-angles-left"></i>
                </button>
                <span> {{ currentPage }} / {{ totalPages }} </span>
                <button @click="nextPage" :disabled="currentPage === totalPages">
                    <i class="fa-solid fa-angles-right"></i>
                </button>
            </div>
        </div>

        <div v-if="showDetails && !showViewPayment">
            <div class="loading-fetch" v-if="loadingDetails">
                <i class="fas fa-spinner fa-spin"></i>
            </div>

            <div v-else>

                <div class="group-header">
                    <h2>GROUPE SOL : {{poolItem.group_name}}</h2>
                    <i class="fa fa-close close-icon" @click="closeGroup"></i>
                </div>

                <div class="information-box">

                    <h2>Informations générales</h2>

                    <div class="info-row">
                        <strong>Statut :</strong> <span>{{ stringSatusGroup(poolItem.cycle_status) }}</span>
                    </div>
                    <div class="info-row">
                        <strong>Date de démarrage :</strong> <span>{{ formatShortDateText(poolItem.start_date) }}</span>
                    </div>
                    <div class="info-row">
                        <strong>Durée Totale du cycle :</strong> <span>{{ formatDuration(poolItem.cycle_duration) }}</span>
                    </div>
                    <div class="info-row">
                        <strong>Fréquence des contributions :</strong> <span>{{ stringFrequences(poolItem.contribution_frequency) }}</span>
                    </div>
                    <div class="info-row">
                        <strong>Montant des contributions :</strong> <span>{{ poolItem.contribution_amount }} {{ poolItem.currency }}</span>
                    </div>
                    <div class="info-row">
                        <strong>Montant rémunéré :</strong> <span>{{ poolItem.cycle_amount }} {{ poolItem.currency }}</span>
                    </div>

                    <div class="config-button-container" v-if="poolItem.is_leader && poolItem.status !== 'in-progress'">
                        <button class="configure-btn" @click="gotoSettings(poolItem.id)">Configurer <i class="fas fa-cog"></i></button>
                    </div>

                </div>

                <div class="participants-container">
                    <!-- Titre -->
                    <h2 class="title">Participants</h2>

                    <!-- Liste des participants -->
                    <div class="participant-list">
                        <div class="participant-item" v-for="(participant, index) in participants" :key="participant.id" @click="checkParticipant(participant)">
                        <div class="participant-info">
                            {{index + 1}}. 
                            <i class="fas fa-user user-icon"
                                :class="{
                                    'fas fa-user user-icon': participant.is_leader === 0 ,
                                    'fas fa-user-tie user-icon-tie': participant.is_leader === 1
                                    }"
                            >
                            </i>

                            <span class="participant-name">{{ participant.fullName }}</span>

                            <i :class="{
                                'fas fa-check-circle status-green': participant.status === 'confirmed' || participant.status === 'active',
                                'fas fa-clock status-orange': participant.status === 'waiting'
                                }"
                            >
                            </i>
                            
                        </div>
                        <div v-if="poolItem.status !== pending">
                            <i class="fas fa-calendar-alt calendar-icon"></i>
                        </div>
                        
                        </div>
                    </div>

                    <!-- Bouton Configuration -->
                    <div class="config-button-container"  v-if="poolItem.is_leader && poolItem.status === 'pending'">
                        <button class="config-button" @click="editMemberList">Configurer <i class="fas fa-cogs"></i></button>
                    </div>
                </div>

                <button class="btn-terms" @click="showModalTerms = true">
                    Termes et Conditions <i class="fas fa-file"></i>
                </button>

            </div>

        </div>


        <!-- container pour effectuer un paiement -->
        <div class="payment-modal" v-if="showViewPayment">
            <!-- Titre et icône de fermeture -->
            <div class="modal-header">
                <h2>Effectuer un Paiement de SOL</h2>
                <i class="fa fa-close close-icon" aria-hidden="true" @click="closeViewPayment"></i>
            </div>

            <!-- Boutons de méthode de paiement -->
            <div class="payment-methods">
                <button class="method-button selected">
                    <i class="fa fa-wallet"></i>
                    <span>KOB DIREK</span>
                </button>
                <button class="method-button">
                    <i class="fa fa-credit-card"></i>
                    <span>CARTES</span>
                </button>
                <button class="method-button">
                    <i class="fa fa-university"></i>
                    <span>BANQUES</span>
                </button>
            </div>

            <!-- Récapitulatif avec labels et valeurs alignés -->
            <div class="recap">
                <p><span class="label">Nom de Sol :</span> <span class="value">{{ poolItem.group_name }}</span></p>
                <p><span class="label">Montant :</span> <span class="value">{{ poolItem.contribution_amount }} {{ poolItem.currency }}</span></p>
                <p><span class="label">Cycle en cours :</span> <span class="value">{{ positionPayment }}e main</span></p>
                <p><span class="label">Date limite de paiement :</span> <span class="value">{{formatShortDateText(datePayment)}}</span></p>
            </div>

            <!-- Bouton de confirmation -->
            <button class="confirm-button" v-if="!showContainerPIN" @click="validatePayment"><i class="fas fa-check-circle"></i> Confirmer</button>

            <div class="container-show-pin" v-if="showContainerPIN">
                <h4 class="title-pin">{{ $t('pageBasicDeposit.enterPin') }}</h4>

                <div class="pin-container">
                    <div class="pin-inputs">
                        <!-- Les inputs avec ref dynamique -->
                        <input v-for="(digit, index) in pin" 
                            :key="index" 
                            v-model="pin[index]" 
                            maxlength="1"
                            :ref="'pin' + index" 
                            @input="onInput(index)" 
                            @keydown.backspace="onBackspace(index)" 
                            :type="isRevealed ? 'text' : 'password'" 
                            class="pin-box" 
                            inputmode="numeric" 
                            pattern="[0-9]*"
                            @keypress="isNumber($event)" />
                        
                        <!-- Icône pour révéler le code PIN -->
                        <i class="fas fa-eye eye-icon" @mousedown="revealPin" @mouseup="hidePin" @mouseleave="hidePin"></i>
                    </div>
                </div>


                <!-- Bouton de validation avec une icône FontAwesome -->
                <button type="submit" id="submitButton" class="btn-submit" :disabled="loadingPin" @click="concluate">
                <span v-if="loadingPin">
                    {{ $t('pageBasicDeposit.subBoutton2') }} <i class="fas fa-spinner fa-spin"></i>
                </span>
                <span v-else>
                    <i class="fas fa-check-circle"></i> Valider 
                </span>
                </button>


                <div>
                    <span class="error-message validate-error" v-if="pinError">{{ pinError }}</span>
                </div>
            </div>

        </div>

                
        <!-- Modal pour les filtres -->
        <modal-filters-pools 
            v-if="showFiltersModal" 
            :isOpen="showFiltersModal" 
            @apply-filters="handleApplyFilters" 
            @close="closeFiltersModal" 
        />

        <ModalTerms 
            v-if="showModalTerms" 
            @close="showModalTerms = false" 
        />

        <ModalCalendar 
            :isOpen = "showModalCalendar" 
            :memberID="currentMemberID"
            :groupID="currentGroupID"
            @close="showModalCalendar = false" 
            @action-chosen="handleActionCalendarChosen"
        />

        <ConfirmPaymentModal
            :date="datePayment"
            :isVisible="isModalCPVisible"
            @update:isVisible="isModalCPVisible = $event"
            @confirm="handleUserCPResponse"
        />

        <!-- Modal de paiement -->
        <ModalInfoPayment 
            :isVisible="isInfoPaymentVisible" 
            :datePayment="datePayment" 
            :amount="amountInfo" 
            :position="positionPayment" 
            @close="closeInfoPayment" 
        />

        <!-- Modal des Notifications -->
        <ModalNotifGroupsSaving 
            :isVisible="showModalNotifications" 
            @close="closeModalNotifications" 
        />
        
        
    </div>

    <Footer :key="footerKey" />
    
</template>


<script>
import Header from '../shared/HeaderPage.vue';
import Footer from '../shared/FooterPage.vue';
import PageHeader from '../shared/PageHeader.vue';
import axios from 'axios';
import { SERVER_IP } from '../../config';

import ModalFiltersPools from './ModalFiltersPools.vue';
import ModalTerms from './ModalTermsView.vue';
import ModalCalendar from './ModalCalendar.vue';
import ConfirmPaymentModal from './ConfirmPaymentModal.vue';
import ModalInfoPayment from './ModalInfoPayment.vue';
import ModalNotifGroupsSaving from './ModalNotifGroupsSaving.vue';

import { checkAuth, formatShortDateText } from '@/utils/validationUtils';
import { stringSatusGroup, stringFrequences } from '@/utils/groupsavings';


import { refreshFooterMixin } from '@/mixins/refreshFooterMixin';

export default {
  name: 'DashboardSavingsGroups',
  mixins: [refreshFooterMixin],

  components: {
      Header,
      PageHeader,
      Footer,

      ModalFiltersPools,
      ModalTerms,
      ModalCalendar,
      ConfirmPaymentModal,
      ModalInfoPayment,
      ModalNotifGroupsSaving,
  },

  created() {
    checkAuth();
  },

  data() {
    return {
        searchQuery: '',
        loadingFetch: true,
        pools: [],
        currentPage: 1,     // Page courante
        itemsPerPage: 4,   // Nombre d'éléments par page
        totalPages: 1,
        showFiltersModal: false,
        showDetails: false,
        loadingDetails: false,
        showModalTerms: false,
        showModalCalendar: false,
        showModalNotifications: false, 

        showViewPayment: false,
        isModalCPVisible: false,
        datePayment: new Date(),
        positionPayment: null,
        currentMemberID: null,
        currentGroupID: null,
        amountInfo: null,
        isInfoPaymentVisible:false,

        selectedFilters: {
            allStatusGroup: true,
            selectedStatusGroup: [],
            allStatusMember: true,
            selectedStatusMember: [],
            allCycles: true,
            selectedCycle: [],
            allCurrencies: true,
            selectedCurrencies: [],
            allRoles: true,
            selectedRoles: [],
            selectedOrder: 1, 
        },

        poolItem: null,
        selectedParticipant: null,
        participants: [
            { id: 1, nomComplet: "Jean Dupont", dateInscription: "2024-11-10" },
            { id: 2, nomComplet: "Marie Tremblay", dateInscription: "2024-11-09" },
            { id: 3, nomComplet: "Paul Martin", dateInscription: "2024-11-08" },
            { id: 4, nomComplet: "Alice Fortin", dateInscription: "2024-11-07" },
            { id: 5, nomComplet: "Lucien Durand", dateInscription: "2024-11-06" }
        ],


        showContainerPIN: false,
        pinError: '',
        isRevealed: false,
        pin: ["", "", "", ""],
        loadingPin: false,

    };
  },

  mounted() {     
    this.initiateFilters();
    this.fetchPools(); // Appel à l'API pour récupérer les économies une fois le composant monté
  },
  beforeUnmount() {
    if (localStorage.getItem('savedFilters')) {
        localStorage.removeItem('savedFilters');
    }
  },

  methods: {
    createPool() {
        this.$router.push('/create-pool-savings');
    },
    initiateFilters() {
        localStorage.setItem('savedFilters', JSON.stringify(this.selectedFilters));
    },

    nextPage() {
        if (this.currentPage < this.totalPages) {
            this.currentPage++;
            this.fetchPools();  // Charger les données de la nouvelle page
        }
    },
    prevPage() {
        if (this.currentPage > 1) {
            this.currentPage--;
            this.fetchPools();  // Charger les données de la nouvelle page
        }
    },

    stringFrequences(frequence) {
        return stringFrequences(frequence); // Appel à la fonction importée
    },
    stringSatusGroup(status) {
        return stringSatusGroup(status);
    },
    formatShortDateText(date) {
        return formatShortDateText(date);
    },

    formatDuration(duration) {
        const daysInWeek = 7;
        const daysInMonth = 30;

        let months = Math.floor(duration / daysInMonth);
        let weeks = Math.floor((duration % daysInMonth) / daysInWeek);
        let days = parseInt(duration % daysInWeek);
        let result = '';

        if(months > 0) {
            result += `${months} mois`;
        }
        if(weeks > 0) {
            if(result) result += ' ';
            result += `${weeks} sem.`;
        }
        if(days > 0 || result === '') {
            if(result) result += ' ';
            result += `${days} jour${days > 1 ? 's' : ''}`;
        }

        return result;
    },
    closeGroup(){
        
        this.loadingDetails = false;
        this.showDetails = false;
    },
    gotoSettings(id) {
        this.$router.push({ name: 'SavingGroupConfiguration', params: { ref: id } });
    },
    

    customGoBack() {
        if (this.showDetails) {
            this.showDetails = false;
            this.loadingDetails = false;
        } 
        else if (this.showViewPayment) {
            this.closeViewPayment();
        }
    },

    closeViewPayment() {
        this.showViewPayment = false;
        this.showDetails = true;
        this.showModalCalendar = true;
    },

    closeModalNotifications() {
        this.showModalNotifications = false;
    },


    openFiltersModal() {
        this.showFiltersModal = true;
    },
    closeFiltersModal() {
        this.showFiltersModal = false;
    },
    handleApplyFilters(filters) {
        this.selectedFilters = filters;
        this.currentPage = 1;
        this.fetchPools();
    },
    searchPools() {
        this.currentPage = 1;
        this.fetchPools();
    },

    async fetchPools() {
        this.loadingFetch = true;
        const url = `${SERVER_IP}/poolsavings/getPools`;
        this.pools = [];

        try {
            const userId = checkAuth();
            const response = await axios.post(url, {
                userId: userId,
                searchQuery: this.searchQuery,
                currentPage: this.currentPage, 
                itemsPerPage: this.itemsPerPage,

                allStatusGroup: this.selectedFilters.allStatusGroup,
                selectedStatusGroup: this.selectedFilters.selectedStatusGroup,
                allStatusMember: this.selectedFilters.allStatusMember,
                selectedStatusMember: this.selectedFilters.selectedStatusMember,
                allCycles: this.selectedFilters.allCycles,
                selectedCycle: this.selectedFilters.selectedCycle,
                allCurrencies: this.selectedFilters.allCurrencies,
                selectedCurrencies: this.selectedFilters.selectedCurrencies,
                allRoles: this.selectedFilters.allRoles,
                selectedRoles: this.selectedFilters.selectedRoles,
                selectedOrder: this.selectedFilters.selectedOrder
            });

            if (response.status === 200 ) {
                // On met à jour les données locales après réception de l'API
                this.pools = response.data.data.pools;   // Tableau des économies
                this.totalPages = response.data.data.totalPages;   // Total des pages
            }
            
        } catch (error) {
            console.log(error.response.data.message);
        } finally {
            this.loadingFetch = false;
        }
    },

    async loadDetails(pool) {
        this.poolItem = pool;
        this.loadingDetails = true;
        this.showDetails = true;
        this.currentGroupID = pool.id;

        try {
            const url = `${SERVER_IP}/poolsavings/getPoolDetails`;
            const userId = checkAuth();
            const response = await axios.post(url, {
                userId: userId,
                poolID: pool.id
            });

            if (response.status === 200 ) {
                this.participants = response.data.data.participants;   // Tableau des économies
                pool.cycle_duration = response.data.data.cycle_duration;
                pool.cycle_amount = response.data.data.cycle_amount;
                pool.is_leader = response.data.data.is_leader;
            }
            
        } catch (error) {
            // console.log(error.response.data.message);
            alert("Error");
        } finally {
            this.loadingDetails = false;
        }
    },


    editMemberList(){
        const url = `/group/${this.poolItem.id}/add-members-saving`;
        this.$router.push(url);
    },

    checkParticipant(participant) {
        const userId = checkAuth();
        if (this.poolItem.cycle_status === 'pending' && participant.member_id === userId && !this.poolItem.is_leader) {
            const url = `/group/${participant.id}/group-invit`;
            this.$router.push(url);
        }
        else if (this.poolItem.cycle_status !== 'pending'){
            this.selectedParticipant = participant;
            this.currentMemberID = participant.member_id;
            this.showModalCalendar = true;
        }
        
    },

    handleActionCalendarChosen({ action, datePayment, position }) {
        
        this.positionPayment = position+1;
        this.datePayment = datePayment;

        if (action === 'payment') {
            if( this.selectedParticipant.member_id === checkAuth() ){
                this.isModalCPVisible = true;
            }
        } else if (action === 'info') {
            this.amountInfo = `${this.poolItem.contribution_amount} ${this.poolItem.currency}`;
            this.isInfoPaymentVisible = true;
        }
    },

    handleUserCPResponse(isConfirmed) {
      if (isConfirmed) {
        this.showModalCalendar = false;
        this.showViewPayment = true;
      } 
    },

    closeInfoPayment(){
        this.isInfoPaymentVisible = false;
    },


    // VIEW ENTER PIN
    // Empêche l'utilisateur d'entrer des lettres
    isNumber(event) {
        const charCode = event.keyCode ? event.keyCode : event.which;
        if (charCode < 48 || charCode > 57) { // 48-57 correspond aux chiffres 0-9
            event.preventDefault();
        }
    },

    // Lorsque l'utilisateur entre un chiffre dans un input
    onInput(index) {
        if (this.pin[index] && index < this.pin.length - 1) {
            // Si le champ est rempli, passer automatiquement au champ suivant
            this.$nextTick(() => {
                const nextInput = this.$refs['pin' + (index + 1)][0];
                if (nextInput) nextInput.focus();
            });
        }
        this.pinError = '';
    },

    // Gestion de la suppression d'un chiffre
    onBackspace(index) {
        if (!this.pin[index] && index > 0) {
            // Si le champ est vide après suppression, revenir au champ précédent
            this.$nextTick(() => {
                const prevInput = this.$refs['pin' + (index - 1)][0];
                if (prevInput) prevInput.focus();
            });
        } 
        this.pinError = '';
    },

    // Révéler le PIN pendant 1 seconde
    revealPin() {
        this.isRevealed = true;
        setTimeout(() => {
            this.isRevealed = false;
        }, 3000); // Masque le code après 3 secondes
    },

    hidePin() {
        this.isRevealed = false;
    },

    // Validation du PIN
    validatePin() {
        if (this.pin.join('').length === 4) {
            return true;
        } else {
            return false;
        }
    },

    validatePayment() {
        this.showContainerPIN = true;
        this.scrollToError('submitButton');
    },

    getErrorMessage(errorCode) {
      switch (errorCode) {
          case 'no_account':
              return 'Aucun compte associé à cette action.';
          case 'inactive_account':
              return 'Votre compte est inactif.';
          case 'no_access':
              return 'Vous n\'avez pas accès à cette fonctionnalité.';
          case 'invalid_pin':
              return 'Le code PIN que vous avez saisi est incorrect. Veuillez réessayer.';
          case 'insufficient_funds':
              return 'Solde insuffisant. Veuillez vérifier votre solde et réessayer.';
          default:
              return 'Une erreur est survenue avec le serveur. Veuillez réessayer plus tard';
      }
    },

    async concluate(){
        this.loadingPin = true;
        if(!this.validatePin()){
            this.pinError = this.$t('pageBasicDeposit.badPin');
            this.loadingPin = false;
            return;
        }

        const url = `${SERVER_IP}/poolsavings/setPayment`;
        try {
            const userId = checkAuth();
            const response = await axios.post(url, { 
                groupID: this.poolItem.id,
                pin: this.pin.join(''),
                datePayment: this.datePayment,
                userId: userId
            });
            if (response.status === 200) {
                this.closeViewPayment();
            }
            
        } catch (error) {
            if (error.response){
                this.pinError = this.getErrorMessage(error.response.data.error);
                this.scrollToError('pinError');
            }            
        }
        finally {
            this.loadingPin = false; // Assurez-vous de désactiver le chargement dans tous les cas
        }
    },

    scrollToError(field) {
        const element = document.getElementById(field);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    },

  },


};

</script>


<style scoped>

.dashboard-group-savings-page {
max-width: 400px;
margin: auto;
padding: 0px;
text-align: center;
color: #ffffff;
padding-left: 10px;
padding-right: 10px;
margin-bottom: 5.5%;
font-size: 0.8em;
}

/************ HEADER SEARCH **************/
.btn-large-container {
    display: flex;
    gap: 10px; /* Espace entre les boutons */
    align-items: center;
    margin-top: 20px;
}

.btn-large-container .btn-add-pool {
    flex: 9; /* 90% de la largeur */
    background-color: #016A40; /* Couleur de fond pour le bouton d'épargne */
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.btn-large-container .btn-add-pool:hover {
    background-color: #005934;
}

.btn-large-container .btn-add-pool:active {
    background-color: #003822;
}

.btn-large-container .btn-notification {
    flex: 1; /* 10% de la largeur */
    background-color: #f0f0f0; /* Couleur de fond pour le bouton de notification */
    color: #000;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}
.btn-large-container .btn-notification:hover {
    /*background-color: #e0e0e0; Couleur de fond au survol*/
    color: #333; /* Couleur du texte au survol */
}

.btn-large-container .btn-notification:active {
    background-color: #d0d0d0; /* Couleur de fond lorsque le bouton est enfoncé */
    /*color: #000; /* Couleur du texte lorsque le bouton est enfoncé */
}

/* Style du conteneur */
.search-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

/* Style de l'input de recherche */
.search-input {
  position: relative;
  flex-grow: 1;
  margin-right: 15px; /* Espace entre l'input et le bouton filters */
  max-width: 75%;
}

.search-input input {
  width: 100%;
  padding: 10px 40px 10px 10px;
  background-color: white; /* Arrière-plan blanc */
  color: black; /* Texte noir */
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 12px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
}

.search-input input::placeholder {
  color: lightgray; /* Placeholder gris clair */
}

/* Icône de recherche à l'intérieur de l'input */
.search-input .search-icon {
  position: absolute;
  right: -15%;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: black; /* Couleur de l'icône de recherche */
  cursor: pointer;
  font-size: 18px;
}

/* Bouton de filtre */
.filter-btn {
  padding: 10px;
  background-color: white; /* Arrière-plan blanc */
  color: black; /* Texte noir */
  border: none;
  border-radius: 5px;
  font-size: 16px; /* Taille de l'icône */
  cursor: pointer;

}

.filter-btn i {
  margin: 0;
}


/******************CARD D'UN SOL*******************/

.loading-fetch {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
.info-card-container {
    display: flex;
    flex-wrap: wrap; /* Permet le wrapping sur plusieurs lignes */
    justify-content: space-between;
    gap: 10px; /* Espace entre les cartes */
    padding: 0;
    width: 100%;
    margin-top: 10px;
}

.sol-card {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: calc(38% - 10px); /* Largeur pour 2 cartes par ligne avec espace */
    margin: 5px 0; /* Espacement vertical entre les lignes */
}


.sol-info {
    margin-bottom: 20px; /* Espacement entre les infos et les boutons */
}

.info-item {
    display: flex;
    align-items: center; /* Centrer verticalement les icônes et le texte */
    margin-bottom: 10px; /* Espacement entre les lignes */
    /*font-size: 16px; /* Taille du texte */
    color: #333; /* Couleur du texte */
}

.info-item i {
    margin-right: 10px; /* Espacement entre l'icône et le texte */
    color: #016A40; /* Couleur des icônes */
}

.sol-buttons {
    display: flex; /* Utiliser flex pour aligner les boutons */
    justify-content: space-between; /* Espace égal entre les boutons */
}

.btn-details {
    background-color: #007bff; /* Couleur de fond pour le bouton détails */
    color: white; /* Couleur du texte */
    border: none; /* Pas de bordure */
    border-radius: 5px; /* Coins arrondis */
    padding: 10px 15px; /* Espacement interne */
    cursor: pointer; /* Curseur de pointeur */
    transition: background-color 0.3s; /* Transition pour l'effet hover */
}

.btn-details:hover {
    background-color: #0056b3; /* Couleur plus foncée au survol */
}

.btn-config {
    background: none; /* Pas de fond */
    border: none; /* Pas de bordure */
    cursor: pointer; /* Curseur de pointeur */
    color: #007bff; /* Couleur de l'icône */
    font-size: 20px; /* Taille de l'icône */
}

.btn-config:hover {
    color: #0056b3; /* Couleur plus foncée au survol */
}


/********************* PAGINATION **********************/
.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 5%;
}

.pagination {
    display: flex;
    align-items: center;
}

.pagination button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    margin: 0 5px;
    font-size: 12px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.pagination button:hover:not(:disabled) {
    background-color: #0056b3;
}

.pagination span {
    margin: 0 10px;
    font-size: 12px;
    font-weight: bold;
}


/****************** NO RESULT FETCH ************************/
.no-results-message {
    display: flex;
    text-align: center;
    color: white; /* Texte en blanc */
    border-radius: 10px; /* Coins arrondis */
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5); /* Ombre portée */
    padding: 20px;
    transition: all 0.3s ease;
    margin-top: 25%;
}


/***************************** DETAILS GROUP **************************************/
.group-header {
  background-color: rgba(255, 255, 255, 0.1); /* Fond translucide */
  color: #fff;
  padding: 15px 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* max-width: 600px; */
  /* margin: 20px auto; */
  margin-top: -20px;
  border: 1px solid rgba(255, 255, 255, 0.3); /*Bordure légère */
  backdrop-filter: blur(10px); /* Effet de flou pour fond moderne */
}

.group-header h2 {
  font-size: 1.5em;
  font-weight: bold;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.group-header .close-icon {
  font-size: 1.2em;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.7);
  transition: color 0.3s ease;
}

.group-header .close-icon:hover {
  color: #ff5c5c; /* Couleur de survol pour l'icône de fermeture */
}


.information-box {
    background-color: #101010;
    color: white;
    padding: 20px;
    border-radius: 12px;
    margin: 20px auto;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Ombre pour ajouter de la profondeur */
}

.information-box h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    font-weight: bold;
    text-align: left;
    border-bottom: 2px solid #444; /* Légère séparation entre le titre et les infos */
    padding-bottom: 10px;
}

.info-row {
    display: flex;
    justify-content: space-between;
    /* font-size: 1.1rem; */
    margin-bottom: 15px;
}

.info-row strong {
    font-weight: 600;
}

.info-row span {
    font-weight: 400;
    color: #f1f1f1; /* Pour donner un contraste avec le texte */
}

.configure-btn {
    background-color: #016A40; /* Fond vert */
    color: white; /* Texte blanc */
    border: none;
    border-radius: 5px; /* Coins moins circulaires */
    padding: 10px 20px; /* Ajustement du rembourrage */
    cursor: pointer;
    transition: background-color 0.3s; /* Transition pour l'animation */
    width: auto;
}

.configure-btn:hover {
    background-color: #005934; /* Changement de couleur au survol */
}

.participants-container {
  background-color: #fff;
  color: #333;
  padding: 20px;
  border-radius: 10px;
  margin: 20px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  min-height: 200px; /* Hauteur minimale du conteneur */
  max-height: 200px; /* Hauteur maximale pour limiter le défilement */
  overflow-y: auto;
  scrollbar-width: none; /* Masque la barre de défilement dans Firefox */
}

.participants-container::-webkit-scrollbar {
  display: none; /* Masque la barre de défilement dans Chrome, Safari et Edge */
}


.participants-container .title {
    font-size: 1.5rem;
    margin-bottom: 20px;
    font-weight: bold;
    text-align: left;
    border-bottom: 2px solid #444; /* Légère séparation entre le titre et les infos */
    padding-bottom: 10px;
}

.participant-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.participant-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.2s ease;
  cursor: pointer;
}

.participant-item:hover {
  background-color: #f5f5f5;
}

.participant-info {
  display: flex;
  align-items: center;
  gap: 8px;
}

.user-icon {
  color: #007bff;
  font-size: 1.2em;
}
.user-icon-tie {
  color: blue;
  font-size: 1.2em;
}

.participant-name {
  font-weight: bold;
  color: #333;
}

.status-green {
  color: #28a745;
}
.status-orange {
  color: orange;
}

.calendar-icon {
  color: #6c757d;
  font-size: 1.2em;
}

.config-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.config-button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.config-button:hover {
  background-color: #0056b3;
}

.btn-terms {
  background-color: #016A40; /* Fond vert */
  color: white; /* Texte blanc */
  border: none;
  border-radius: 5px; /* Coins moins circulaires */
  padding: 10px 20px; /* Ajustement du rembourrage */
  cursor: pointer;
  transition: background-color 0.3s; /* Transition pour l'animation */
  width: auto;
}

.btn-terms:hover {
  background-color: #005934; /* Changement de couleur au survol */
}

.btn-terms:active {
  background-color: #003822; /* Changement de couleur lorsqu'il est cliqué */
}

</style>

<style scoped>

/* container pour effectuer un paiement  */
.payment-modal {
    background-color: #000;
    color: #fff;
    padding: 20px;
    max-width: 400px;
    margin: 20px auto;
    border-radius: 8px;
    font-size: 0.8rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.payment-modal .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.payment-modal .modal-header h2 {
    font-size: 1.1rem;
    margin: 0;
}

.payment-modal .close-icon {
    cursor: pointer;
    color: #888;
    font-size: 1.2rem;
}
.payment-modal .close-icon:hover{
    color: #FFF;
}

.payment-modal .payment-methods {
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
}

.payment-modal .method-button {
    background-color: #333;
    color: #fff;
    border: none;
    padding: 8px 15px;
    border-radius: 5px;
    font-size: 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s;
    text-align: center;
}

.payment-modal .method-button:hover {
    background-color: #555;
}

.payment-modal .method-button.selected {
    background-color: #016A40;
}

.payment-modal .method-button i {
    /* display: block; */
    font-size: 0.8rem;
    margin-right: 5px;
}

.payment-modal .method-button span {
    /* display: block; */
    font-size: 0.5rem;
}

.payment-modal .recap {
    margin: 20px 0;
}

.payment-modal .recap p {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
}

.payment-modal .recap .label {
    font-weight: normal;
}

.payment-modal .recap .value {
    text-align: right;
}

.payment-modal .confirm-button {
    background-color: #016A40;
    color: #fff;
    border: none;
    padding: 12px;
    /* width: 100%; */
    border-radius: 5px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

.payment-modal .confirm-button:hover {
  background-color: #005934; /* Changement de couleur au survol */
}

.payment-modal .confirm-button:active {
  background-color: #003822; /* Changement de couleur lorsqu'il est cliqué */
}


 /* .pin-container */

.title-pin{
font-weight: normal;
font-size: 1.5rem;
}

.pin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pin-label {
  margin-bottom: 20px;
  font-size: 1.2rem;
  text-align: center;
}

.pin-inputs {
  display: flex;
  gap: 10px;
  position: relative;
}

.pin-box {
  width: 50px;
  height: 50px;
  border: 2px solid #000103;
  border-radius: 5px;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}

.eye-icon {
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.error-message {
font-size: 0.8em; /* Ajustez la taille de la police selon vos besoins */
font-weight: normal; /* Ajustez le poids de la police si nécessaire */
transition: font-size 0.3s; /* Transition pour une animation douce */
color: #FFFC00; /* Couleur du texte rouge */
font-style: italic; /* Texte en italique */
margin-top: 5px;
}

.btn-submit {
background-color: #016a40;
color: white;
border: none;
padding: 10px 20px;
font-size: 1em;
cursor: pointer;
border-radius: 5px;
transition: background-color 0.3s;
margin-top: 15px;
}

.btn-submit:hover {
background-color: #005934;
}

.btn-submit:active {
  background-color: #003822; /* Changement de couleur lorsqu'il est cliqué */
}
</style>