<template>
    <div class="modal-overlay" v-if="isOpen" @click.self="closeModal">
      <div class="modal-content">
        <h3>Calendrier</h3>

        <div class="loading-container" v-if="loadingPayments">
            <i class="fas fa-spinner fa-spin"></i>
        </div>
        <div v-else>

            <div v-if="payments.length > 0">
                <div class="payments-container">
                    <div v-for="(payment, index) in payments" :key="index"
                        class="payment-circle" :class="getStatusClass(payment)"
                        @click="clickDatePayment(payment)"
                    >
                        <div class="circle"></div>
                        <p class="payment-date">{{ formatDate(payment.date) }}</p>
                    </div>
                </div>
            </div>
            <div class="error-server" v-else>
                <p>Une erreur est survenue, veuillez réessayer un peu plus tard</p>
            </div>

        </div>

        <!-- Boutons de validation -->
        <div class="modal-buttons">
            <button @click="closeModal"><i class="fas fa-times"></i> Fermer</button>
        </div>
      </div>
    </div>
</template>

<script>

import axios from 'axios';
import { SERVER_IP } from '../../config';
import { checkAuth, formatShortDate } from '@/utils/validationUtils';

export default {
    name: 'ModalCalendar',
    props: {
      isOpen: {
        type: Boolean,
        required: true
      },
      memberID: {           // Prop pour le `memberID`
        type: Number,
        required: true
      },
      groupID: {           // Prop pour le `memberID`
        type: Number,
        required: true
      }
    },

    watch: {
        isOpen(newVal) {
            if (newVal) {
                this.getPayments();
            }
        }
    },

    data() {
        return {
            loadingPayments: true,
            payments: [],
        };        
    },

    methods: {
        closeModal() {
            this.$emit('close');
        },

        getStatusClass(payment) {
            if(payment.status === 'paid')
                return 'status-paid';
            if(payment.status === 'pending')
                return 'status-pending';
            if(payment.status === 'cleared')
                return 'status-cleared';
        },
        formatDate(date) {
            return formatShortDate(date);
        },
        
        async getPayments() {
            this.loadingPayments = true;
            this.payments = [];

            try {
                const url = `${SERVER_IP}/poolsavings/getPayments`;
                const userId = checkAuth();
                const response = await axios.post(url, {
                    userId: userId,
                    groupID: this.groupID,
                    memberID: this.memberID
                });

                if (response.status === 200 ) {
                    this.payments = response.data.payments;   
                }
                
            } catch (error) {
                this.payments = [];
            } finally {
                this.loadingPayments = false;
            }
        },

        async getDatePaid(datePayment) {
            try {
                const url = `${SERVER_IP}/poolsavings/getPaymentDate`;
                const response = await axios.post(url, {
                    groupID: this.groupID,
                    memberID: this.memberID,
                    datePayment: datePayment
                });

                if (response.status === 200) {
                    return response.data.date; // Renvoie la nouvelle date récupérée depuis l'API
                }
                
            } catch (error) {
                console.log(error.message);
                return null; // Si erreur, retourner null ou une valeur par défaut
            }
        },

        async clickDatePayment(payment) {
            let datePayment = payment.date;
            let position = -1; // Valeur par défaut si aucun paiement éligible n'est trouvé

            // Vérifier si le statut du paiement est 'cleared' ou 'pending'
            if (payment.status === 'cleared' || payment.status === 'pending') {
                // Filtrer les paiements avec le statut 'cleared' ou 'pending'
                const eligiblePayments = this.payments.filter(p => p.status === 'cleared' || p.status === 'pending');
                
                // Trouver le paiement avec la plus petite date
                if (eligiblePayments.length > 0) {
                    const earliestPayment = eligiblePayments.reduce((earliest, current) => {
                        return new Date(current.date) < new Date(earliest.date) ? current : earliest;
                    });

                    datePayment = earliestPayment.date;
                    position = this.payments.findIndex(p => p.date === datePayment && (p.status === 'cleared' || p.status === 'pending'));
                }

                this.$emit('action-chosen', { action: 'payment', datePayment, position });
            }

            else{
                position = this.payments.findIndex(p => p.date === payment.date);
                const updatedDatePayment = await this.getDatePaid(payment.date);
                this.$emit('action-chosen', { action: 'info', datePayment: updatedDatePayment, position });
            }

        },

    },

}

</script>


<style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 350px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    color: #000;
  }

  .modal-content::-webkit-scrollbar {
    width: 0px; /* Cache la barre de défilement dans Chrome, Safari */
    background: transparent; /* Optionnel : pour enlever un arrière-plan */
  }
  .content-text{
    text-align: left;
    text-align: justify;
    font-size: 0.8rem;
    max-height: 65vh; /* Limite la hauteur de la modale pour éviter qu'elle dépasse l'écran */
    overflow-y: auto; /* Ajoute le défilement si le contenu dépasse */
    scrollbar-width: none; /* Cache la barre de défilement dans Firefox */
  }
  
  
  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    text-align: center;
  }
  
  .modal-buttons button {
    margin-left: 10px;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    background-color: #dc3545;
    color: white;
    cursor: pointer;
    margin-top: 10px;
  }
  

  .payment-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    max-width: 700px;
    margin: 20px auto;
    text-align: left;
  }

  .payment-circle {
    text-align: center;
    width: 80px;
    display: inline-block;
  }
  
.circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: darkgray; /* couleur par defaut*/
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.payment-date {
    margin-top: 5px;
    font-size: 0.6rem;
    color: #333;
}

.status-paid .circle {
    background-color: forestgreen;
}
.status-pending .circle {
    background-color:goldenrod;
}
.status-cleared .circle {
    background-color: darkgray;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: #000;
    font-size: 1.5rem;
}

.error-server{
    color: #000;

}
</style>