export const categoriesList = [
    "Afterwork",
    "Atelier",
    "Concert",
    "Conférence",
    "Evenement politique",
    "Evenement religieux",
    "Exposition",
    "Festival",
    "Foire",
    "Formation",
    "Gala",
    "Grande Ouverture",
    "Meetup",
    "Portes Ouvertes",
    "Randonnée",
    "Rencontre",
    "Réseautage",
    "Séminaire",
    "Spectacle",
    "Webinaire"
];

export function validateNameEvent(nameEvent) {
    // Réinitialiser le message d'erreur
    let textError = ""; 

    // Vérifier si nameEvent est vide
    if (!nameEvent.trim()) {
      textError = "Le nom de l'événement est requis.";
      return { isValid: false, errorMessage: textError };
    }

    // Vérifier la longueur minimale (par exemple, 3 caractères)
    if (nameEvent.length < 3) {
      textError = "Le nom de l'événement doit contenir au moins 3 caractères.";
      return { isValid: false, errorMessage: textError };
    }

    // Vérifier si nameEvent contient uniquement des caractères valides (lettres, espaces, chiffres, etc.)
    const validNamePattern = /^[a-zA-ZÀ-ÖØ-öø-ÿ0-9\s-]+$/; // Pas besoin d'échapper le tiret
    if (!validNamePattern.test(nameEvent)) {
        const textError =
        "Le nom de l'événement ne doit contenir que des lettres, des chiffres, des espaces ou des tirets.";
        return { isValid: false, errorMessage: textError };
    }


    // Si toutes les validations passent
    return { isValid: true, errorMessage: "" };
}

export function validateCategory(category) {
    // Vérifie si une catégorie est sélectionnée
    if (!category) {
      return { isValid: false, errorMessage: "Veuillez sélectionner une catégorie valide." };
    }
  
    // Vérifie si la catégorie existe dans la liste des options
    if (!categoriesList.includes(category)) {
      return { isValid: false, errorMessage: "La catégorie sélectionnée est invalide." };
    }
  
    // Si tout est correct, retourne un objet valide
    return { isValid: true, errorMessage: "" };
}

export function validateDateEvent(date) {
    let errorMessage = "";
    const currentDate = new Date(); // La date actuelle

    // Vérifier que la date n'est pas vide
    if (!date) {
      errorMessage = "La date de l'événement est obligatoire.";
      return { isValid: false, errorMessage };
    }

    // Convertir la chaîne de date en un objet Date
    const eventDate = new Date(date);

    // Vérifier si la date est valide
    if (isNaN(eventDate.getTime())) {
      errorMessage = "La date de l'événement n'est pas valide.";
      return { isValid: false, errorMessage };
    }

    // Vérifier que l'événement a lieu dans le futur
    if (eventDate < currentDate) {
      errorMessage = "La date de l'événement doit être dans le futur.";
      return { isValid: false, errorMessage };
    }

    // Si toutes les validations sont passées
    return { isValid: true, errorMessage: "" };
}

export function validateAddressEvent(eventType, address, url) {
    let errorMessage = "";

    // Si l'événement est de type "physique"
    if (eventType === "physique") {
        const addressPattern = /^\s?.,?\s?.+$/;

        // Vérifier que l'adresse n'est pas vide
        if (!address || address.trim() === "") {
            errorMessage = "L'adresse de l'événement est obligatoire pour un événement physique.";
            return { isValid: false, errorMessage };
        }

        // Validate that the address contains at least one word
        if (!addressPattern.test(address)) {
            errorMessage = "Entrer le numero de l'adresse suivi de la rue";
            return { isValid: false, errorMessage };
        }
    }
    // Si l'événement est en ligne (ou un URL)
    else if (eventType === "online") {
        // Vérifier que l'URL est valide
        const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
        if (!url || url.trim() === "") {
            errorMessage = "L'URL de l'événement en ligne est obligatoire.";
            return { isValid: false, errorMessage };
        }
        if (!urlPattern.test(url)) {
            errorMessage = "L'URL de l'événement en ligne n'est pas valide.";
            return { isValid: false, errorMessage };
        }
    } else {
        // Si le type d'événement est invalide
        errorMessage = "Le type d'événement est inconnu. Veuillez spécifier un type valide.";
        return { isValid: false, errorMessage };
    }

    // Si toutes les validations sont passées
    return { isValid: true, errorMessage: "" };
}

  


  